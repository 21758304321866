/**
 * FeedLoading.js
 * Just a temporary notif loader template
 * @author Jaiwardhan Swarnakar
 */
import React from 'react'
import BaseComponent from '../../BaseComponent'
import './NotifLoading.css'

class NotifLoading extends BaseComponent {
    render() {
        return (
            <div>
                <div className="notification-wrapper">
                    <div className="notification-item">
                        <div className="animated-background-notification">
                            <div className="row no-gutters">
                                <div className="col">
                                    <div className="notification-background-masker header-top"></div>
                                    <div className="notification-background-masker header-left"></div>
                                    <div className="notification-background-masker header-right"></div>
                                    <div className="notification-background-masker header-bottom"></div>
                                    <div className="notification-background-masker subheader-left"></div>
                                    <div className="notification-background-masker subheader-right"></div>
                                    <div className="notification-background-masker subheader-bottom"></div>
                                    <div className="notification-background-masker content-top"></div>
                                    <div className="notification-background-masker content-first-end"></div>
                                    <div className="notification-background-masker content-second-line"></div>
                                    <div className="notification-background-masker content-second-end"></div>
                                    <div className="notification-background-masker content-third-line"></div>
                                    <div className="notification-background-masker content-third-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotifLoading;