/**
 * Permanent Benefit Reward Popup Dialog
 * @author Rutvi Thakkar
 */

import React from 'react';
import $ from 'jquery';
import BaseComponent from "../../BaseComponent";
import ServiceLocator from '../../../ServiceLocator';
import LocUtils from '../../../util/localization/LocUtils';
import './RewardPopup.css'

class RewardPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.onRewardClaimClick = this.onRewardClaimClick.bind(this);
    }

    _constants = {
        fv2IframeID: "farm2-game-frame",
        UWG_TYPE_EGRESS_REWARD_CLAIM: "UWG_REWARD_CLAIM",
    }

    onRewardClaimClick() {
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_REWARD_CLAIM,
            { "day": this.props.featureData.daysPassed }
        )

        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'reward_claimed',
            phylum: ServiceLocator.PB.GetRewardNum(),
            zClass: LocUtils.T("feature.dialog.reward." + ServiceLocator.PB.GetRewardNum())
        });

        this.props.onRewardClaimed();
        $("#rewardModal").modal('hide');
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            "UWG_SHOW_GAME",
            {}
        );
    }

    render() {
        const rewardNum = this.props.featureData ? this.props.featureData.daysPassed + 1 : 1;
        const url = "/dialogs/permanentBenefits/RewardIcons/icon_permanentbenefits_reward" + rewardNum + ".png";

        return (
            <div className="modal fade" id="rewardModal" data-keyboard="false" data-backdrop="static" tabIndex="1" role="dialog" aria-labelledby="rewardModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content" style={{ border: "none", backgroundColor: 'transparent' }}>
                        <div className="modal-body reward-modal-body"
                            style={{ backgroundImage: "url(" + process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_permanentbenefits_reward_uibg.png)" }}>
                            <div className="col no-gutters reward-container">
                                <div className="row header-text">
                                    {LocUtils.T("feature.dialog.reward." + rewardNum)}
                                </div>

                                <div className="row reward-subheader">
                                    {LocUtils.T("feature.dialog.reward.subheader")}
                                </div>
                            </div>

                            <img className="reward-img" alt=""
                                src={process.env.REACT_APP_ASSET_PATH + url}
                                style={{ marginLeft: '310px', marginTop: '150px', height: '200px' }} />

                            <button
                                className="btn button-green button-green-text reward-button"
                                onClick={this.onRewardClaimClick}
                                data-dismiss="modal" aria-label="Close">
                                {LocUtils.T("feature.dialog.reward.cta")}
                            </button>

                            <div className="footer-text">
                                <span>{LocUtils.T("feature.dialog.reward.footer")}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RewardPopup;