/**
 * ContextManager.js
 * Helpful to maintain contexts accross different
 * page components. This can be used if you don't really like
 * the old school .bind(this) to register contexts.
 * @author Jaiwardhan Swarnakar
 */
import ZLogger from '../util/zlogger/zlogger'
var Contexts = (function() {
    var CONTEXT_LIST = {
        CONNECT_OPTIONS: "connect_options",
        GAME_PAGE: "game-page",
        GAME_INFO: "game-info",
        GAME_IFRAME: "game-iframe"
    }

    var contexts = {}

    function registerNew(contextSymbol, contextSymbolValue) {
        if(contextSymbol.toUpperCase() !== contextSymbol) {
            ZLogger.log("ContextManager:registerNew:: !Warning: Attempting to register a new context without CAPS convention. passed: "+contextSymbol);
            ZLogger.log("ContextManager:registerNew:: !This may result in inconsistent behaviour by human error!");
        }
        if(contextSymbol && contextSymbolValue) {
            if(this.CONTEXT_LIST[contextSymbol.toUpperCase()] !== undefined) {
                ZLogger.error("ContextManager:registerNew:: Unable to overwrite an already registered context type: "+contextSymbol.toUpperCase());
                return;
            }
            this.CONTEXT_LIST[contextSymbol.toUpperCase()] = contextSymbolValue;
        }
        return contextSymbol.toUpperCase();
    }

    function setCtx(contextName, ctx) {
        contexts[contextName] = ctx;
    }

    function unsetCtx(contextName) {
        if(contexts[contextName] !== undefined) {
            delete contexts[contextName];
        }
    }

    function get(contextName) {
        return contexts[contextName];
    }

    return {
        CONTEXT_LIST        : CONTEXT_LIST,
        registerNew         : registerNew,
        setCtx              : setCtx,
        unsetCtx            : unsetCtx,
        get                 : get
    }
})();

export default Contexts;