/**
 * Various site wide data, such as social friends etc
 * can be provided here. Gives other components and games
 * flexibility to fetch common information from here.
 */
var Site = (function () {
    var _socialMappings = {}

    function SetSocialMappings(data) {
        data = (data === undefined) ? {} : data;
        _socialMappings = {};
        for (var zid in data) {
            _socialMappings['' + zid] = data[zid];
        }
    }

    function SocialLookup(zid = '00') {
        return (String(zid) in _socialMappings) ? _socialMappings[String(zid)] : {}
    }

    function SocialExists(zid = '00') {
        return (String(zid) in _socialMappings);
    }

    /**
     * Return epoch time in seconds
     */
    function GetCurrentTimestamp() {
        return Math.floor((new Date()).getTime() / 1000.0);
    }

    function IsSameDay(date, otherDate) {
        return date.toDateString() === otherDate.toDateString();
    }

    function TimeLeftUntilEOD() {
        /*var now = new Date()
        let hoursLeft = 23 - now.getHours()
        var minutesleft = 59 - now.getMinutes();
        if (minutesleft < 10) minutesleft = "0" + minutesleft;
        let secondsLeft = 59 - now.getSeconds();
        if (secondsLeft < 10) secondsLeft = "0" + secondsLeft;
        let obj = {
            "h": hoursLeft,
            "m": minutesleft,
            "s": secondsLeft
        };
        return obj;*/

        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        return (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;

    }

    return {
        SetSocialMappings: SetSocialMappings,
        SocialExists: SocialExists,
        SocialLookup: SocialLookup,
        GetCurrentTimestamp: GetCurrentTimestamp,
        IsSameDay: IsSameDay,
        TimeLeftUntilEOD: TimeLeftUntilEOD
    }
})();
export default Site;