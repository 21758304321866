
/**
 * Friends.js
 * Friends - A special category homo-sapien that another homo-sapien
 * adds to his circle to improve his social interaction/stature.
 * Things you can do with friends can not be done with other 'People'.
 * @author jswarnakar
 */
import ServiceLocator from "../../../../ServiceLocator";
import ZLogger from "../../../../util/zlogger/zlogger";

var Friends = (function () {

    let friends = [];

    /**
     * Gets the zFriends of this user.
     * @param {fn} onCompleteCallback Called when successful fetch
     * @param {fb} onFailureCallback Called when fetch failed
     * Response schema => 
     * {
     *      "zFriendBaseInfo": [{"zid": "36498853859", "displayName": "linda habben"}, ...]
     * }
     */
    function Get(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_getZFriendBaseInfo",
            payload: payload,
            token: "ZDC_GET_ZFRIENDS"
        })
            .then(res => { return res.json() })
            .then(parsed => {
                if (parsed && parsed.zFriendBaseInfo) {
                    friends = parsed.zFriendBaseInfo;
                }
                onCompleteCallback(parsed)
            })
            .catch(err => { onFailureCallback(err) })
    }

    function IsFriend(zid) {
        // @TODO
    }
    function Unfriend(zid) {
        // @TODO
    }

    function List() {
        friends = !!friends ? friends : [];
        return friends;
    }

    return {
        Get: Get,
        List: List,
        IsFriend: IsFriend,
        Unfriend: Unfriend
    }
})();

export default Friends;