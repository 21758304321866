import React from 'react';
import BaseComponent from '../../BaseComponent';
import './contentLoading.css'
class ContentLoading extends BaseComponent {
    render() {
        return (
            <div className="wrapper">
                <div className="wrapper-cell">
                    <div className="image"></div>
                    <div className="text">
                        <div className="text-line-med"></div>
                        <div className="text-line-short"> </div>
                        <div className="text-line-med"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContentLoading;