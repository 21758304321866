class GenericUtils {
  static URLQuerystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static TimeNowSeconds() {
    return parseInt(GenericUtils.TimeNow() / 1000);
  }

  static TimeNow() {
    return (new Date()).getTime();
  }

  // Expects url to be from media.zynga.com: 
  // eg: => https://media.zynga.com/image/83455b7f6c05bcf0db99726678248680d708b93f:0:12920777/90x90.png
  static GetResizedPictureURL(url, w = 128, h = 128) {
    if (url === undefined || url.length === 0 || w === 0 || h === 0) {
      return "";
    }
    var s = url.split("/");
    if (s.length > 0) {
      var format = s[s.length - 1].match(".png|.jpg|.jpeg$");
      if (format !== null) {
        var input = format['input'].split(format[0])[0];

        var ipSplit = input.split("x");
        if (ipSplit.length !== 2 || parseInt(ipSplit[0]) + '' !== ipSplit[0] || parseInt(ipSplit[1]) + '' !== ipSplit[1]) {
          return url;
        } else {
          return s.slice(0, s.length - 1).join("/") + "/" + w + "x" + h + format[0];
        }
      }
    }
    return url;
  }

  static GetURLSearchParams(url = window.location.search) {
    if (url[0] === "?") return url.slice(1);
    return url;
  }

  static IsJsonString(str) {
    try {
      JSON.parse(String(str));
    } catch (e) {
      return false;
    }
    return true;
  }

  static generateRandomNum(min, max) {
    return Math.random() * (max - min) + min;
  }
}


export default GenericUtils;