import React from 'react';
import BaseComponent from '../../BaseComponent'
import './ScrollHelper.css'
import ZLogger from '../../../util/zlogger/zlogger';

class ScrollHelper extends BaseComponent {

    constructor(props) {
        super(props);
        this.getScrollPercent = this.getScrollPercent.bind(this);
        this.onClickScrollDown = this.onClickScrollDown.bind(this);
        this.onClickScrollUp = this.onClickScrollUp.bind(this);
        this.updateScrollStatus = this.updateScrollStatus.bind(this);

        this.onHoldScrollDown = this.onHoldScrollDown.bind(this);
        this.onHoldScrollUp = this.onHoldScrollUp.bind(this);
        this.onReleaseScrollDown = this.onReleaseScrollDown.bind(this);
        this.onReleaseScrollUp = this.onReleaseScrollUp.bind(this);
        this.state = {
            showUp: false,
            showDown: false,
        };
        window.addEventListener('scroll', this.updateScrollStatus);
    }

    scrollParams = {
        step: 20,
        behaviour: 'smooth',
        holdStep: 40,
        default: 'auto',
        holdTimeoutMS: 200
    }

    timerIds = {
        down: undefined,
        up: undefined
    }

    accelerators = {
        down: {
            current: 1.0,
            multiple: 2,
            default: 1.0
        },
        up: {
            current: 1.0,
            multiple: 2,
            default: 1.0
        }
    }

    getScrollPercent() {
        var h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    }

    onHoldScrollUp() {
        this.timerIds.up = setInterval(() => {
            this.onClickScrollUp(
                undefined,
                this.scrollParams.holdStep * this.accelerators.up.current,
                this.scrollParams.default
            );
            this.accelerators.up.current = this.accelerators.up.current * this.accelerators.up.multiple;
        }, this.scrollParams.holdTimeoutMS);
    }

    onHoldScrollDown() {
        this.timerIds.down = setInterval(() => {
            this.onClickScrollDown(
                undefined,
                this.scrollParams.holdStep * this.accelerators.down.current,
                this.scrollParams.default
            );
            this.accelerators.down.current = this.accelerators.down.current * this.accelerators.down.multiple;
        }, this.scrollParams.holdTimeoutMS);
    }

    onReleaseScrollUp() {
        if (this.timerIds.up !== undefined) clearInterval(this.timerIds.up);
        this.accelerators.up.current = this.accelerators.up.default;
    }

    onReleaseScrollDown() {
        if (this.timerIds.down !== undefined) clearInterval(this.timerIds.down);
        this.accelerators.down.current = this.accelerators.down.default;
    }

    onClickScrollUp(e, customStep = undefined, customScrollBehaviour = undefined) {
        if (customStep === undefined) customStep = this.scrollParams.step;
        if (customScrollBehaviour === undefined) customScrollBehaviour = this.scrollParams.behaviour
        window.scrollBy({
            top: -1.0 * customStep,
            left: 0,
            behavior: customScrollBehaviour
        });
        ZLogger.log("Clicked scroll up");
    }

    onClickScrollDown(e, customStep = undefined, customScrollBehaviour = undefined) {
        if (customStep === undefined) customStep = this.scrollParams.step;
        if (customScrollBehaviour === undefined) customScrollBehaviour = this.scrollParams.behaviour
        window.scrollBy({
            top: 1.0 * customStep,
            left: 0,
            behavior: customScrollBehaviour
        });
        ZLogger.log("Clicked scroll down", customStep, customScrollBehaviour);
    }

    updateScrollStatus() {
        let showUp = false;
        let showDown = false;
        let scrollPcInitial = this.getScrollPercent() * 1.0;
        if (scrollPcInitial === 0.0) { showDown = true }
        else if (scrollPcInitial === 100.0) { showUp = true; }
        else { showUp = showDown = true }
        this.setState({
            showDown: showDown,
            showUp: showUp
        });
        if (!showDown && this.timerIds.down !== undefined) clearInterval(this.timerIds.down);
        if (!showUp && this.timerIds.up !== undefined) clearInterval(this.timerIds.up);
    }

    componentDidMount() {
        this.updateScrollStatus();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateScrollStatus);
    }

    render() {
        return (
            <div>
                {
                    this.state.showUp &&
                    <img src={process.env.REACT_APP_ASSET_PATH + "/scroll.png"}
                        alt="^" className="scroll_helper_common scroll_helper_up"
                        onClick={this.onClickScrollUp}
                        onMouseDown={this.onHoldScrollUp} onMouseUp={this.onReleaseScrollUp} />
                }
                {
                    this.state.showDown &&
                    <img src={process.env.REACT_APP_ASSET_PATH + "/scroll.png"}
                        alt="v" className="scroll_helper_common scroll_helper_down"
                        onClick={this.onClickScrollDown}
                        onMouseDown={this.onHoldScrollDown} onMouseUp={this.onReleaseScrollDown} />
                }
            </div>
        )
    }
}

export default ScrollHelper;