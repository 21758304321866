/**
 * Signup.js
 * [Managed by LoginComponent]
 * [Housed in login tab]
 * Takes care of signing up users from the page.
 * Contains BS4 form for a registration.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react';
import $ from 'jquery';
import BaseComponent from '../../BaseComponent';
import LocUtils from '../../../util/localization/LocUtils'
import '../../../../styles/generic.css'
import './Login.css'
import Toast from '../../../util/toasts/Toast';
import Utils from '../../../managers/Network/Utils';
import ServiceLocator from '../../../ServiceLocator';
import Button from '../buttons/Buttons';

class Signup extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            fname: "",
            lname: "",
            gender: "",
            genderToDisplay: "",
            passwordBarStrength: "0%",
            passwordBarColor: "bg-danger",
            passwordHintText: "",
            engaged: false,
            fromSignup: false,
            keepSignedIn: "true",
            fbID: "",
            fbToken: "",
            fbConnect: {
                connected: false,
                data: {}
            },
            confirm:false,
            validation: {
                fname: false,
                lname: false,
                username: false,
                password: false,
            },
            errorMessage: ""
        };

        this.signupConfirm = this.signupConfirm.bind(this);
        this.onSignup = this.onSignup.bind(this);
        this.getMeter = this.getMeter.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.verifySignup = this.verifySignup.bind(this);

        // Subscription to turn of the field editing 
        this.onEngageFinish = this.onEngageFinish.bind(this);
        if (!ServiceLocator.Events.isSubscribed("ZDC_ACCOUNT_REGISTER", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_ACCOUNT_REGISTER", this.onEngageFinish);
        if (!ServiceLocator.Events.isSubscribed("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish);

        this.loginAuthHandler = props.loginAuthHandler;
        this.loginFBHandler = props.loginFBHandler;
        this.connectWithFBHandler = this.connectWithFBHandler.bind(this);
        this.baseSignupHandler = this.baseSignupHandler.bind(this);
        this.signupAuthHandler = this.signupAuthHandler.bind(this);
    }

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "ui",
            kingdom: "load",
            phylum: "signup"
        })
    }

    // Helper actions when an error could had been encountered
    pageActions = {
        back: () => {
            // Essentially takes the carousel to slide 0
            // Also flush all state data
            this.pageActions.flushData();
            $("#signup-carousel-fb").carousel(0);
        },
        flushData: (leaveFB = false, postFlush = () => { }) => {
            // Resets all the state data to initials
            this.setState({
                username: "",
                password: "",
                fname: "",
                lname: "",
                gender: "male",
                passwordBarStrength: "0%",
                passwordBarColor: "bg-danger",
                passwordHintText: "",
                engaged: false,
                fbID: "",
                fbToken: "",
                fbConnect: leaveFB ? this.state.fbConnect : { connected: false, data: {} }
            }, postFlush)
        },
        next: (pane = "fb", postFlushAction = () => { }) => {
            if (typeof (postFlushAction) !== "function") postFlushAction = () => { };
            // Takes the carousel to slide 1 (hidden action)
            // Also flush only form state data (not fb connect data)
            this.pageActions.flushData(true, postFlushAction);
            pane = pane !== "fb" ? "social" : pane;
            $("#signup-carousel-" + pane).carousel(1);

        },
        somethingWentWrong: (err) => {
            Toast.Show({ message: LocUtils.TPure("toasts.something_went_wrong"),
                severity: Toast.SEVERITY_CODES.ERROR
            })
            super.fatal(err);
        },
        userAlreadyExists: () => {
            Toast.Show({ message: LocUtils.TPure("toasts.user_signup_user_already_exists"),
                severity: Toast.SEVERITY_CODES.WARNING
            })
        },
        emailVerification: () => {
            Toast.Show({ message: LocUtils.TPure("toasts.user_signup_registration_do_verify_email"),
                severity: Toast.SEVERITY_CODES.INFO,
                timeout: Toast.TIMEOUTS.STICKY

            })
        },
        validityChecks: () => {
            const { fname, lname, password, username, gender } = this.state;
            let verdict = this.state;
            if (!fname || fname.length === 0) verdict.validation.fname = true;
            if (!lname || lname.length === 0) verdict.validation.lname = true;
            if (!password || password.length < 7) verdict.validation.password = true;
            if (!username || username.length === 0 || !Utils.ValidateEmail(username)) verdict.validation.username = true;
            if (!gender || gender.length === 0) verdict.validation.gender = true;
            this.setState(verdict);
        },
        resetValidityCheck: (d = {}) => {
            let verdict = this.state;
            Object.keys(d).forEach(k => {
                if (this.state.validation[k] !== undefined) verdict.validation[k] = false;
            })
            this.setState(verdict);
        },
        reset: () => {
            this.pageActions.back();
        }
    }

    baseSignupHandler(signupFormData) {
        var connectMethod = (!this.state.fbConnect.connected)
            ? ServiceLocator.Auth.Zynga.Signup
            : ServiceLocator.Auth.Zynga.SignupFB
        connectMethod(
            signupFormData,
            ((resp) => {
                if (resp && resp.e !== undefined && resp.e === 0) {
                    ServiceLocator.ZTrack.Cast({
                        counter: "zdc_2",
                        kingdom: "register",
                        family: "zdc_register"
                    });
                    // Registration Complete
                    Toast.Show({ message: LocUtils.TPure("toasts.user_signup_registration_complete"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    })


                    if(this.state.fbConnect.connected){
                        Toast.Show({ message: LocUtils.TPure("toasts.user_signup_registration_page_auto_login"),
                            severity: Toast.SEVERITY_CODES.INFO
                        })
                        // AUTO SIGN IN THE USER
                        this.loginAuthHandler(signupFormData);
                    }else{

                        this.pageActions.emailVerification();
                        this.pageActions.flushData();
                        this.onEngageFinish();
                        this.props.parentSwitchTabHandle('login', { email: this.state.username, fbID: "" })
                        $("#loginModalCenter").modal('hide');
                        $("#emailVerificationModalSignUp").modal('show');
                    }

                } else {
                    // Looks like a DAPI error happened here
                    // Lets send a OOPS error
                    this.pageActions.somethingWentWrong("DAPI error on Signup");
                }
            }),
            ((err) => {
                // Seems like an error with registration
                this.pageActions.somethingWentWrong(err);
            })
        )
    }

    /** 
     * Method to handle signup form data. Does in a step process:
     * 1) verify if user already exists
     * 2) if (1) OK, then complete registration
     * 3) if (2) OK, then Auto sign in with credentials
     * x) Error/Toasts on all other failures.
     */
    signupAuthHandler(signupFormData) {
        this.setState({
            engaged: true
        })
        ServiceLocator.Auth.Zynga.Exists(
            signupFormData,
            ((resp) => {
                // Check if exits then decide whether to go ahead with signup
                if (resp && resp.data && resp.data.wfn === false) {
                    if (this.state.fbConnect.connected) {
                        ServiceLocator.Auth.Zynga.ConfirmAssociation(
                            {
                                email: signupFormData.username,
                                fbId: this.state.fbConnect.data.userID,
                                fbToken: this.state.fbConnect.data.accessToken
                            },
                            (resp) => {
                                if (resp && resp.e === 0 && resp.data && resp.data.association) {
                                    if (resp.data.association === "free") {
                                        // Append to form data and trigger base SU
                                        signupFormData.fbID = this.state.fbConnect.data.userID;
                                        signupFormData.fbToken = this.state.fbConnect.data.accessToken;
                                        return this.baseSignupHandler(signupFormData);
                                    } else {
                                        this.pageActions.userAlreadyExists();
                                        this.pageActions.back();
                                        if (resp.data.association === "emailExists" || resp.data.association === "associated") {
                                            this.props.parentSwitchTabHandle('login', { email: this.state.username, fbID: "" })
                                        } else {
                                            // Facebook ID Exists
                                            this.props.parentSwitchTabHandle('login', { email: this.state.fbConnect.data.email, fbID: this.state.fbConnect.data.userID })
                                        }
                                    }
                                } else {
                                    this.pageActions.somethingWentWrong("Non 0 error code or missing data");
                                }
                            },
                            (err) => {
                                this.pageActions.somethingWentWrong(err);
                            }
                        )
                    } else {
                        // Good to go ahead with a normal register
                        Toast.Show({ message: LocUtils.TPure("toasts.user_signup_user_wait_while_registering"),
                            severity: Toast.SEVERITY_CODES.SUCCESS
                        })
                        this.baseSignupHandler(signupFormData);
                    }
                } else {
                    // Looks like the account already exists
                    this.pageActions.userAlreadyExists();
                    this.pageActions.back("signup");
                    this.pageActions.back();
                    this.onEngageFinish();
                    this.props.parentSwitchTabHandle('login', { email: this.state.username, fbID: "" })
                }
            }),
            ((err) => {
                this.pageActions.somethingWentWrong(err);
                // this.pageActions.back("signup");
                // this.pageActions.back();
            })
        )
    }


    /** 
     * 
     */
    connectWithFBHandler(e) {
        ServiceLocator.Auth.Fb.Login(
            (data) => {
                super.log("<======> login good: ", data);
                if (data.accessToken && data.userID) {
                    Toast.Show({ message: LocUtils.TPure("toasts.user_signup_fb_connected"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    });
                    this.setState({
                        fbConnect: {
                            connected: true,
                            data: data
                        }
                    }, () => {
                        // Now check if this is already connected?
                        ServiceLocator.Auth.Zynga.ExistsFB(
                            { fbId: data.userID },
                            (resp) => {
                                if (resp && resp.e === 0 && resp.data) {
                                    if (resp.data.wfn === false) {
                                        // Not connected yet, good to go to next slide
                                        // also pre-populate the email from fb, however user
                                        // can change it.
                                        this.pageActions.next("fb", () => {
                                            this.setState({
                                                username: this.state.fbConnect.data.email
                                            })
                                        })
                                    } else {
                                        // seems like this is already connected
                                        // switch to login page with the Facebook Id handler
                                        this.pageActions.userAlreadyExists();
                                        this.pageActions.back();
                                        this.onEngageFinish();
                                        this.props.parentSwitchTabHandle('login', { email: this.state.fbConnect.data.email, fbID: this.state.fbConnect.data.userID });
                                    }
                                    // add this email to the username state
                                    // this.pageActions.next(() => {
                                    //     this.setState({
                                    //         username: data.email
                                    //     })
                                    // })
                                } else {
                                    this.pageActions.somethingWentWrong("Non 0 error code or missing data");
                                }
                            },
                            (err) => {
                                this.pageActions.somethingWentWrong(err);
                            }
                        )
                    })
                }
            },
            (err) => {
                Toast.Show({ message: LocUtils.TPure("toasts.login_failed"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
                super.error("Error occurred => ", err)
            }
        )
    }

    /** Subscription handler */
    onEngageFinish(evt) {
        this.setState({
            engaged: false
        })
    }

    /** plugged to page input elements to update the state */
    onChangeField(data) {
        this.pageActions.resetValidityCheck(data);
        this.setState(data);
    }

    /** 
     * Listener when the password field is changed. Suggests
     * password strength to the users.
     */
    onChangePassword(data) {
        var colorConfig = {
            "-1": {
                color: "",
                fill: "0%",
                name: "none",
                hint: LocUtils.T("login.password_hint_very_weak")
            },
            "0": {
                color: "bg-danger",
                fill: "25%",
                name: "very_weak",
                hint: LocUtils.T("login.password_hint_very_weak")
            },
            "1": {
                color: "bg-warning",
                fill: "50%",
                hint: LocUtils.T("login.password_hint_weak")
            },
            "2": {
                color: "bg-info",
                fill: "75%",
                hint: LocUtils.T("login.password_hint_okay")
            },
            "3": {
                color: "bg-success",
                fill: "100%",
                hint: LocUtils.T("login.password_hint_strong")
            }
        }
        var matchedCase = [];
        matchedCase.push("[$@$!%*#?&]"); // Special Character
        matchedCase.push("[A-Z]");      // Uppercase Alphabets
        matchedCase.push("[0-9]");      // Numbers
        matchedCase.push("[a-z]");     // Lowercase Alphabets

        // Check the conditions
        var ctr = -1;
        var color = "";
        var strength = "";
        var hintText = "";

        if (data.length > 0) {
            ctr = 0;
            if (data.length >= 7) {
                for (var i = 0; i < matchedCase.length; i++) {
                    if (new RegExp(matchedCase[i]).test(data)) {
                        ctr++;
                    }
                }
            }
        }
        
        if(ctr > 3){
            ctr = 3;
        }
        // Display it
        color = colorConfig[String(ctr)].color;
        strength = colorConfig[String(ctr)].fill;
        hintText = colorConfig[String(ctr)].hint;
        this.setState({
            passwordBarStrength: strength,
            passwordBarColor: color,
            passwordHintText: hintText
        }, () => { this.onChangeField({ password: data }) })
    }

    /**
     * Does basic form validation for user inputs
     */
    verifySignup() {
        // check form input validity
        this.pageActions.validityChecks();
        if (
            !this.state.username || this.state.username.length === 0 ||
            !this.state.password || this.state.password.length === 0 ||
            !this.state.fname || this.state.fname.length === 0 ||
            !this.state.lname || this.state.lname.length === 0 ||
            !this.state.gender || this.state.gender.length === 0
        ) {
            this.setState({
                errorMessage: LocUtils.TPure("toasts.user_signup_form_incomplete")
            });
            return 0;  // Form incomplete
        }

        // Check data validity
        if (this.state.password.length < 7) {
            this.setState({
                errorMessage: LocUtils.TPure("toasts.user_signup_password_too_short")
            });
            return -1; // Something wrong with the form password input
        }
        if (this.state.password.length > 16) {
            this.setState({
                errorMessage: LocUtils.TPure("toasts.user_signup_password_too_long")
            });
            return -1; // Something wrong with the form password input
        }
        if (!Utils.ValidateEmail(this.state.username)) {
            this.setState({
                errorMessage: LocUtils.TPure("toasts.forget_password_invalid_email")
            });
            return -2;// Email doesn't look to be valid
        }

        return 1; // all ok
    }

    /** Button event handler when a submit is done on click */
    signupConfirm(evt) {
        var allOk = this.verifySignup();
        if (allOk === 1) {
            // All looks okay in the form 
                
            this.setState({
                confirm: true
            });
        } else {
            // Wait for the user to change some form data
            // do nothing here
        }
    }

    /** Button event handler when a submit is done on click */
    onSignup(evt) {
        var allOk = this.verifySignup();
        if (allOk === 1) {
            // All looks okay in the form 
            this.signupAuthHandler(this.state);
        } else {
            // Wait for the user to change some form data
            // do nothing here
        }
    }

    /** A simple meter component which signals the password strength */
    getMeter() {
        var strength = this.state.passwordBarStrength;
        return <div className="row">
            <div className="col-9 pr-0">
                <div className="progress">
                    <div className={"progress-bar progress-bar-striped " + this.state.passwordBarColor}
                        role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"
                        style={{ width: strength }}>
                    </div>
                </div>
            </div>
            <div className="col-3 pl-1">
                <h6><span style={{"verticalAlign": "super"}} className="badge">
                    {this.state.passwordHintText}</span>
                </h6>
                <br />
            </div>
        </div>
    }

    componentWillUnmount() {
        ServiceLocator.Events.unsubscribe("ZDC_ACCOUNT_REGISTER", this.onEngageFinish);
        ServiceLocator.Events.unsubscribe("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish);
    }

    render() {
        return (

            <div aria-labelledby={this.props.tabRef} id={this.props.id}>
                { this.state.confirm === false &&
                <div className="row login-row no-gutters">
                    <div className="d-none d-md-block col-md-6 col-lg-6">
                        <img src={process.env.REACT_APP_ASSET_PATH + "/dialogs/login/second_session_login.png"}
                             alt="Login"
                             className="login-left-pane-image"
                        />
                    </div>
                    <div className="col-sm-12 col-12 col-md-6 col-lg-6 signup-fb-container" id="signup_form_pane">
                        <div id="signup-carousel-fb" className="carousel slide" data-keyboard="false" data-ride="carousel" data-interval="false">
                            <div className="carousel-inner carousel-min-pad">
                                <div className="carousel-item active">
                                    <h4>{LocUtils.TPure("login.signup_header")}</h4>
                                    <h6 style={{ "fontSize": "0.8rem",  "display": (this.state.validation.username || this.state.validation.password) ? "none" : "block"} }>{LocUtils.TPure("login.signup_subheader")}</h6>
                                    <div className="input-group mb-3 text-left">
                                        <div className="invalid-tooltip"
                                        style={{"position" : "static", "display": (this.state.validation.username || this.state.validation.password) ? "block" : "none"}}>
                                        {this.state.errorMessage}
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input id={this.props.id + "-fname"}
                                            onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({ fname: evt.target.value });
                                            }}
                                            disabled={this.state.engaged}
                                            className={(!!this.state.validation.fname ? "inp-error" : "") + " form-control rounded-corners"}
                                            type="text" aria-label="First Name"
                                            placeholder={LocUtils.TPure("login.signup_form_placeholder_first_name")}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <input id={this.props.id + "-lname"}
                                            onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({ lname: evt.target.value });
                                            }}
                                            disabled={this.state.engaged}
                                            className={(!!this.state.validation.lname ? "inp-error" : "") + " form-control rounded-corners"}
                                            type="text" aria-label="Last Name"
                                            placeholder={LocUtils.TPure("login.signup_form_placeholder_last_name")}
                                        />
                                    </div>
                                    <div className="input-group mb-3 form-group">
                                    <button className={(!!this.state.validation.gender ? "inp-error" : "") + " form-control rounded-corners btn rounded-corners dropdown-toggle is-flex"}
                                            style={{width: '100%', textAlign: 'left', backgroundColor: 'white', color: 'grey'}}
                                            type="button" id="genderDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {(this.state.genderToDisplay) ? this.state.genderToDisplay :LocUtils.TPure("login.signup_form_placeholder_gender")}</button>
                                         <div class="dropdown-menu" aria-labelledby="genderDropdown">
                                            <button className="dropdown-item" type="button" onClick={(evt) => {
                                                    if (evt) evt.preventDefault();
                                                    this.onChangeField({ gender: "male" });
                                                    this.setState({
                                                        genderToDisplay: LocUtils.TPure("login.signup_form_option_sex_male")
                                                    })
                                                }} id={this.props.id + "-sex-m"}>{LocUtils.TPure("login.signup_form_option_sex_male")}
                                            </button>

                                            <button className="dropdown-item" type="button" onClick={(evt) => {
                                                    if (evt) evt.preventDefault();
                                                    this.onChangeField({ gender: "female" });
                                                    this.setState({
                                                        genderToDisplay: LocUtils.TPure("login.signup_form_option_sex_female")
                                                    })
                                                }} id={this.props.id + "-sex-f"}>{LocUtils.TPure("login.signup_form_option_sex_female")}</button>

                                            <button className="dropdown-item" type="button" onClick={(evt) => {
                                                    if (evt) evt.preventDefault();
                                                    this.onChangeField({ gender: "other" });
                                                    this.setState({
                                                        genderToDisplay: LocUtils.TPure("login.signup_form_option_sex_non_binary")
                                                    })
                                                }} id={this.props.id + "-sex-nb"}>{LocUtils.TPure("login.signup_form_option_sex_non_binary")}</button>

                                            <button className="dropdown-item" type="button" onClick={(evt) => {
                                                    if (evt) evt.preventDefault();
                                                    this.onChangeField({ gender: "na" });
                                                    this.setState({
                                                        genderToDisplay: LocUtils.TPure("login.signup_form_option_sex_undisclosed")
                                                    })
                                                }} id={this.props.id + "-sex-u"}>{LocUtils.TPure("login.signup_form_option_sex_undisclosed")}</button>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input disabled={this.state.engaged} type="email" onChange={(evt) => {
                                            if (evt) evt.preventDefault();
                                            this.onChangeField({ username: evt.target.value });
                                        }} placeholder={LocUtils.TPure("login.email")}
                                            value={this.state.username}
                                            className={(!!this.state.validation.username ? "inp-error" : "") + " form-control rounded-corners"}
                                            aria-label="Email" id={this.props.id + "-email"} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <input disabled={this.state.engaged} type="password" onChange={(evt) => {
                                            if (evt) evt.preventDefault();
                                            this.onChangePassword(evt.target.value);
                                        }} placeholder={LocUtils.TPure("login.signup_password")}
                                            className={(!!this.state.validation.password ? "inp-error" : "") + " form-control rounded-corners"}
                                            aria-label="Password" id={this.props.id + "-password"} />
                                    </div>
                                    {this.getMeter()}
                                    <div className="input-group mb-3">
                                        <Button onClick={this.signupConfirm} extraClass="form-signin-button" message={
                                            this.state.engaged
                                                ? <span><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{ fontSize: "1rem" }}></i>&nbsp;{LocUtils.T("login.login_btn")}</span>
                                                : LocUtils.T("login.signup")
                                        } />
                                    </div>
                                    {/* <div className="input-group mb-3">
                                        <i className="div-button small-font badge badge-secondary" onClick={this.pageActions.reset}>{LocUtils.T("login.reset")}</i>
                                    </div> */}
                                    <div className="input-group mb-3">
                                        <i className="div-button small-font badge badge-secondary" onClick={() => this.props.parentSwitchTabHandle("login")}>&lt;&nbsp;{LocUtils.T("login.signup_login")}</i>
                                    </div>
                                    <div>
                                        <br /><hr />
                                        <div className="tos">
                                            {LocUtils.T("login.signup_form_terms_both", {
                                                fb_button_text: LocUtils.T("login.signup_connect"),
                                                email_button_text: LocUtils.T("login.signup")
                                            })}
                                            <ul>
                                                <li><a target="_blank" rel="noopener noreferrer" href="http://company.zynga.com/legal/terms-of-service">{LocUtils.T("login.signup_terms_of_service")}</a></li>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.take2games.com/privacy">{LocUtils.T("login.signup_privacy_policy")}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="input-group mb-3">
                                        <p className="h4">{LocUtils.T("login.please_wait")}</p>
                                    </div>
                                    <div className="input-group mb-3">
                                        <p className="h4">
                                            <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{ fontSize: "1rem" }}></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                { this.state.confirm === true &&
                    <div className="row login-row no-gutters">
                        <div className="d-none d-md-block col-md-6 col-lg-6">
                            <img src={process.env.REACT_APP_ASSET_PATH + "/dialogs/login/confirmation_screen.png"}
                                alt="Login"
                                className="login-left-pane-image"
                            />
                        </div>
                        <div className="col col-md-6 col-lg-6">
                            <div id="login-carousel" className="carousel slide full-ht" data-keyboard="false" data-ride="carousel" data-interval="false">
                                <div className="carousel-inner carousel-min-pad full-ht">
    
                                    {/* The login form */}
                                    <div className="carousel-item active full-ht">
    
                                        {/* The form */}
                                        <div className="row no-gutters">
                                            <div className="col mt-3">
                                                <p className="h4">{LocUtils.T("login.signup_confirm_title")}</p>
                                            </div>
                                        </div>
                                        {/* <div className="row no-gutters">
                                            <div className="col">
                                                <p className="h5">{LocUtils.T("login.signup_confirm_subtitle")}</p>
                                            </div>
                                        </div> */}
                                        <hr />
                                        <div className="input-group mb-3 text-left">
                                            <p className="h6">{LocUtils.T("login.signup_confirm_subtitle")}</p>
                                        </div>
                                        <div className="input-group mb-3 text-center">
                                            <Button onClick={this.loginFBHandler}
                                                extraClass="form-signin-button"
                                                message={
                                                    <span style={{ fontSize: "1.2rem" }}>
                                                        <img src={process.env.REACT_APP_ASSET_PATH + "/trademarks/facebook/logos/transparent/facebook.png"}
                                                            alt="f" style={{ width: "2rem", marginRight: "0.3rem" }} />
                                                        {LocUtils.T("login.signup_connect")}
                                                    </span>
                                                }
                                                severity="facebook" />
                                            <Button
                                                onClick={this.onSignup}
                                                extraClass="form-signin-button signup-btn"
                                                message={
                                                    <span style={{ fontSize: "1.2rem" }}>
                                                        {LocUtils.T("login.signup_confirm_deny")}
                                                    </span>
                                                }
                                                />
                                        </div>
                                        {/* <i className="div-button small-font badge badge-secondary" onClick={this.onSignup}>{LocUtils.T("login.new_farm")}</i> */}
                                        <div style={{"position":"absolute", "bottom": "-15px"}}>
                                        <br />
                                        <hr />
                                        <div className="tos">
                                            {LocUtils.T("login.signup_form_terms", {
                                            button_text: LocUtils.T("login.signup_connect"),
                                            })}
                                            <ul>
                                            <li>
                                                <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="http://company.zynga.com/legal/terms-of-service"
                                                >
                                                {LocUtils.T("login.signup_terms_of_service")}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.take2games.com/privacy"
                                                >
                                                {LocUtils.T("login.signup_privacy_policy")}
                                                </a>
                                            </li>
                                            </ul>
                                        </div>
                                        </div>        
                                    </div>
    
    
                                    {/* The forget password form */}
                                    <div className="carousel-item full-ht forgot-margins">
                                        <div className="input-group mb-3 text-left">
                                            <h3>{LocUtils.T("login.login_forgot_password_title")}</h3>
                                        </div>
                                        <div className="input-group mb-3 text-left">
                                            <h5>
                                                {LocUtils.T("login.login_forgot_password_desc")}
                                                <br />
                                            </h5>
                                        </div>
                                        <div className={(!!this.state.validation.forgetEmail ? "inp-error" : "") + " input-group mb-3"}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <input disabled={this.state.engaged} type="email" onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({ forgetEmail: evt.target.value });
                                            }} placeholder={LocUtils.TPure("login.email")} className="form-control" aria-label="Email" id={this.props.id + "-email-forgot"} />
                                        </div>
                                        <Button onClick={this.onForgotPasswordSubmitClick} extraClass="form-signin-button" message={LocUtils.T("login.login_forgot_password_send_btn")} severity="info" />
                                        <hr />
                                        <i className="div-button small-font badge badge-secondary" onClick={this.onBackToLoginButtonClick}>&lt; {LocUtils.T("login.back")}</i>
                                    </div>
    
                                    {/* The mail sent for forget password */}
                                    <div className="carousel-item full-ht email-sent-margins">
                                        <div className="input-group mb-3 text-left">
                                            <h3>{LocUtils.T("login.login_email_sent_title")}</h3>
                                        </div>
                                        <div className="input-group mb-3 text-left">
                                            <h5>{LocUtils.T("login.login_email_sent_desc")}</h5>
                                        </div>
                                        <div className="input-group mb-3 text-left">
                                            <Button onClick={this.onForgotPasswordUnderstoodButtonClick} extraClass="form-signin-button" message={LocUtils.T("login.login_email_sent_confirm_btn")} severity="info" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
                        
        )
    }
}

export default Signup;