/**
 * AuthenticatedRoute.js
 * Authenticated route allows a route to be accessed only if the
 * user is authenticated, otherwise redirects to the home page.
 * If the component being requested requires authentication,
 * then it is redirected to the login location.
 * @author Jaiwardhan Swarnakar
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import Loading from "./Loading";
import ScrollTop from "../minions/scrollTop/ScrollTop";

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      cProps.loggedin
        ? ((cProps.needs_gdpr_verification && String(props.location.pathname).indexOf('gdpr_verification') < 0)
          ? <ScrollTop>
            <Redirect to={`/gdpr_verification?redirect=${props.location.pathname}${props.location.search}`} />
          </ScrollTop>
          : <ScrollTop>
            <C {...props} {...cProps} />
          </ScrollTop>)
        : (cProps.alreadyLoggedin === undefined || cProps.alreadyLoggedin === true)
          ? <ScrollTop>
            <Loading />
          </ScrollTop>
          : <ScrollTop>
            <Redirect to={`/?redirect=${props.location.pathname}${props.location.search}`} />
          </ScrollTop>}
  />;
