/**
 * Permanent Benefit Daily Reward Info Dialog
 *
 * Informational dialog - contains calendar view of all rewards
 * or a subset of rewards depending on hud UI variant.
 * @author Rutvi Thakkar
 */

import React from 'react';
import $ from 'jquery';
import './RewardInfoDialog.css'
import BaseComponent from "../../BaseComponent";
import ServiceLocator from '../../../ServiceLocator';
import LocUtils from "../../../util/localization/LocUtils";

class Variant1 extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            featureTimer: ""
        }
        this.getFeatureTimer = this.getFeatureTimer.bind(this);
    }

    closeModal() {
        $("#variant1Modal").modal('hide');
        ServiceLocator.Xing.Send(
            "farm2-game-frame",
            "UWG_SHOW_GAME",
            {}
        );
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'close',
            phylum: 'help_dialog'
        });
    }

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'load',
            phylum: 'help_dialog',
            zClass: "v1"

        });
        $("#variant1Modal").on('getFeatureTimer', this.getFeatureTimer);
    }

    getFeatureTimer() {
        this.setState({
            featureTimer: ServiceLocator.PB.FeatureTimer()
        });
    }

    showGameFrame(){
        ServiceLocator.Xing.Send(
            "farm2-game-frame",
            "UWG_SHOW_GAME",
            {}
        );
    }

    render() {
        return (
            <div className="modal fade" id="variant1Modal" data-keyboard="false" data-backdrop="true" tabIndex="1" role="dialog" aria-labelledby="variant1Modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-common-props" role="document">
                    <div className="modal-content" style={{ border: "none", backgroundColor: 'transparent' }}>
                        <div className="modal-body info-modal-body">
                            <div className="col no-gutters info-container">
                                <div className="row reward-info-text info-header"> {LocUtils.T("feature.dialog.v1.header")}
                                    <button type="button" onClick={this.closeModal} className="close modal-close-btn info-close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" className="">x</span>
                                    </button>
                                </div>
                                <div className="row reward-info-text info-subheader">{LocUtils.T("feature.dialog.v1.subheader")}</div>
                                <div className="row reward-info-text info-timer">{ServiceLocator.PB.FeatureTimer()}</div>

                                <div className="v1-container">
                                    <div className="row no-gutters">
                                        <div className="col-3 img-container img-animate-2">
                                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/v1/permanentbenefits_v1_02.png"}
                                                style={{ position: 'absolute', right: '-100px', top: '120px' }} />
                                        </div>

                                        <div className="col-3 img-container img-animate-3">
                                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/v1/permanentbenefits_v1_03.png"}
                                                style={{ height: '250px', width: '250px' }} />
                                        </div>

                                        <div className="col-3 img-container img-animate-4">
                                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/v1/permanentbenefits_v1_04.png"}
                                                style={{ position: 'absolute', top: '110px', marginLeft: '0px' }} />
                                        </div>
                                    </div>
                                    <div className="row no-gutters" >
                                        <div className="col-4 img-container img-animate-1">
                                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/v1/permanentbenefits_v1_01.png"}
                                                style={{ left: '-12px', position: 'absolute' }} />
                                        </div>
                                        <div className="col-4 img-container"></div>
                                        <div className="col-4 img-container img-animate-5">
                                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/v1/permanentbenefits_v1_05.png"}
                                                style={{ right: '-10px', position: 'absolute', top: '-15px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_hud_ui_v1_bg.png"}
                                style={{}} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Variant2 extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            featureTimer: ""
        }

        this.getFeatureTimer = this.getFeatureTimer.bind(this);
    }

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'load',
            phylum: 'help_dialog',
            zClass: "v2"

        });
        $("#variant2Modal").on('getFeatureTimer', this.getFeatureTimer);
    }

    closeModal() {
        $("#variant2Modal").modal('hide');
        ServiceLocator.Xing.Send(
            "farm2-game-frame",
            "UWG_SHOW_GAME",
            {}
        );
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'close',
            phylum: 'help_dialog'
        });
    }

    getFeatureTimer() {
        this.setState({
            featureTimer: ServiceLocator.PB.FeatureTimer()
        });
    }

    render() {
        return (
            <div className="modal fade" id="variant2Modal" data-keyboard="false" data-backdrop="true" tabIndex="1" role="dialog" aria-labelledby="variant2Modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-common-props" role="document">
                    <div className="modal-content" style={{ border: "none", backgroundColor: 'transparent' }}>
                        <div className="modal-body info-modal-body">
                            <div className="col no-gutters info-container">
                                <div className="row reward-info-text info-header"> {LocUtils.T("feature.dialog.v2.header")}
                                    <button type="button" onClick={this.closeModal} className="close modal-close-btn info-close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" className="">x</span>
                                    </button>
                                </div>
                                <div className="row reward-info-text info-subheader">{LocUtils.T("feature.dialog.v2.subheader")}</div>
                                <div className="row reward-info-text info-timer">{ServiceLocator.PB.FeatureTimer()}</div>

                                <div className="rewards-scrollable-container">
                                    <div className="grid-container">
                                        <RewardComponent featureData={this.props.featureData} />
                                    </div>
                                </div>
                            </div>

                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/hud_ui_v2_bg.png"}
                                style={{}} />
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

class RewardComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            totalRewards: 30
        };
    }

    render() {
        const url = (index) => "/dialogs/permanentBenefits/RewardIcons/icon_permanentbenefits_reward" + index + ".png";
        const rewardCollected = (index) => {
            return index <= this.props.featureData.daysPassed;
        }
        const cardUrl = (index) => {
            if (rewardCollected(index)) {
                return "hud_ui_reward_card_g.png"
            }
            return "hud_ui_reward_card.png"
        }

        var tmp = [];
        for (var level = 1; level <= this.state.totalRewards; level++) {
            tmp.push(level);
        }

        var rewardSlots = tmp.map(function (index) {
            return (
                <div className="reward-slot-bg">
                    <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/" + cardUrl(index)}
                        className="reward-card" />

                    <div className="reward-slot-contents">
                        <p className="reward-slot-text">{LocUtils.T("feature.dialog.v2.title", { num: index })}</p>
                        <img alt=""
                            src={process.env.REACT_APP_ASSET_PATH + url(index)}
                            style={{ height: '90px', position: 'relative' }} />
                        {rewardCollected(index) &&
                            <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/icon_check.png"}
                                className="check-mark" />
                        }
                    </div>

                    <div className="reward-name">
                        <span>{LocUtils.T("feature.dialog.v2.hover." + index)}</span>
                    </div>
                </div>
            );
        });

        return (
            <>
                {rewardSlots}
            </>
        );
    }
}


class RewardInfoDialog extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                {this.props.variant === 1 && <Variant1 />}

                {this.props.variant === 2 && <Variant2
                    featureData={this.props.featureData} />}
            </>
        );
    }
}


export default RewardInfoDialog;