/**
 * Utils.js
 * A generic collection of network utilities.
 * @author Jaiwardhan Swarnakar
 */
import SiteConstants from '../../util/SiteConstants';
var Utils = (function () {

    let _parseParam = (function (d, x, params, p, i, j) {
        return function (qs) {
            // start bucket; can't cheat by setting it in scope declaration or it overwrites
            params = {};
            // remove preceding non-querystring, correct spaces, and split
            qs = qs.substring(qs.indexOf('?') + 1).replace(x, ' ').split('&');
            // march and parse
            for (i = qs.length; i > 0;) {
                p = qs[--i];
                // allow equals in value
                j = p.indexOf('=');
                // what if no val?
                if (j === -1) params[d(p)] = undefined;
                else params[d(p.substring(0, j))] = d(p.substring(j + 1));
            }

            return params;
        };//--  fn  deparam
    })(decodeURIComponent, /\+/g)

    /**
     * Returns a url encoded string from a dict of KV pairs.
     * eg. {name: "Jai", role: "Dev"} return => "name=Jai&role=Dev"
     * Setting reverse reverse encodes. The result when reverse is set true is:
     * "Jai=name&Dev=role"
     */
    function GetURLEncoded(dict, reverse) {
        var arr = [];
        dict = dict ? dict : {};
        reverse = (reverse === true) ? true : false;
        for (var k in dict) {
            var value = String(dict[k]);
            var pair = reverse ? (value + "=" + k) : (String(k) + "=" + value);
            arr.push(pair);
        }
        return arr.join("&");
    }

    function ParseQueryParameters(searchText = window.location.search) {
        return _parseParam(searchText)
    }

    // Retrieves Source from URL parameters
    function GetSourceFromUrl() {
        let params = ParseQueryParameters();
        const hasSource = !!params["src"] || !!params["source"];
        let sourceValue = "direct";
        if (hasSource) {
            sourceValue = params["src"] ? String(params["src"]) : String(params["source"])
        }

        if (!!params["redirect"]) {
            let redirectValue = String(params["redirect"]);
            sourceValue = redirectValue !== undefined && redirectValue.indexOf('source') >= 0 && !!params[SiteConstants.UrlCodes.Games.Farm2.NOTIF_URL_QUERY_PARAM]
                ? "air_notif" : sourceValue;
        }

        return sourceValue;
    }

    // Retrieves Campaign name from URL parameters
    function GetCampaignFromUrl() {
        let params = ParseQueryParameters();
        const hasCampaign = !!params["cmp"];
        let sourceValue = "NONE";
        if (hasCampaign) {
            sourceValue = String(params["cmp"])
        }
        return sourceValue;
    }

    /**
     * Retrieves redirect url parameter if present
     * Else, get path and query string from URL
     */
    function GetUrlPath() {
        let params = ParseQueryParameters();
        const hasRedirect = !!params["redirect"];
        let redirectValue = "";
        if (hasRedirect) {
            redirectValue = String(params["redirect"])
        }

        var urlPath = redirectValue !== ""
            ? redirectValue
            : window.location.pathname + window.location.search;

        return urlPath;
    }


    /**
     * Validates an email address for validation
     */
    function ValidateEmail(mail) {
        return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    /**
     * Validates a password for validation
     */
    function ValidatePassword(password) {
        return (!password || password.length < 7) ? false : true;
    }

    return {
        GetURLEncoded: GetURLEncoded,
        ParseQueryParameters: ParseQueryParameters,
        ValidateEmail: ValidateEmail,
        ValidatePassword: ValidatePassword,
        GetSourceFromUrl: GetSourceFromUrl,
        GetUrlPath: GetUrlPath,
        GetCampaignFromUrl: GetCampaignFromUrl
    }
})();

export default Utils;