/**
 * GoogleOptimize.js
 * Collection of Google Optimize methods
 * Google Optimize is being used for creating experiments and doing AB
 * @author  Juhi Sharma
 */

import ServiceLocator from "../../ServiceLocator";

var GoogleOptimize = (function () {
  let pluginCheckNotTracked = true;

  async function Initialize() {
    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }
    this.intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        document.cookie = "_gaexp=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        clearInterval(this.intervalId);
      }
    }, 100);
  }

  /**
   * 
   * @param {String} experimentName An experiment name that you are looking for
   * @return {Int} The assignment value of this experiment. If not present, then 0
   */
  function GetExperimentVariant(experimentName = undefined) {
    let variant = 0;
    if (!!window.google_optimize) {
      variant = window.google_optimize.get(experimentName);
      if (!!pluginCheckNotTracked) {
        ServiceLocator.ZTrack.Cast({
          counter: "gopt_plugin",
          kingdom: "session_check",
          phylum: "enabled"
        });
        pluginCheckNotTracked = false
      }
    } else {
      // Potentially blocked by an extension. Failover
      // to override defaults as truth source and cast a track.
      variant = ServiceLocator.Env.OptimizationOverrides.Optimize.Get(String(experimentName));
      if (!!pluginCheckNotTracked) {
        ServiceLocator.ZTrack.Cast({
          counter: "gopt_plugin",
          kingdom: "session_check",
          phylum: "blocked"
        });
        pluginCheckNotTracked = false
      }
    }
    if (variant === undefined) return 0;
    return variant;
  }

  return {
    GetExperimentVariant: GetExperimentVariant,
    Initialize: Initialize
  }
})();

export default GoogleOptimize;
