/**
 * NetworkManager.js
 * Collection of network related wrappers and
 * utilities.
 * @author Jaiwardhan Swarnakar
 */
import Ajax from './Network/Ajax'
import Fetch from './Network/Fetch'
import Queue from './Network/Queue'
import Utils from './Network/Utils';

/**
 * Interface for various network related calls
 */
var NetworkManager = (function() {

    return {
        Ajax    : Ajax,
        Fetch   : Fetch,
        Queue   : Queue,
        Utils   : Utils
    }
})();

export default NetworkManager;