/**
 * Welcome popup for players new to ZDC 2.0
 * @author Juhi Sharma
 */
import React from "react";
import BaseComponent from "../../BaseComponent";
import LocUtils from "../../../util/localization/LocUtils";
import DataStore from "../../../data/DataStore";

class WelcomeComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      hide: DataStore.LocalStorage.Get("welcome_popup"),
    };
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.setState({
      hide: "true",
    });
    DataStore.LocalStorage.Store("welcome_popup", "true");
  }

  render() {
    if(this.state.hide !== "true" && this.props.hideWelcome){
      this.closeDialog();
    }

    return (
      <div>
      { this.state.hide !== "true" && (
        <div
        id="noty_layout__topRight"
        role="alert"
        aria-live="polite"
        className={this.state.hide === "true" ? "fade" : ""}
        style={{
          fontSize: "0.8rem",
          backgroundColor: "white",
          padding: "10px 10px 10px 10px",
          top: "75px",
          right: "20px",
        }}
      >
        <button
          type="button"
          className="close login-modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
          style={{
            fontSize: "1rem",
            lineHeight: "0.4",
            opacity: "1",
            padding: "0px 5px 10px",
          }}
          onClick={this.closeDialog}
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="row no-gutters">
          <div className="col-3">
            <img
              style={{
                width: "90%",
              }}
              className="branding-hover-show zynga-logo-branding"
              src={process.env.REACT_APP_ASSET_PATH + "/zyngalogo.png"}
              alt=""
            />
          </div>
          <div className="col-9">
            <span style={{ lineHeight: "1.25" }}>
              {LocUtils.TPure("notification.welcome")}
            </span>
          </div>
        </div>
      </div>
      )}
    </div>
    );
  }
}

export default WelcomeComponent;
