/**
 * BaseComponent.js
 * A base for all components in this page.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react'
import ZLogger from '../util/zlogger/zlogger';

class BaseComponent extends React.Component {
    /** .log component support */
    log(...logParams) {
        ZLogger.log(...logParams);
    }

    /** .error component support */
    error(...errParams) {
        ZLogger.error(...errParams);
    }

    /** .fatal component support */
    fatal(...fatalParams) {
        ZLogger.fatal(...fatalParams);
    }

    warn(...warnParams) {
        ZLogger.warn(...warnParams);
    }
}

export default BaseComponent;