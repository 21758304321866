/**
 * Directory.js
 * Maintains a directory of 'identified' users by zid. yellowPages contain the 
 * zid => {object} mapping. User data is fetched via user_attributes api.
 * Unidentified zid(s) can be set to 'Identify' them via the user api and 
 * store them back in yellowPages by setting the onIdentified to Add|Zpersona
 * @author jswarnakar
 */

import ServiceLocator from "../../../../ServiceLocator";
import ZLogger from "../../../../util/zlogger/zlogger";
import ZPersona from "../ZPersona/ZPersona";

var Directory = (function () {

    var yellowPages = {};

    /**
     * Add a user|list-of-users|user_attribute api response users to the
     * yellow pages.
     * @param {Object|Array} toAdd Can contain a Object.zid, list-of-Objects.zid, Object.users.{zid => {...userAttributes}}
     */
    function Add(toAdd = {}, force = false) {
        // See if this is just one person Object
        if (!Array.isArray(toAdd) && toAdd.zid !== undefined) {
            toAdd = [toAdd];
        }

        // See if this is a list of objects
        if (Array.isArray(toAdd) && toAdd.length > 0) {
            toAdd.forEach(person => {
                const zid = person.zid;
                yellowPages[String(zid)] = (!force && yellowPages[zid]) || (new ZPersona(person));
            })
            return;
        }

        if (toAdd.e !== undefined) {
            // Looks like a response from user_attributes api,
            // check for response errors if present.
            if (toAdd.e !== 0) {
                return ZLogger.error("Directory:Add Non 0 error code when adding: ", toAdd)
            }
        }

        // See if it contains users Object (zid -> {})
        if (toAdd.users !== undefined && Object.keys(toAdd.users) > 0) {
            const usersToAdd = toAdd.users;
            Object.keys(usersToAdd).forEach(eachZid => {
                yellowPages[String(eachZid)] = (!force && yellowPages[eachZid]) || (new ZPersona(usersToAdd[eachZid]));
            })
        }
    }

    /**
     * Identifies a bunch of zids for people and stores them in Yellow pages
     * for future lookups.
     * @param {string|Array|Object} people A zid|list-of-zids|Object.zid|list-of-Objects.zid to Identify
     */
    function Identify(people = [], onIdentified = () => { }) {
        if (!people) {
            ZLogger.error("Directory:Identify Undefined people! Damn!");
            return;
        }
        // possibly looks like one single object.
        // Convert to a list of object
        if (!Array.isArray(people)) {
            people = [people];
        }

        let zids = [];
        let alreadyAdded = [];
        let toAddPeople = [];
        
        people.forEach(eachPerson => {
            const thisZid = (eachPerson.zid !== undefined) ? eachPerson.zid : eachPerson;
            if (!yellowPages[thisZid]) {
                zids.push(thisZid)
                if (zids.length > 0) {
                    try {
                        toAddPeople.push(eachPerson.profile);
                        ServiceLocator.SocialNetworkManager.People.Directory.Add(toAddPeople);
                    }catch (error) {
                        //ZLogger.fatal("Unexpected error while adding recommended friends: "+error);
                    }
                }
            } else {
                alreadyAdded.push(thisZid);
            }
        });

        if (alreadyAdded.length === people.length) {
            let personas = [];
            alreadyAdded.forEach(zid => {
                personas.push(ServiceLocator.SocialNetworkManager.People.Directory.Lookup(zid));
            })
            onIdentified(personas);
        }
    }

    /**
     * Lookups the yellow pages to get the persona of that
     * person (if Identified already).
     * Returns False if not found.
     * @param {String} zid A zid to lookup
     */
    function Lookup(zid) {
        const zidToLookup = String(zid);
        if (!!yellowPages[zidToLookup]) return yellowPages[zidToLookup];
        return false;
    }

    return {
        Add: Add,
        Identify: Identify,
        Lookup: Lookup
    }
})();

export default Directory;