/**
 * ScrollTop.js
 * A simple minion which works best with Router. Simply
 * scrolls the page to the top when it has mounted.
 * Use it as an outer layer over the top of your component.
 * @author Jaiwardhan Swarnakar
 */
import BaseComponent from '../../BaseComponent'

class ScrollTop extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentLocation: undefined
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            this.props.children
        )
    }
}

export default ScrollTop;