

import React from 'react'
import BaseComponent from '../../BaseComponent'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery'
import './EmailVerification.css'
import LocUtils from '../../../util/localization/LocUtils';
import Button from '../buttons/Buttons';
import ServiceLocator from '../../../ServiceLocator'
import DataStore from "../../../data/DataStore";
import Toast from "../../../util/toasts/Toast";


class EmailVerification extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            signUpNew:this.props.signUpNew
        }
        this.onClickEmailVerification = this.onClickEmailVerification.bind(this);
    }

    componentDidMount() {
        var currentState = this.state;
        this.setState(currentState);
    }

    onClickEmailVerification(evt) {
        if (evt) evt.preventDefault();
        var email = DataStore.LocalStorage.Get("emailIdToSendVerification");

        $("#emailVerificationModal").modal('hide')

        ServiceLocator.Auth.Zynga.SendVerificationEmail({
            email,
            onCompleteCallback: (resp) => {
                Toast.Show({
                    message: LocUtils.TPure("toasts.send_email_verification"),
                    severity: Toast.SEVERITY_CODES.INFO
                })
            },
            onFailureCallback: (err) => {
                Toast.Show({
                    message: LocUtils.TPure("toasts.something_went_wrong_on_email_verified_check"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
            }
        })
        //SEND VERIFICATION EMAIL DU
    }


    render() {
        var classProps = "tab-pane fade show " + this.props.active;
        return (
                <div className="EmailVerificationPage" style={{backgroundColor: "#222222"}}>
                    <div className="modal fade" id="emailVerificationModal" data-keyboard="false" data-backdrop="static" tabIndex="1" role="dialog" aria-labelledby="emailVerificationModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header toc-header-bg">

                                    <button type="button" className="close login-modal-close-btn grey-btn" style={{ marginRight: "-0.5rem" }}
                                            data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span>
                                    </button>
                                    <div className="row">
                                            <div className="col col-2 zynga-logo-col">
                                                    <img alt="fp" className="forgot-password-lock-icon zynga-logo-icon" src="https://zlive.cdn.zynga.com/zdc_static/zdc-c436fe34_b1882547_1081b27d-static/js/compiled/zui/modules/widgets/zdc/common/header/zdc_logo_v2.png" />
                                            </div>
                                            <div className="col col-10 text-center text-white toc-header-text">
                                                <h3 className="modal-title" id="accountVerificationModalTitle"><b>{LocUtils.T("login.non_email_verification_dialog_header")}</b></h3>
                                                <br />
                                            </div>


                                    </div>
                                </div>
                
                                <div className="modal-body">

                                    <div className={classProps} role="tabpanel" aria-labelledby={this.props.tabRef} id={this.props.id}>
                                        <div id="verify-tos-carousel" className="carousel slide" data-keyboard="false" data-ride="carousel" data-interval="false">
                                            <div className="carousel-inner">

                                                {/* The accept button form */}
                                                <div className="carousel-item active">
                                                    <div>
                                                        <ul>
                                                            <li>{LocUtils.T("login.non_email_verification_dialog_data1")}</li>
                                                            <li>{LocUtils.T("login.non_email_verification_dialog_data2")}</li>
                                                            <li>{LocUtils.T("login.non_email_verification_dialog_data3")}</li>
                                                        </ul>
                                                    </div>
                                                    <br />
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-2" ></div>
                                                        <div className="col-8" style={{ margin: "auto", textAlign: "center", bottom: "15px" }}>
                                                            <Button message={LocUtils.T("login.non_email_verification_dialog_button")} onClick={this.onClickEmailVerification} />
                                                        </div>
                                                        <div className="col-2" ></div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default EmailVerification;