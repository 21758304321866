/**
 * AuthData.js
 * A util to store in memory temporary storage as KV pairs
 * which can be set in any section of the page and retrieved
 * else where. Temporary information or flags can also be set here.
 * @author Jaiwardhan Swarnakar
 */
var AuthData = (function() {

    var _data = {};

    function AddData(postAuthData, extracts) {
        extracts = postAuthData;
        return extracts;
    }

    function Store(data, networkKey) {
        networkKey = (networkKey === undefined)? "default": networkKey;
        _data[networkKey] = data;
    }

    function Fetch(networkKey) {
        networkKey = (networkKey === undefined)? "default": networkKey;
        return _data[networkKey];
    }

    function Delete(networkKey) {
        networkKey = (networkKey === undefined)? "default": networkKey;
        delete _data[networkKey];
    }

    return {
        AddData         : AddData,
        Delete          : Delete,
        Fetch           : Fetch,
        Store           : Store,
    }
})();

export default AuthData;