/**
 * PermanentBenefitManager.js
 * Contains all methods (fetch/update) pertaining to the feature.
 * Maintains a local copy of the feature data that can
 * be accessed from other components.
 * @author Rutvi Thakkar
 */
import ServiceLocator from '../../ServiceLocator';
import ZLogger from "../../util/zlogger/zlogger";
import LocUtils from "../../util/localization/LocUtils";

var PB = (function () {
    let featureData = {};

    function FetchData(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_getPermanentBenefitData",
            payload: {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': "application/json"
                },
                referrer: 'client',
                body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
            },
            token: "PERM_BENEFITS_FETCH_INFO"
        })
            .then((res) => { return res.json() })
            .then((parsed) => {
                if (parsed.e !== undefined && parsed.e === 0 && parsed.data) {
                    featureData = parsed.data;
                }
                onCompleteCallback(parsed)
            })
            .catch((err) => {
                onFailureCallback(err)
            })
    }

    /**
     * Eligibility criteria - within bounds of feature timer and
     * hasn't collected the reward today.
     */
    function EligibleForReward() {
        if (featureData !== undefined && featureData.daysPassed < 30 && !this.FeatureExpired() &&
            (!ServiceLocator.Site.IsSameDay(new Date(), new Date(featureData.rewardClaimedTS * 1000)) || featureData.rewardClaimedTS === 0)) {
            return true;
        }
        return false;
    }

    /** Either feature timer is up or player has collected all the rewards */
    function FeatureExpired() {
        if (featureData !== undefined) {
            if ((featureData.featureEndTime > 0 && featureData.featureEndTime - ServiceLocator.Site.GetCurrentTimestamp() < 0) || featureData.daysPassed >= featureData.duration) {
                return true;
            }
        }
        return false;
    }

    /** Countdown feature timer */
    function FeatureTimer() {
        if (ServiceLocator.PB.FeatureExpired()) {
            return LocUtils.T("expired");
        }


        if (featureData !== undefined && featureData.featureEndTime === 0) {
            return LocUtils.T("feature.hud.timer",
                { featureTimer: featureData.duration + " " + LocUtils.T("days") });
        }

        let featureTimer = "";
        var timeleft = featureData.featureEndTime - ServiceLocator.Site.GetCurrentTimestamp();

        var days = Math.floor(((timeleft / 60) / 60) / 24)
        if (days !== 0) {
            featureTimer = days + " " + LocUtils.T("days");
        } else {
            var hours = Math.floor((timeleft / 60) / 60)
            if (hours !== 0) {
                featureTimer = hours + " " + LocUtils.T("hours");
            } else {
                var mins = Math.floor(timeleft / 60)
                if (mins !== 0) {
                    featureTimer = mins + " " + LocUtils.T("minutes");
                } else {
                    featureTimer = timeleft + " " + LocUtils.T("seconds");
                }
            }
        }

        return LocUtils.T("feature.hud.timer", { featureTimer: featureTimer });
    }

    function UpdateRewardClaimed(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (featureData !== undefined) {
            var params = {
                csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
                request: "rewardClaim",
            }

            ServiceLocator.NetworkManager.Fetch.Call({
                url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_updatePermanentBenefitData",
                payload: {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': "application/json"
                    },
                    referrer: 'client',
                    body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
                },
                token: "PERM_BENEFITS_REWARD_CLAIM"
            })
                .then((res) => { return res.json() })
                .then((res) => {
                    if (res.e !== undefined && res.e === 0 && res.data) {
                        featureData = res.data;
                    }
                    onCompleteCallback(res)
                })
                .catch((err) => {
                    onFailureCallback(err)
                })
        }
    }

    function UpdateMotdSeen(method, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (featureData !== undefined) {
            var params = {
                csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
                request: method
            }

            ServiceLocator.NetworkManager.Fetch.Call({
                url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_updatePermanentBenefitData",
                payload: {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': "application/json"
                    },
                    referrer: 'client',
                    body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
                },
                token: "PERM_BENEFITS_REWARD_CLAIM"
            })
                .then((res) => { return res.json() })
                .then((res) => {
                    if (res.e !== undefined && res.e === 0 && res.data) {
                        featureData = res.data;
                    }
                    onCompleteCallback(res)
                })
                .catch((err) => { onFailureCallback(err) })
        }
    }

    function GetRewardNum() {
        if (featureData !== undefined) {
            return featureData.daysPassed + 1;
        }
    }
    function GetNumberOfDaysPassed() {
        if (featureData !== undefined) {
            return featureData.daysPassed;
        }
    }
    
    function GetMOTDSeenCounter() {
        if (featureData !== undefined) {
            return featureData.motdSeenCounter;
        }
    }

    return {
        EligibleForReward: EligibleForReward,
        FetchData: FetchData,
        FeatureExpired: FeatureExpired,
        UpdateRewardClaimed: UpdateRewardClaimed,
        UpdateMotdSeen: UpdateMotdSeen,
        FeatureTimer: FeatureTimer,
        GetRewardNum: GetRewardNum,
        GetMOTDSeenCounter:GetMOTDSeenCounter,
        GetNumberOfDaysPassed:GetNumberOfDaysPassed
    }
})();

export default PB;