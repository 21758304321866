/**
 * SiteTour.js
 * Helps in giving a guided site tour of our website
 * Uses React Joyride Plugin
 * @author Juhi Sharma
 */
import React from "react";
import Joyride from "react-joyride";
import BaseComponent from "../../BaseComponent";
import LocUtils from "../../../util/localization/LocUtils";
import DataStore from "../../../data/DataStore";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Nav.css";
import "../../../../styles/generic.css";

/**
 * The component which houses the site tour steps
 */
class SiteTourComponent extends BaseComponent {
  _stepStorageKey = "step";
  startIndex = DataStore.LocalStorage.Get(this._stepStorageKey);

  state = {
    steps: [
      {
        target: ".fa-users",
        content: LocUtils.T("tour.community.1"),
      },
      {
        target: ".fa-bell",
        content: LocUtils.T("tour.notification.1"),
      },
      {
        target: ".connect-user-state-loggedin",
        content: LocUtils.T("tour.profile.1"),
      },
    ],
  };

  handleJoyrideCallback = (data) => {
    const { action, index } = data;

    let newIndex = parseInt(index) + parseInt(this.startIndex);
    if(action !== "reset"){
      DataStore.LocalStorage.Store(this._stepStorageKey, newIndex);
    }
    
  };

  render() {
    if (this.props.loggedin === true) {
      const { steps } = this.state;
      if (
        this.startIndex === null ||
        this.startIndex === undefined ||
        isNaN(this.startIndex)
      ) {
        this.startIndex = 0;
      }
      return (
        this.props.variant === "2" &&
        this.startIndex < steps.length - 1 &&
        this.props.alreadyLoggedin !== undefined &&
        this.props.loginFetching === false && (
          <div className="app">
            <Joyride
              callback={this.handleJoyrideCallback}
              locale={{
                back: LocUtils.TPure("tour.controls.back").toString(),
                next: LocUtils.TPure("tour.controls.next").toString(),
                last: LocUtils.TPure("tour.controls.last").toString(),
                skip: LocUtils.TPure("tour.controls.skip").toString(),
              }}
              continuous={true}
              showSkipButton={true}
              steps={steps.slice(this.startIndex)}
              styles={{
                options: {
                  zIndex: 10000,
                  "margin-top": "15px",
                },
              }}
            />
          </div>
        )
      );
    } else {
      return null;
    }
  }
}

export default SiteTourComponent;
