/**
 * Platform.js
 * Uses Bowser to keep the useragent data, device type etc
 * ready for future usage, such rendering another set of
 * components for different devices.
 * For reference refer to npm bowser manual.
 * @author Jaiwardhan Swarnakar
 */
import Bowser from 'bowser';

var Platform = (function () {

    var _data = {};
    var _done = false;

    function Fetch() {
        Init();
        return _data;
    }

    function Init() {
        if (!_done) {
            _data = Bowser.parse(window.navigator.userAgent);
            _done = true;
        }
    }

    function Set(data) {
        if (data !== undefined && Object.keys(data).length > 0) {
            _data = data;
        }
    }
    return {
        Fetch: Fetch,
        Init: Init,
        Set: Set
    }
})();
window.Platform = Platform;
export default Platform;