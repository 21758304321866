/**
 * Footer.js
 * Contains a footer component which should house
 * links to things like Legal, Privacy policy, forums
 * or site navigation options or social connect
 * @author Jaiwardhan Swarnakar
 */
import React from 'react'
import BaseComponent from '../../BaseComponent'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './footer.css'
import '../../../../styles/generic.css'
import LocUtils from '../../../util/localization/LocUtils';
import ServiceLocator from '../../../ServiceLocator';

class NavLinks extends BaseComponent {

    render() {
        return (
            <a className={(LocUtils.CurrentIsRTL() ? "bilingual-excerpt" : "") + " nav-link nav-link-styles"} rel="noopener noreferrer" target="_blank"
                onClick={() => {
                    ServiceLocator.ZTrack.Cast({
                        counter: "click",
                        kingdom: "footer",
                        phylum: "footer-links",
                        zClass: this.props.type ? this.props.type : "general"
                    })
                }}
                href={this.props.href}>{String(this.props.text).toUpperCase()}</a>
        )
    }
}


class FooterSocialIcon extends BaseComponent {
    render() {
        return (
            <a href={this.props.takeTo} target="_blank" rel="noopener noreferrer"
                onClick={() => {
                    ServiceLocator.ZTrack.Cast({
                        counter: "click",
                        kingdom: "footer",
                        phylum: "social-links",
                        zClass: this.props.type ? this.props.type : "general"
                    })
                }} >
                <img className="footer-social-icon div-button" src={this.props.src} alt="socialIcon" />
            </a>
        )
    }
}

class Footer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            optOut: false
        }
        this.optOut = this.optOut.bind(this);
    }

    optOut() {
        if (this.state.optOut) return; // Already attempting an opt-out
        this.setState({
            optOut: true
        }, () => {
            ServiceLocator.ZTrack.Cast({
                counter: "click",
                kingdom: "footer",
                phylum: "opt-out",
                family: String(ServiceLocator.SocialNetworkManager.SessionData().zid), // If not logged in this would be 0
            })
            // Possibly show a dialog
            this.props.onOptOutClick();
        })
    }

    render() {
        return (
            <div>
                <section id="footer">
                    <div className="row footer-row ">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center footer-branding-logo-col" >
                            <img className="footer-branding-logo" src={process.env.REACT_APP_ASSET_PATH + "/zynga-logo-off.png"} alt="Zynga Logo" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
                            <div className="container">
                                <div className="row">
                                    {/* <div className="col opt-out-col">
                                        {
                                            !this.state.optOut
                                            && <span className="opt-out" onClick={this.optOut}>
                                                <i className="fa fa-reply" aria-hidden="true"></i>&nbsp;{LocUtils.T("footer.opt_out")}
                                            </span>
                                        }
                                        {
                                            !!this.state.optOut
                                            && <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{ fontSize: "1rem" }}></i>
                                        }
                                    </div> */}
                                </div>
                                <div className="row">

                                    <div className="col-sm-12 text-center text-white">
                                        {/* <div className="col-10"> */}
                                        <ul className="nav justify-content-center">
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="support"
                                                    text={LocUtils.TPure("footer.support")}
                                                    href="https://www.zynga.com/support/"
                                                />
                                            </li>
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="contact-us"
                                                    text={LocUtils.TPure("footer.contact")}
                                                    href="https://www.zynga.com/about/contact-us"
                                                />
                                            </li>
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="privacy"
                                                    text={LocUtils.TPure("footer.privacy")}
                                                    href="https://www.take2games.com/privacy"
                                                />
                                            </li>
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="tos"
                                                    text={LocUtils.TPure("footer.tos")}
                                                    href="https://www.zynga.com/legal/terms-of-service"
                                                />
                                            </li>
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="cookies"
                                                    text={LocUtils.TPure("footer.cookies")}
                                                    href="https://www.zynga.com/privacy/about-cookies"
                                                />
                                            </li>
                                            <li className="nav-item">
                                                <NavLinks
                                                    type="security"
                                                    text={LocUtils.TPure("footer.security")}
                                                    href="https://www.zynga.com/security/"
                                                />
                                            </li>
                                        </ul>
                                        {/* </div> */}
                                    </div>
                                    <hr />
                                </div>
                                <hr style={{ border: "0.15px grey solid" }} />
                            </div>
                        </div>
                        <div className="d-none d-xs-none col-xs-0 d-sm-none col-sm-0 d-md-none col-md-0 d-lg-block col-lg-2 d-xl-block col-xl-2">

                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-sm-12 footer-social-icons text-center">
                            <ul className="nav justify-content-center">
                                <li className="nav-item">
                                    <FooterSocialIcon
                                        type="facebook"
                                        src={process.env.REACT_APP_ASSET_PATH + "/facebook_circle.png"}
                                        takeTo="https://www.facebook.com/FarmVille2/" />
                                </li>
                                <li className="nav-item">
                                    <FooterSocialIcon
                                        type="youtube"
                                        src={process.env.REACT_APP_ASSET_PATH + "/youtube_circle.png"}
                                        takeTo="https://www.youtube.com/channel/UCezmLKmBxLJ66HapE8O0XBA" />
                                </li>
                                <li className="nav-item">
                                    <FooterSocialIcon
                                        type="twitter"
                                        src={process.env.REACT_APP_ASSET_PATH + "/twitter_cicle.png"}
                                        takeTo="https://twitter.com/farmville2" />
                                </li>
                                <li className="nav-item">
                                    <FooterSocialIcon
                                        type="instagram"
                                        src={process.env.REACT_APP_ASSET_PATH + "/instagram_cicle.png"}
                                        takeTo="https://www.instagram.com/farmville_2/" />
                                </li>
                            </ul>
                        </div>
                    </div>

                </section>
                <div className="row" id="branding-section">
                    ©{(new Date()).getFullYear()} ZYNGA INC. ALL RIGHTS RESERVED
                </div>
            </div>
        )
    }
}

export default Footer;