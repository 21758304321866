import React from 'react';
import $ from 'jquery';
import BaseComponent from '../../BaseComponent';
import './GdprComponent.css';
import '../dialogCommons.css'
import '../../../../styles/generic.css';
import ServiceLocator from '../../../ServiceLocator';
import Toast from '../../../util/toasts/Toast';
import LocUtils from '../../../util/localization/LocUtils';
import LoadingWidget from '../LoadingWidget';

class GdprComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            pin: undefined,
        }
        this.prepareGDPRPin = this.prepareGDPRPin.bind(this);
        this.prepareLoad = this.prepareLoad.bind(this);
        this.resetSelf = this.resetSelf.bind(this);
    }

    _constants = {
        fv2IframeID: "farm2-game-frame",
        UWG_TYPE_INGRESS_HIDE_GAME: "UWG_HIDDEN_GAME",
        UWG_TYPE_INGRESS_SHOW_GAME: "UWG_SHOWN_GAME",
        UWG_TYPE_EGRESS_HIDE_GAME: "UWG_HIDE_GAME",
        UWG_TYPE_EGRESS_SHOW_GAME: "UWG_SHOW_GAME",
    }

    componentDidMount() {
        // Bind a method to this modal for data updations for gdpr
        // Use jquery .trigger() to fire this method
        $("#gdprShowModal").on('prepareGDPRPin', this.prepareGDPRPin);
        $("#gdprShowModal").on('prepareLoad', this.prepareLoad)
    }

    pageActions = {
        showError: () => {
            Toast.Show({
                message: LocUtils.TPure("toasts.something_went_wrong"),
                severity: Toast.SEVERITY_CODES.ERROR
            })
        }
    }

    validation = {
        inputIllegal: (sample) => {
            if (sample === undefined || typeof (sample) !== "string" || sample.length === 0) {
                return true;
            }
            return false;
        }
    }

    prepareLoad() {
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_HIDE_GAME,
            {}
        );
    }

    prepareGDPRPin() {
        var currentState = this.state;
        currentState.ping = undefined;
        this.setState(currentState, () => {
            ServiceLocator.Auth.Zynga.Session.GenerateGDPRPin(
                this.props.data,
                (resp) => {
                    if (resp !== undefined && resp.pin !== undefined) {
                        currentState.pin = resp.pin;
                        this.setState(currentState)
                    } else {
                        this.pageActions.showError();
                    }
                },
                (err) => {
                    super.fatal("Error on generating PIN from server: ", err);
                    this.pageActions.showError();
                }
            )
        })
    }

    resetSelf() {
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_SHOW_GAME,
            {}
        );
    }

    render() {
        return (
            <div>
                {/* GDPR Pin modal */}
                <div className="modal fade" id="gdprShowModal"
                    data-keyboard="false" data-backdrop="static"
                    tabIndex="1" role="dialog" aria-labelledby="gdprShowModal" aria-hidden="true">
                    <div className="modal-dialog modal-account-props" role="document">
                        {/* Modal Content */}
                        <div className="modal-content">

                            {/* Modal Header (X)*/}
                            <div className="modal-header modal-head gdpr-modal-header">
                                <p className="h5 modal-title" id="payFrameModalTitle">{LocUtils.T("dialogs.gdpr.title")}</p>
                                <button type="button" onClick={this.resetSelf} className="close modal-close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="">×</span></button>
                            </div>

                            {/* Modal Body */}
                            <div className="modal-body gdpr-modal-body">

                                {/* <div className="gdpr-frame-filler-footer"></div> */}
                                {/* Iframe */}
                                <div className="gdpr-frame gdpr-body-text">
                                    <p>{LocUtils.T("dialogs.gdpr.sub1")}</p>
                                    <p>{LocUtils.T("dialogs.gdpr.sub2")}</p>

                                    <div className="row">
                                        <div className="col-10">
                                            <p className="h6">{LocUtils.T("dialogs.gdpr.sub3", { player_zid: this.props.data.zid })}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-10">
                                            {this.state.pin === undefined && <p className="h6">{LocUtils.T("dialogs.gdpr.sub4_temp")}&nbsp;<LoadingWidget id="gdpr_pinwait" show='true' /></p>}
                                            {this.state.pin !== undefined && <p className="h6">{LocUtils.T("dialogs.gdpr.sub4", { pin: this.state.pin })}</p>}
                                        </div>
                                    </div>
                                    <br />
                                    <small>{LocUtils.T("dialogs.gdpr.sub5")}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GdprComponent;
