/**
 * Env.js
 * Loads environment data such game maps and optimization
 * overrides etc. This configs are not required to be part
 * of .env* and can be safely loaded from the environment.
 * @author Jaiwardhan Swarnakar
 */
import ServiceLocator from '../ServiceLocator'
let PageMaps = (() => {
    let pages = {}
    let gameMaps = {}

    // Gets friendly name from a known path
    let GetFriendlyName = (game = "Farm2", forPath = undefined) => {
        forPath = !!forPath ? forPath : "/";
        if (!!pages[forPath]) {
            return pages[forPath].friendly_name;
        } else {
            if (gameMaps.hasOwnProperty(game)) {
                var apps = gameMaps[game];
                for (var key in apps) {
                    if (apps.hasOwnProperty(key) && forPath.indexOf(apps[key].href) >= 0) {
                        return apps[key].friendly_name;
                    }
                }
            }
        }
        return { friendly_name: "" }
    }

    let Import = (config) => {
        if (!!config.PageMaps) {
            pages = config.PageMaps
        }

        if (!!config.GameMaps) {
            gameMaps = config.GameMaps
        }
    }

    return {
        Import: Import,
        GetFriendlyName: GetFriendlyName
    }

})()


let GameMaps = (() => {
    let maps = {}

    let Get = (game = "Farm2", ns = "farmville-two") => {
        if (!!maps[game] && !!maps[game][ns]) {
            return maps[game][ns]
        }
        return { href: "", base: "" }
    }

    let Import = (config) => {
        if (!!config.GameMaps) {
            maps = config.GameMaps
        }
    }

    return {
        Get: Get,
        Import: Import,
    }
})()

let OptimizationOverrides = (() => {
    let Optimize = {
        defaults: {},
        Get: (_e) => {
            if (!_e || Optimize.defaults[String(_e)] === undefined) return 0;
            return parseInt(Optimize.defaults[String(_e)])
        }
    }
    let EOS = {
        defaults: {},
        Get: (_e) => {
            if (!_e || EOS.defaults[String(_e)] === undefined) return 0;
            return parseInt(EOS.defaults[String(_e)])
        }
    }

    let Import = (config) => {
        if (
            !!config.OptimizationOverrides && !!config.OptimizationOverrides.Optimize &&
            !!config.OptimizationOverrides.Optimize.defaults
        ) {
            Optimize.defaults = config.OptimizationOverrides.Optimize.defaults
        }

        if (
            !!config.OptimizationOverrides && !!config.OptimizationOverrides.EOS &&
            !!config.OptimizationOverrides.EOS.defaults
        ) {
            EOS.defaults = config.OptimizationOverrides.EOS.defaults
        }
    }

    return {
        Import: Import,
        Optimize: {
            Get: Optimize.Get
        },
        EOS: {
            Get: EOS.Get
        }
    }
})();

let Platforms = (() => {
    let configured = {}

    let Import = (config) => {
        if (!!config.Platforms) {
            configured = config.Platforms
        }
    }

    let Get = (game = "Farm2", name = "fb") => {
        if (!!configured[game] && !!configured[game][name]) return configured[game][name];
        return { url: "/" }
    }

    return {
        Get: Get,
        Import: Import
    }
})()

let Env = (() => {
    let configuredGames = {}
    import(process.env.REACT_APP_CUSTOM_ENV_PATH)
        .then(_import => {
            let loadConfig = _import.default;
            OptimizationOverrides.Import(loadConfig);
            Platforms.Import(loadConfig);
            GameMaps.Import(loadConfig);
            PageMaps.Import(loadConfig)
            configuredGames = loadConfig.configuredGames;

            // Fire is called post loading Env to ascertain the landing page Stat
            // is fired only after the appropriate config is imported.
            ServiceLocator.Events.Queue.Fire(ServiceLocator.Events.Queue.Contexts.EnvLoad)
        })
    return {
        meta: "Contains other environment configs which cant be part of .env",
        configuredGames: configuredGames,
        GameMaps: {
            Get: GameMaps.Get
        },
        OptimizationOverrides: {
            Optimize: {
                Get: OptimizationOverrides.Optimize.Get
            },
            EOS: {
                Get: OptimizationOverrides.EOS.Get
            }
        },
        Platforms: {
            Get: Platforms.Get
        },
        PageMaps: {
            GetFriendlyName: PageMaps.GetFriendlyName
        }
    }
})();

export default Env;