/**
 * People.js
 * People - mysterious homo sapiens ruling the earth who often
 * come to play on a social networking/gaming website. People
 * can either be Friends or just People.
 * Users can 'Friend' unknown people, but can only
 * 'Unfriend' friends.
 * Actions here are applicable to People (not friends)
 */
import Friends from "./Friends/Friends";
import ServiceLocator from "../../../ServiceLocator";
import ZLogger from "../../../util/zlogger/zlogger";
import Directory from "./Directory/Directory";

var People = (function () {

    let addQueue = {
        csrf: "",
        zids: []
    };

    let addFlushActive = undefined;

    function addQueueReset() {
        clearTimeout(addFlushActive); addFlushActive = undefined;
    }

    /**
     * Flushes a list of zids from the queue to add them as friends.
     * Takes a snapshot of at most 5 items from the _addQueue and processes
     * it. This way keeps _addQueue.zids free to add more items meanwhile a 
     * flush has already started.
     * @param {fn} onFailureCallback method to call upon when failed resp
     */
    function addFlush(onFailureCallback = ZLogger.fatal) {
        if (addQueue.zids.length === 0) { clearTimeout(addFlushActive); addFlushActive = undefined; return }
        const requestingCsrf = addQueue.csrf;
        const zids = addQueue.zids.splice(0, 5);
        // At this point we no longer need the queue flush timeout to be
        // blocked, so lets unblock it so that subsequent adds can be
        // entertained in the next cycle if triggered
        addQueueReset();

        let params = {
            csrf: addQueue.csrf
        };
        for (let i = 0; i < zids.length; ++i) {
            params["reqInfo[" + i + "][zid]"] = zids[i].zid;
            params["reqInfo[" + i + "][source]"] = "Friend Finder ZDC2.0"
        }
        let payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:neighbors_multiadd",
            payload: payload,
            token: "ZDC_GET_FRIEND_RECOMMENDED"
        })
            .then(res => { return res.json() })
            .then(parsed => { addRejectOnSuccess(parsed, requestingCsrf, zids) })
            .catch(err => { addQueueReset(); onFailureCallback(err); })
    }

    /**
     * Adds a list of zids to rejects if they were successfully added.
     * If Rate limit is reached then we simply flush the list.
     * @param {Object} resp The response code from the server
     * @param {Array} zids A list of zids that were attempted to add
     */
    async function addRejectOnSuccess(resp, csrf, zids) {
        if (resp !== undefined) {
            if (resp["e"] === 200) {
                zids.forEach(zidData => ServiceLocator.SocialNetworkManager.People.Reject({
                    csrf: csrf,
                    rejectZid: zidData.zid,
                    gameId: zidData.gameId
                }, () => { }))
            } else if (resp["e"] === 601) {
                // Rate limit reached, probably throw toast or something?
                // @TODO: check action item later, ignore for now
                // Do nothing
            } else {
                // Looks like an unknown
                ZLogger.fatal("_addRejectOnSuccess: Unknown response from server: ", resp);
            }
        }
    }

    function addPush(data) {
        addQueue.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        addQueue.zids.push({ zid: data.suggestedZid, gameId: data.gameId });
        if (addFlushActive === undefined) {
            addFlushActive = setTimeout(addFlush, 10000);
        }
    }

    /**
     * Accept a friend request by zid, provided a friend
     * request was made to this user. We donot check on the
     * client if a request was made from the zid and is subject
     * to server validation.
     * @param {Object} data Dict contains suggestedZid
     * @param {fn} onCompleteCallback Callback when success 2xx
     * @param {fn} onFailureCallback Callback when failure 4xx 5xx
     */
    function Accept(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (!data || !data.suggestedZid) {
            return onFailureCallback("Suggested.Accept: Malformed data payload", data);
        }
        var params = {
            zid: data.suggestedZid,
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        };
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:neighbors_confirm",
            payload: payload,
            token: "ZDC_GET_FRIEND_ACCEPT"
        })
            .then(res => { return res.json() })
            .then(parsed => {
                onCompleteCallback(parsed)
            })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Add a suggested friend by adding it to the queue. A flush
     * call will flush it to the server since rate limiting is
     * active.
     * @param {dict} data A payload containing the friend to be added zid
     * @param {fn} onFailureCallback Response function to respond to on error
     * data payload schema =>
     * {
     *      suggestedZid: => The zid to be added
     *      gameId: => The gameId from which this suggestion came
     * }
     */
    function Friend(data, onFailureCallback = ZLogger.error) {
        if (!data || !data.suggestedZid || !data.gameId) {
            return onFailureCallback("Suggested.Add: Malformed data payload", data);
        }
        addPush(data);
    }

    /**
     * Get a list of suggested friends from a gameId. Default gameId is 1031 and maxLimit is 8.
     * Sample call: ServiceLocator.SocialNetworkManager.People.GetSuggested()
     * @param {Object} data An object containing the gameId to be queried for | optional maxLimit = 8
     * @param {fn} onCompleteCallback Callback when this request is successfully completed
     * @param {fn} onFailureCallback Callback when this request fails from the server
     */
    function GetSuggested(data = { gameId: 1031 }, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (!data || !data.gameId) {
            return onFailureCallback("GetFriendSuggestions: Invalid/Missing data parameters")
        }
        if (data.maxLimit === undefined) data.maxLimit = 8; // put a max cap if not specified
        var params = {
            gameId: data.gameId,
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
            maxLimit: data.maxLimit
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zFriendRecommendations_get",
            payload: payload,
            token: "ZDC_GET_FRIEND_RECOMMENDED"
        })
            .then(res => { return res.json() })
            .then(parsed => {
                // Additionally Tell the directory service meanwhile to
                // Check them out and index these people
                if (parsed && parsed.recs && parsed.recs.length > 0) {
                    ServiceLocator.SocialNetworkManager.People.Directory.Identify(parsed.recs);
                }
                onCompleteCallback(parsed)
            })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Rejects a suggested neighbor zid so that they are never shown again in
     * recommended section.
     * data payload schema => 
     * {
     *      gameId: => Affected GameId
     *      rejectZid: => The zid to be rejected
     * }
     */
    function Reject(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (!data || !data.gameId || !data.rejectZid) {
            return onFailureCallback("GetFriendSuggestions: Invalid/Missing data parameters")
        }
        var params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
            rejectZid: data.rejectZid,
            gameId: data.gameId
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zFriendRecommendations_reject",
            payload: payload,
            token: "ZDC_REJECT_FRIEND_RECOMMENDED"
        })
            .then(data => { return data.json() })
            .then(data => { onCompleteCallback(data) })
            .catch(err => { onFailureCallback(err) })
    }

    function Block(zid) {
        // @TODO
    }

    /**
     * Checkouts 👓 a bunch of zids for profile information
     * @param {String|Array} zids A zid/list-of-zids to be checked out for profile info
     * @param {fn} onCompleteCallback Method to handover the data after 200
     * @param {fn} onFailureCallback Method to call when an error occurred
     */
    function Checkout(zids = [], onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        const zidsToCheck = Array.isArray(zids) ? zids : [String(zids)];
        const attributesToCheck = ["displayName", "firstName", "lastName", "profileName", "userName", "birthday", "email", "gender", "language", "zid", "pic_key", "profile_url", "profilePicNormal", "profilePicThumb", "online", "fbid", "not_activiated", "gid", "canReceiveGive", "isGiveCapped", "zavatarVer"];
        let urlsParams = "csrf=" + ServiceLocator.SocialNetworkManager.SessionData().csrf + "&";
        zidsToCheck.forEach(eachZid => {
            urlsParams += "data[" + eachZid + "][snid]=18&"
            attributesToCheck.forEach(attribute => {
                urlsParams += "data[" + eachZid + "][attr][]=" + attribute + "&";
            })
        })

        let payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: urlsParams
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_user_getUserAttributes",
            payload: payload,
            token: "ZDC_PEOPLE_CHECKOUT_" + (new Date()).getTime()
        })
            .then(res => { return res.json() })
            .then(parsed => { onCompleteCallback(parsed) })
            .catch(err => { onFailureCallback(err) })
    }

    return {
        Accept: Accept,
        Friend: Friend,
        GetSuggested: GetSuggested,
        Reject: Reject,

        Block: Block,
        Checkout: Checkout,
        Friends: Friends,
        Directory: Directory
    }

})();

export default People;