/**
 * PermanentBenefitController.js
 * Relays game load from farm2 iframe to enable reward grant if eligible.
 * @author: rthakkar
 * @copyright Zynga Inc 2019
 */
import ServiceLocator from "../../../../ServiceLocator";
import $ from "jquery";
import DataStore from '../../../../data/DataStore';

var PermanentBenefitController = {};

PermanentBenefitController.Constants = (function () {
    return {
        ZDC_TYPE_INGRESS_GAME_LOAD: "UWG_REWARD_CLAIM",
        UWG_TYPE_INGRESS_PONG: "UWG_PONG",
        UWG_SENDER_EGRESS_FARM2: "FARM2",

        UWG_GAME_IFRAME_IDS: {
            FARM2: "farm2-game-frame"
        },

        UWG_LISTENER_XFRAME_MSG: "message"
    };
})();

PermanentBenefitController.Instance = (function () {
    var _rules = [
        {
            activated: false,
            handler: (d) => {
                // Only if the relay data contains this signature
                // its considered to be complete
                if (d.sig !== undefined && d.sig === PermanentBenefitController.Constants.UWG_TYPE_INGRESS_PONG) {
                    // Ping pong compelte
                    console.log("PINGPONG recieved");
                }
            },
            passes: [
                ServiceLocator.Xing.XingChecker.Validate,
                ServiceLocator.Xing.XingChecker.IsPong
            ],
            name: "fv2pong"
        },
        {
            activated: false,
            handler: (d, original) => {
                var sender = original.sender;
                if (sender === PermanentBenefitController.Constants.UWG_SENDER_EGRESS_FARM2 && _that !== undefined) {
                    var currentState = _that.state;
                    currentState.didGameLoad = true;
                    _that.setState(currentState);

                    if (ServiceLocator.PB.EligibleForReward()) {
                        ServiceLocator.ZTrack.Cast({
                            counter: "zdc_perm_benefit",
                            kingdom: 'reward_ready',
                            phylum: ServiceLocator.PB.GetRewardNum()
                        });
                    }

                    if (ServiceLocator.PB.GetNumberOfDaysPassed() === 0 && ServiceLocator.PB.GetMOTDSeenCounter() <= 1) {
                        let ftueStep1 = DataStore.LocalStorage.Get("ftue1Step")
                        let ftueStep2 = DataStore.LocalStorage.Get("ftue2Step")
            
                        if (ftueStep1 === null || ftueStep1 === undefined || ftueStep1 !== "1"
                            || ftueStep2 === null || ftueStep2 === undefined || ftueStep2 !== "1") {
                                ServiceLocator.Xing.Send(
                                    "farm2-game-frame",
                                    "UWG_HIDE_GAME",
                                    {}
                                );
                                $('#permBenefitModal').modal('show');
                        }

                    }
                    
                }
            },
            passes: [
                ServiceLocator.Xing.XingChecker.Validate,
                (d) => { return d.type === PermanentBenefitController.Constants.ZDC_TYPE_INGRESS_GAME_LOAD }
            ],
            name: "UWG_GAME_LOAD"
        }

    ];

	/** 
	 * The reference of the component using this controller.
	 * Protocol actions can be taken on the component based on a condition.
	 */
    var _that = undefined;

    function _registerRules() {
        ServiceLocator.Xing.RegisterRules(_rules);
    }

    function _activateRules() {
        ServiceLocator.Xing.ActivateRules(_rules);
    }

    function Init({ that }) {
        if (that) {
            _that = that;
            _registerRules();
            _activateRules();
        }
    }

    return {
        Init: Init
    };
})();

export default PermanentBenefitController;
