/**
 * GameAuth.js
 * Contains a game level (not app/page level) auth 
 * methods (such as games exchanging their tokens) etc.
 */
import ServiceLocator from '../../ServiceLocator';

var Game = (function() {
    /**
     * Does a facebook token authorization with Zynga servers for Farm2 in
     * facebook login context.
     * Should get you the following on success:
     * --> access_token
     * --> signed_request
     */
    function FacebookGameExchange(accessToken, onCompleteCallback, onFailureCallback) {
        if(accessToken === undefined) {
            onFailureCallback("Invalid token passed");
            return;
        }
        var payload = {
            type        : 'GET',
            contentType : 'application/x-www-form-urlencoded',
            url         : 'https://fb1.farm2.zynga.com/extension/login.php?access_token='+ accessToken +'&action=exchange'
        };
        ServiceLocator.NetworkManager.Ajax.Call({
            payload: payload,
            onComplete: onCompleteCallback,
            onFailure: onFailureCallback,
            token: "FB_GAME_EXCHANGE"
        })
    }

    /**
     * Refreshes the game token when provided with signedRequest in facebook
     * login context.
     * Should get you the following on success:
     * --> zySnid
     * --> zyAuthHash
     * --> zySig
     */
    function FacebookGameRefreshSig(signedRequest, onCompleteCallback, onFailureCallback) {
        if(signedRequest === undefined) {
            onFailureCallback("Invalid signed request passed");
            return;
        }
        var payload = {
            type        : 'GET',
            contentType : 'application/json',
            url         : 'https://fb1.farm2.zynga.com/session.json?action=refreshSig',
            data        : {
                "signed_request" : signedRequest
            }
        }
        ServiceLocator.NetworkManager.Ajax.Call({
            payload: payload,
            onComplete: onCompleteCallback,
            onFailure: onFailureCallback,
            token: "FB_GAME_REF"
        })
    }

    /**
     * Does a token exchange with your csrf in ZDC/Zynga login context.
     * Should get you the following on success:
     * --> zySnid
     * --> zyAuthHash
     * --> zySig
     */
    function ZyngaGameExchangeFarm2(csrf, appId, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = onCompleteCallback?onCompleteCallback:(() => {})
        onFailureCallback = onFailureCallback?onFailureCallback:(() => {})
        var exchangeParams = {
            csrf: csrf,
            appId: appId
        };
        var exchangePayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(exchangeParams)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_authenticateGame",
            payload: exchangePayload,
            token: "ZDC_EXCHANGE_FARM2"
        })
        .then(resp => {return resp.json()})
        .then(data => {onCompleteCallback(data)})
        .catch(err => {onFailureCallback(err)});
    }

    return {
        FacebookGameExchange    : FacebookGameExchange,
        FacebookGameRefreshSig  : FacebookGameRefreshSig,
        ZyngaGameExchangeFarm2  : ZyngaGameExchangeFarm2
    }

})();

export default Game;