import React from 'react';
import BaseComponent from '../../BaseComponent';
import { Link } from "react-router-dom";
import './Buttons.css'

function getButtonClass(severity, extraClass, forceClass) {
    let options = {
        "default": " btn button-green button-green-text ",
        "info": " btn button-grey button-grey-text ",
        "transparent-white": " btn button-transparent button-white-text ",
        "transparent-black": "btn button-transparent-black button-black-text",
        "action": " btn button-blue button-blue-text ",
        "facebook": " btn button-grey button-facebook",
        "link": " link-button",
    }
    severity = !severity || !(severity in options)
        ? "default"
        : severity;
    let result = options[severity];
    if (extraClass) {
        result = result + " " + extraClass;
    }
    if (forceClass) {
        result = forceClass;
    }
    return result;
}

class GenericButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <button
                type="button"
                onClick={this.props.onClick}
                className={getButtonClass(
                    this.props.severity,
                    this.props.extraClass,
                    this.props.forceClass
                )}
                name={this.props.name ? this.props.name : ""}
                data-toggle={this.props.toggle ? this.props.toggle : ""}
                data-target={this.props.target ? this.props.target : ""}
            >
                {this.props.message}
            </button>
        )
    }
}

class LinkButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
        if (!this.props.to) {
            super.fatal("Unable to render Link without a 'to' property");
        }
        this.onClick = this.props.onClick ? this.props.onClick : () => { };
    }

    render() {
        return (
            <Link
                className={getButtonClass(
                    this.props.severity,
                    this.props.extraClass,
                    this.props.forceClass
                )}
                to={this.props.to}
                onClick={this.onClick}
            >
                {this.props.message}
            </Link>
        )
    }
}

/**
 * Generic button class that can behave as base <a> or
 * a react <Link> component based on passed props.
 * Input schema:
 * @param {String}  type        link|*  renders as a <Link> or <a>
 * @param {String}  severity    default|info|action renders the appropriate button style
 * @param {String} extraClass   *   Space separated classes to apply
 * @param {String} forceClass   *   Space separated classes to override all button.css classes
 * @returns {Button}
 */
class Button extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const buttonDetect = (bProps) => {
            if (bProps.type === "link") {
                return <LinkButton {...this.props} {...bProps} />
            }

            return <GenericButton {...this.props} {...bProps} />
        }
        return (
            buttonDetect(this.props)
        )
    }
}

export default Button;