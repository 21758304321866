/**
 * Fetch.js
 * Wrapper over javascript's fetch() method.
 * All website networking happens through these methods.
 * @author Jaiwardhan Swarnakar
 */
import ServiceLocator from '../../ServiceLocator'

var Fetch = (function() {
    function Call({url, payload, token}) {
        url = url?url:"";
        payload = payload?payload:{};

        // Tell the network queue that a new network request is being added
        var _networkRequestToken = ServiceLocator.NetworkManager.Queue.Add({
            requestType: token
        });
        return fetch(url, payload).then(data => {
            ServiceLocator.NetworkManager.Queue.Complete(_networkRequestToken);
            return data
        }).catch(err => {
            ServiceLocator.NetworkManager.Queue.Complete(_networkRequestToken);
            return err
        })
    }

    return {
        Call    : Call
    }
})();

export default Fetch;