/**
 * LocalStorage.js
 * Localstorage wrapper for window.localStorage, supporting
 * sync and async based storage,
 * @author Jaiwardhan Swarnakar
 */
var LocalStorage = (function () {

    async function AsyncGet(key) {
        return Promise.resolve().then(() => {
            return window.localStorage.getItem(key);
        });
    }

    async function AsyncStore(key, value) {
        return Promise.resolve().then(() => {
            window.localStorage.setItem(key, value);
        })
    }

    function Get(key) {
        return window.localStorage.getItem(key);
    }

    function Store(key, value) {
        window.localStorage.setItem(key, value);
    }

    function Remove(key) {
        window.localStorage.removeItem(key);
    }

    function Has(key) {
        var val = window.localStorage.getItem(key);
        return val !== undefined && val !== null;
    }

    return {
        AsyncGet: AsyncGet,
        AsyncStore: AsyncStore,
        Get: Get,
        Has: Has,
        Remove: Remove,
        Store: Store
    }
})();

export default LocalStorage;