/**
 * LoginComponent.js
 * BS4 modal which houses login.js component and
 * signup.js component as tabs.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react';
import BaseComponent from '../../BaseComponent';
import $ from 'jquery';
import ServiceLocator from '../../../ServiceLocator';
import Login from './login'
import Signup from './signup'

import './LoginComponent.css'
import '../dialogCommons.css'
import LocUtils from "../../../util/localization/LocUtils";


class LoginComponent extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            modal_id: "loginModalCenter",
            tabPreloadedData: {     // In case we want to switch tabs between SU and Login
                login: {            // some data can be passed between tabs with this state
                    email: "",      // information, and child can leverage that
                    fbId: ""
                },
                signup: {

                }
            },
            currentTab: "login" // either login or signup
        }
        this.loginFBHandler = props.loginFBHandler;
        this.loginAuthHandler = props.loginAuthHandler;
        this.signupAuthHandler = props.signupAuthHandler;
        this.forgotPasswordHandler = props.forgotPasswordHandler;
        this.fbConnectHandler = props.fbConnectHandler;
        this.resetFbLoginClick = props.resetFbLoginClick;

        this.switchTabs = this.switchTabs.bind(this);
    }

    switchTabs(to, data = { email: "", fbId: "" }) {
        if (to === "login") {
            this.setState({
                tabPreloadedData: {
                    login: data,
                    signup: data
                },
                currentTab: to
            }, () => {
                // Switch to login tab
                // $("#login-tab").tab('show');
                // $("#signup-tab").tab('hide');
            })
            return;
        }
        if (to === "signup") {
            this.setState({
                tabPreloadedData: {
                    login: data,
                    signup: data
                },
                currentTab: to
            }, () => {
                // switch to signup tab
                // $("#signup-tab").tab('show');
                // $("#login-tab").tab('hide');
            })
            return;
        }
    }

    render() {
        if (this.props.loggedin === true) {
            $("#" + this.state.modal_id).modal('hide');
        }
        return (
            <div>

                {/* Login/Signup pane BS4 Modal */}
                <div className="modal fade" id="loginModalCenter" data-backdrop="static" tabIndex="1" role="dialog" aria-labelledby="loginModalCenter" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-common-props" role="document">
                        <div className="modal-content" style={{ border: "none" }}>
                            <div className="modal-body login-modal-body">

                                {/* The (X) close button */}
                                <button type="button" className="close login-modal-close-btn grey-btn" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        if (this.state.currentTab === "login" && this.props.loggedin !== true) {
                                            let dialogname = "defaultLogin";
                                            if (this.state.loginType === "1") {
                                                dialogname = "fbLogin";
                                            } else if (this.state.loginType === "2") {
                                                dialogname = "emailLogin";
                                            }
                                            ServiceLocator.ZTrack.Cast({
                                                counter: "dialog",
                                                kingdom: "close",
                                                phylum: dialogname,
                                                family: "zdc_register",
                                                value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                                            });
                                        }
                                        let currState = this.state;
                                        currState.currentTab = "login";
                                        this.setState(currState);
                                    }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                {this.state.currentTab === "login" &&
                                    <Login id="user-login"
                                        tabRef="login-tab"
                                        active="active"
                                        loggedin={this.props.loggedin}
                                        loginFBHandler={this.loginFBHandler}
                                        loginAuthHandler={this.loginAuthHandler}
                                        forgotPasswordHandler={this.forgotPasswordHandler}
                                        parentSwitchTabHandle={this.switchTabs}
                                        preloadedData={this.state.tabPreloadedData.login}
                                        fbLoginClicked={this.props.fbLoginClicked}
                                        resetFbLoginClick={this.props.resetFbLoginClick}
                                        cookieEmail={this.props.cookieEmail}
                                        isZDCMigrationModule2={this.props.isZDCMigrationModule2}
                                        loginErrMsg={this.props.loginErrMsg}
                                        invalidCredentials={this.props.invalidCredentials}
                                        source={this.props.source}
                                        resetInvalidCreds={this.props.resetInvalidCreds}
                                        loginType={this.props.loginType} />}
                                {!!this.props.isZDCExpCouponActive && (
                                    <div className="exp-side-card-modal">
                                        <div className="exp-side-card-modal-content">
                                            <img
                                                className={"exp-coupon-image-modal"}
                                                src={process.env.REACT_APP_ASSET_PATH + "/icon_zdc_exp_coupon_sidecard.png"}
                                                alt=""
                                            />
                                            <div className={"exp-coupon-text-modal"}>{LocUtils.T("feature.dialog.zdcExpCoupon.sideCardBody")}</div>
                                        </div>

                                    </div>
                                )}

                                {this.state.currentTab === "signup" &&
                                    <Signup id="user-signup"
                                        tabref="signup-tab"
                                        loginFBHandler={this.loginFBHandler}
                                        loginAuthHandler={this.loginAuthHandler}
                                        parentSwitchTabHandle={this.switchTabs}
                                        loginErrMsg={this.props.loginErrMsg}
                                        preloadedData={this.state.tabPreloadedData.signup} />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default LoginComponent;