

import React from 'react'
import BaseComponent from '../../BaseComponent'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery'
import './EmailVerification.css'
import LocUtils from '../../../util/localization/LocUtils';
import Button from '../buttons/Buttons';
import {Redirect} from "react-router-dom";


class VerifyEmail extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            signUpNew:"",
            redirect: false,
            loggedin: props.loggedin,
            alreadyLoggedin: props.alreadyLoggedin
        }
        this.onClickOkay = this.onClickOkay.bind(this);
        this.getButtonText = this.getButtonText.bind(this);
    }

    componentDidMount() {
        var currentState = this.state;
        this.setState(currentState);
    }

    onClickOkay(evt) {
        if (evt) evt.preventDefault();
        this.setState({redirect:true})
        $("#verifyEmailModal").modal('hide');
        // show login page after verification if they were not logged in else we're already redirecting to homepage
        if (!(this.state.loggedin === true) && !(this.state.alreadyLoggedin === true)) {
            $("#loginModalCenter").modal("show");
            $("#login-carousel").carousel(0);
        } else {
            this.props.reloadAttrHandler();
        }
        if (this.props.loggedin) {
            document.getElementById('zdcMigrationForceRedirect').style.display = 'block';
        }
    }

    getButtonText() {
        return ((this.state.loggedin === true) || (this.state.alreadyLoggedin === true)) ?
            LocUtils.T("login.non_email_verification_dialog_button_okay") :
            LocUtils.T("login.email_verification_success_dialog_button");
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to='/' />
        }
        const buttonText = this.getButtonText();
        var classProps = "tab-pane fade show " + this.props.active;
        return (
            <div className="EmailVerificationPage" style={{backgroundColor: "#222222"}}>
                <div className="modal fade" id="verifyEmailModal" data-keyboard="false" data-backdrop="static" tabIndex="1" role="dialog" aria-labelledby="verifyEmailModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header toc-header-bg">
                                <div className="row">
                                    <div className="col col-2 zynga-logo-col">
                                        <img alt="fp" className="forgot-password-lock-icon zynga-logo-icon" src="https://zlive.cdn.zynga.com/zdc_static/zdc-c436fe34_b1882547_1081b27d-static/js/compiled/zui/modules/widgets/zdc/common/header/zdc_logo_v2.png" />
                                    </div>
                                    <div className="col col-10 text-center text-white toc-header-text" style={{left: "20px"}}>
                                        <h3 className="modal-title" id="verifyEmailModalTitle"><b>{LocUtils.T("login.email_verification_success_dialog_header")}</b></h3>
                                        <br />
                                    </div>


                                </div>
                            </div>

                            <div className="modal-body">

                                <div className={classProps} role="tabpanel" aria-labelledby={this.props.tabRef} id={this.props.id}>
                                    <div id="verify-tos-carousel" className="carousel slide" data-keyboard="false" data-ride="carousel" data-interval="false">
                                        <div className="carousel-inner">

                                            {/* The accept button form */}
                                            <div className="carousel-item active">
                                                <div>
                                                    <ul>
                                                        <li>{LocUtils.T("login.email_verification_success_dialog_data")}</li>
                                                    </ul>
                                                </div>
                                                <br />
                                                <br />

                                                <div className="row">
                                                    <div className="col-2" ></div>
                                                    <div className="col-8" style={{ margin: "auto", textAlign: "center", bottom: "15px" }}>
                                                        <Button message={buttonText} onClick={this.onClickOkay} />
                                                    </div>
                                                    <div className="col-2" ></div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VerifyEmail;