/**
 * PaymentComponent.js
 * Keeps a Zbillr modal payment dialog ready. Works in conjunction
 * with PaymentController.js which helps do the talking with
 * Xing and performing/conveying required actions.
 * @author: jswarnakar
 * @copyright Zynga Inc 2019
 */

import React from 'react';
import $ from 'jquery';
import BaseComponent from '../../BaseComponent';
import PaymentController from './helpers/PaymentController';
import './PaymentComponent.css';
import '../dialogCommons.css'
import '../../../../styles/generic.css';
import ServiceLocator from '../../../ServiceLocator';

/**
 * Zbillr payment component modal
 */
class ZbillrComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            modals: {
                pay: {
                    frameData: {
                        frameURL: "",
                        sender: "",
                        referrerId: ""
                    }
                },
                paySettings: {
                    frameData: {
                        frameURL: "",
                        sessionId: ""
                    }
                }
            }
        };
        this.modals = {
            pay: {
                modalId: "payFrameModal",
                contentId: "payFrameModalContent",
                iframeId: "paymentFrame",
                modalBodyId: "payFrameModalBody",
                widthFactor: 1.0,
                heightFactor: 1.0,
            },
            paySettings: {
                modalId: "settingsPayFrameModal",
                contentId: "settingsPayFrameModalContent",
                iframeId: "settingsPaymentFrame",
                modalBodyId: "settingsPayFrameModalBody",
                widthFactor: 1.1,
                heightFactor: 1.5,
            }
        }
        this.resizeModal = this.resizeModal.bind(this);
        this.openSelf = this.openSelf.bind(this);
        this.closeSelf = this.closeSelf.bind(this);
        this.resetSelf = this.resetSelf.bind(this);
        this.onButtonClose = this.onButtonClose.bind(this);
        this.openPaymentSettings = this.openPaymentSettings.bind(this);
        this.closePaymentSettings = this.closePaymentSettings.bind(this);

        this.boundaryheightExtras = 68;
        // Initialize Zblillr rules
        PaymentController.Zbillr.Instance.Init({
            that: this
        })
    }
    /* Internal constants */
    _constants = {
        fv2IframeID: "farm2-game-frame",
        UWG_TYPE_INGRESS_HIDE_GAME: "UWG_HIDDEN_GAME",
        UWG_TYPE_INGRESS_SHOW_GAME: "UWG_SHOWN_GAME",
        UWG_TYPE_EGRESS_HIDE_GAME: "UWG_HIDE_GAME",
        UWG_TYPE_EGRESS_SHOW_GAME: "UWG_SHOW_GAME",
    }

    openSelf() {
        $("#" + this.modals.pay.modalId).modal('show');
    }

    closeSelf() {
        $("#" + this.modals.pay.modalId).modal('hide');
    }

    openPaymentSettings() {
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_HIDE_GAME,
            {}
        );
        $("#" + this.modals.paySettings.modalId).modal('show');
    }

    closePaymentSettings() {
        $("#" + this.modals.paySettings.modalId).modal('hide');
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_SHOW_GAME,
            {}
        );
    }

    onButtonClose() {
        PaymentController.Zbillr.Instance.ExternalClose();
    }

    resetSelf() {
        this.setState({
            modals: {
                pay: {
                    frameData: {
                        frameURL: "",
                        sender: "",
                        referrerId: ""
                    }
                },
                paySettings: {
                    frameData: {
                        frameURL: "",
                        sessionId: ""
                    }
                }
            }
        });
        ServiceLocator.Xing.Send(
            this._constants.fv2IframeID,
            this._constants.UWG_TYPE_EGRESS_SHOW_GAME,
            {}
        );
    }

    resizeModal(data) {
        var h = data.h;
        var w = data.w;
        var eachModal, modalData, iframeEl, contentEl, modalBodyEl, wFactor, hFactor;
        super.log("RESIZING PAYMENT CONTAINER: ", h, w);
        for (eachModal in this.modals) {
            modalData = this.modals[eachModal];
            iframeEl = document.getElementById(modalData.iframeId);
            contentEl = document.getElementById(modalData.contentId);
            modalBodyEl = document.getElementById(modalData.modalBodyId);
            wFactor = modalData.widthFactor;
            hFactor = modalData.heightFactor;
            iframeEl.style.width = '' + w + 'px';
            iframeEl.style.height = '' + h + 'px';
            contentEl.style.width = '' + w * wFactor + 'px';
            modalBodyEl.style.width = '' + w * wFactor + 'px';
            modalBodyEl.style.height = '' + h * hFactor + 'px';
        }
    }

    render() {
        return (
            <div>
                {/* Zbillr component modal */}
                <div className="modal fade" id={this.modals.pay.modalId} tabIndex="1"
                    data-keyboard="false" data-backdrop="static"
                    role="dialog" aria-labelledby={this.modals.pay.modalId} aria-hidden="true">
                    <div className="modal-dialog payment-modal-props" role="document">
                        {/* Modal Content */}
                        <div id={this.modals.pay.contentId} className="modal-content payment-modal-content">

                            {/* Modal Header (X)*/}
                            <div className="modal-header modal-head payment-modal-header">
                                <h5 className="modal-title" id="payFrameModalTitle">Zynga Payments</h5>
                                <button type="button" onClick={this.onButtonClose} className="close modal-close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="">×</span></button>
                            </div>

                            {/* Modal Body */}
                            <div id={this.modals.pay.modalBodyId} className="modal-body payment-modal-body">

                                <div className="payment-frame-filler-footer"></div>
                                {/* Iframe */}
                                <iframe id={this.modals.pay.iframeId} className="payment-iframe"
                                    src={this.state.modals.pay.frameData.frameURL}
                                    name={this.modals.pay.contentId} title={this.modals.pay.contentId} />

                            </div>
                        </div>
                    </div>
                </div>

                {/* Zbillr card settings modal */}
                <div className="modal fade" id={this.modals.paySettings.modalId}
                    data-keyboard="false" data-backdrop="static"
                    tabIndex="1" role="dialog" aria-labelledby={this.modals.paySettings.modalId} aria-hidden="true">
                    <div className="modal-dialog payment-modal-props" role="document">
                        {/* Modal Content */}
                        <div id={this.modals.paySettings.contentId} className="modal-content payment-modal-content">

                            {/* Modal Header (X)*/}
                            <div className="modal-header modal-head payment-modal-header">
                                <h5 className="modal-title" id="payFrameModalTitle">Zynga Payments</h5>
                                <button type="button" onClick={this.resetSelf} className="close modal-close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="">×</span></button>
                            </div>

                            {/* Modal Body */}
                            <div id={this.modals.paySettings.modalBodyId} className="modal-body payment-modal-body">

                                <div className="payment-frame-filler-footer"></div>
                                {/* Iframe */}
                                <iframe id={this.modals.paySettings.iframeId} className="payment-iframe payment-settings-iframe"
                                    src={this.state.modals.paySettings.frameData.frameURL}
                                    name={this.modals.paySettings.contentId} title={this.modals.paySettings.contentId} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Consists a collection of all Payment UI 
 * modal components.
 */
class PaymentComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <ZbillrComponent />

            </div>
        )
    }
}

export default PaymentComponent;