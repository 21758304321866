/**
 * FacebookAuth.js
 * Contains a collection of facebook core related auth
 * methods.
 * @author Jaiwardhan Swarnakar
 */
import ServiceLocator from '../../ServiceLocator'
import DataStore from '../../data/DataStore'
import ZLogger from  '../../util/zlogger/zlogger'

var Fb = (function() {
    var Context = "FACEBOOK";

    /**
     * Compares the passed context and returns true if
     * it maches with Fb.Context
     */
    function Is(compareContext) {
        return compareContext && compareContext === Context;
    }

    /**
     * Gets user daya like name, mail, friends etc.
     * Use this if the user had logged in previously.
     * returns an authobject with the following:
     * --> picture
     * --> first_name
     * --> last_name
     * --> email
     * --> friends
     */
    function Get(userID, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback === undefined)? (() => {}): onCompleteCallback;
        onFailureCallback = (onFailureCallback === undefined)? (() => {}): onFailureCallback;
        if(userID === undefined || userID.length === 0) {
            onFailureCallback("FacebookGet: Unknown userId passed");
            return;
        }
        if(!window.FB) {
            onFailureCallback("FacebookGet: Window.FB unavailable. Has the class loaded already?");
            return;
        } 
        // Notify network queue that FB api is going to make a network request
        var _networkQueueToken = ServiceLocator.NetworkManager.Queue.Add({
            requestType: "FACEBOOK_GET"
        })
        var authData = {
            userID: userID
		};
        window.FB.api('/me', {fields: 'last_name,first_name,email,picture'}, function(response) {
            authData.picture        = response.picture.data;
            authData.first_name     = response.first_name;
            authData.last_name      = response.last_name;
            authData.email          = response.email;
            // Also attempt to get his friend list
            window.FB.api('/'+authData.userID+'/friends', function(response) {
				authData.friends = response;
				ZLogger.log("FacebookAuth:Get:: fetch complete: ", authData);
				onCompleteCallback(authData);
                ServiceLocator.NetworkManager.Queue.Complete(_networkQueueToken);
            });
        });
    }

    /**
     * Does facebook login.
     * Returns an Auth object with the following properties:
     * --> accessToken
     * --> signedRequest
     * --> userID
     * --> grantedScopes
     * --> picture
     * --> first_name
     * --> last_name
     * --> email
     * --> friends
     * Uses FacebookGet to fetch user personal facebook data
     */
    function Login(onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback === undefined)? (() => {}): onCompleteCallback;
        onFailureCallback = (onFailureCallback === undefined)? (() => {}): onFailureCallback;
        if(!window.FB) {
            onFailureCallback("FacebookLogin: Window.FB unavailable. Has the class loaded already?");
            return;
		}
        // Notify network queue that FB api is going to make a network request
        var _networkQueueToken = ServiceLocator.NetworkManager.Queue.Add({
            requestType: "FACEBOOK_LOGIN"
        })
        var authData = {

        }
        window.FB.login(function(response) {
            if (response.authResponse) {
                var authResponse = response.authResponse;
                if(authResponse === undefined) { authResponse = {}; } //safe
                authData.accessToken        = authResponse.accessToken;
                authData.signedRequest      = authResponse.signedRequest;
                authData.userID             = authResponse.userID;
                authData.grantedScopes      = authResponse.grantedScopes;

                ZLogger.log('FacebookAuth:Login:: Fetched basic Login information: ', authData);

                ServiceLocator.Auth.Fb.Get(
                    authData.userID,
                    (response) => {
                        authData.picture        = response.picture;
                        authData.first_name     = response.first_name;
                        authData.last_name      = response.last_name;
                        authData.email          = response.email;
                        authData.friends 		= response.friends;
                        DataStore.AuthData.Store(authData);
						onCompleteCallback(authData);
						ServiceLocator.NetworkManager.Queue.Complete(_networkQueueToken);
                    },
                    (err) => {
						onFailureCallback(err); //traceback
						ServiceLocator.NetworkManager.Queue.Complete(_networkQueueToken);
                    }
                )
            } else {
                ZLogger.fatal('FacebookAuth:Login:: User cancelled login or did not fully authorize.');
                authData = response;
				onCompleteCallback(authData);
				ServiceLocator.NetworkManager.Queue.Complete(_networkQueueToken);
            }
        }, {
            scope: ['email','user_friends'],
            return_scopes: true
        });
    }

    /**
	 * Does a Logout from a facebook state
	 * Caution: This might not work on localhost
	 * possibly due to a facebook bug.
	 */
	function Logout(onCompleteCallback, onFailureCallback) {
		onCompleteCallback = (onCompleteCallback === undefined)? (() => {}): onCompleteCallback;
        onFailureCallback = (onFailureCallback === undefined)? (() => {}): onFailureCallback;

        // Notify network queue that FB api is going to make a network request
        var _networkQueueToken = ServiceLocator.NetworkManager.Queue.Add({
            requestType: "FACEBOOK_LOGIN"
        })
        if(window.FB === undefined) {
            onFailureCallback("Logout: Window.FB unavailable. Has the class loaded already?");
            return;
        }
        window.FB.logout(function(resp) {
            onCompleteCallback(resp);
            ServiceLocator.NetworkManager.Queue.Complete(_networkQueueToken);
            return;
        });
	}
    
    return {
        Context : Context,
        Get     : Get,
        Is      : Is,
        Login   : Login,
        Logout  : Logout
    }
})();

export default Fb;