/**
 * ZyngaAuth.js
 * Collection of ZDC side auth methods such as login, verify
 * etc.
 * @author Jaiwardhan Swarnakar
 */
import ServiceLocator from '../../ServiceLocator'
import ZLogger from '../../util/zlogger/zlogger'
import ZyngaAuthSession from './ZyngaAuthSession';
import DataStore from "../../data/DataStore";

var Zynga = (function() {
    var Context = "ZYNGA";

    /**
     * zauth_register API
     * Intakes a payload that would be send to common_web:zauth_register API.
     */
    function _registerAPI(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error, requestToken) {
        requestToken = (!requestToken || requestToken.length === 0)
                        ?("--"+(new Date()).getTime()+"--")
                        : requestToken;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(data)
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_register",
            payload: payload,
            token: requestToken
        })
        .then(resp => { ZLogger.log("exists raw resp: ", resp); return resp.json() })
        .then(resp => { onCompleteCallback(resp) })
        .catch(err => { onFailureCallback(err) });
    }

    /**
     * Checks if an email id and a valid fbid are associated with each other.
     * Payload schema:
     * {
     *      email   => an email address
     *      fbId    => an fb id
     *      fbToken => a fb session token
     * }
     */
    function ConfirmAssociation(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if( !(data && data.email && data.fbId && data.fbToken) ) {
            // Looks like the form is incomplete
            onFailureCallback("Incomplete data passed to check association: ", data);
            return;
        }

        var associationParams = {
            method: "checkAssociation",
            email: data.email,
            fbid: data.fbId,
            fbsession: data.fbToken,

        }

        _registerAPI(associationParams, onCompleteCallback, onFailureCallback, "ZDC_CHECK_ASSOCIATION");
    }

    /**
     * Tells the server that a user with token and the new password wants
     * to confirm this password.
     */
    function ConfirmNewPassword(data, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback)?onCompleteCallback: (() => {});
        onFailureCallback = (onFailureCallback)?onFailureCallback: (() => {});

        var confirmPasswordParams = {
            token: data.token,
            email: data.email,
            snid : data.snid,
            newPassword: data.newPassword
        };
        var confirmPasswordPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(confirmPasswordParams)
        };
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_changePassword",
            payload: confirmPasswordPayload,
            token: "ZDC_NEW_PASSWORD"
        })
        .then(resp => {return resp.json()})
        .then(resp => { onCompleteCallback(resp) })
        .catch(err => { onFailureCallback(err) })
    }

    /**
     * Expects the data to have an email, checks the servers for an
     * existing account with Zynga <3 with this mail id.
     * Payload schema:
     * {
     *      email   => an email address
     * }
     */
    function Exists(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if(!data || !data.username) {
            onFailureCallback("Empty data sent or email feild not present");
            return;
        }

        var existParams = {
            email: data.username, 
            method: "exists"
        }

        _registerAPI(existParams, onCompleteCallback, onFailureCallback, "ZDC_ACCOUNT_EXISTS");
    }

    /**
     * Expects the data to have an fbId, checks the servers for an existing linked
     * zdc account with this fbId.
     * payload schema:
     * {
     *      fbid    => a valid fb id
     * }
     */
    function ExistsFB(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if(!data || !data.fbId) {
            onFailureCallback("Empty data sent or fbId feild not present");
            return;
        }

        var existParams = {
            fbid: data.fbId, 
            method: "existsFb"
        }

        _registerAPI(existParams, onCompleteCallback, onFailureCallback, "ZDC_FB_ACCOUNT_EXISTS");
    }
    
    /**
     * Sends a signal to the server that a plauer with an emailid
     * seems to have forgotten his password. If valid, the server will
     * send an email to this emailid.
     */
    function ForgotPassword(email, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback)?onCompleteCallback: (() => {});
        onFailureCallback = (onFailureCallback)?onFailureCallback: (() => {});
        var result = {};
        var forgotPasswordParams = {
            email: email
        }
        var forgotPasswordPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(forgotPasswordParams)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_forgotPassword",
            payload: forgotPasswordPayload,
            token: "ZDC_FORGET_PASSWORD"
        }).then(resp => {
            return resp.json()
        }).then(parsed => {
            result["e"]         = parsed.e;
            result["errors"]    = parsed.errors
            onCompleteCallback(result);
        }).catch(err => {onFailureCallback(err)})
    }

    /**
     * Sends a signal to the server that a plauer with an emailid
     * needs to generate his password. If valid, the server will
     * send an email to this emailid.
     */
    function CreatePassword(email, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback)?onCompleteCallback: (() => {});
        onFailureCallback = (onFailureCallback)?onFailureCallback: (() => {});
        var result = {};
        var createPasswordParams = {
            email: email
        }
        var createPasswordPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(createPasswordParams)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_createPassword",
            payload: createPasswordPayload,
            token: "ZDC_CREATE_PASSWORD"
        }).then(resp => {
            return resp.json()
        }).then(parsed => {
            result["e"]         = parsed.e;
            result["errors"]    = parsed.errors
            onCompleteCallback(result);
        }).catch(err => {onFailureCallback(err)})
    }

    /**
     * Compares the passed context and returns true if
     * it maches with Zynga.Context
     */
    function Is(compareContext) {
        return compareContext && compareContext === Context;
    }

    /**
     * Does a log in to ZDC env. Fetches all user information like csrf, game token,
     * zdc profile user data.
     * Should get you the following on success:
     * --> csrf token
     * --> zid
     * --> userData     (Complete User profile)
     * --> jwt          (jwt is your token for continuing a session on reload)
     */
	function Login({username, password, fbtoken, keepSignedIn, source, onCompleteCallback, onFailureCallback}) {
		if((username === undefined || password === undefined) && fbtoken === undefined ){
            ZLogger.log(username, password);
			onFailureCallback("Malformed credentials passed: ");
			return;
        }

        var result = {};
        var loginParams = {};

        if(username !== undefined && password !== undefined){
            loginParams = {
                "email": username,
                "password": password,
                "redirectUri": "https://zynga.com",
                "keepSignedIn": keepSignedIn,
                "uwgLogin": false
            }
        }else if(fbtoken !== undefined){
            loginParams = {
                "fbtoken": fbtoken,
                "redirectUri": "https://zynga.com",
                "keepSignedIn": keepSignedIn,
                "uwgLogin": false
            }
        }

        var loginPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(loginParams)
        }

        let loginToken = source === 1 ? "ZDC_LOGIN" : source === 3 ? "ZDC_RESTORE_DIALOG" : "ZDC_LOGIN_DIALOG";

        // Send a req over to the server to get login data
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_issueToken",
            payload: loginPayload,
            token: loginToken
        }).then(data => {
            return data.json()
        }).then(parsed => {
            result["e"]         = parsed.e;
            result["csrf"]      = parsed.csrf;
            result["zid"]       = parsed.zid;
            result["userData"]  = parsed.userData;
            result["jwt"]       = parsed.jwt; //!Important for sessions
            result["email"]     = parsed.email;
            result["source"]    = source;
            onCompleteCallback(result);
        }).catch(err => {
            onFailureCallback(err);
        })
    }

    function SendVerificationEmail({email, onCompleteCallback, onFailureCallback}) {

        if(email === undefined){
            ZLogger.log(email);
            return;
        }

        var result = {};
        var emailVerificationParams = {
            email: email
        }
        var emailVerificationPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(emailVerificationParams)
        }

        DataStore.LocalStorage.Remove("emailIdToSendVerification");


        // Send a req over to the server to get login data
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_sendVerificationEmailFromLogin",
            payload: emailVerificationPayload,
            token: "ZDC_VERIFY_EMAIL"
        }).then(resp => {
            return resp.json()
        }).then(parsed => {
            result["e"]         = parsed.e;
            onCompleteCallback(result);
        }).catch(err => {
            onFailureCallback(err);
        })

    }
    
    /**
     * Does a sign up on the zynga server
     */
    function Signup(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if( !(data && data.fname && data.fname && data.gender && data.username && data.password) ) {
            // Looks like the form is incomplete
            onFailureCallback("Form data is incomplete: ", data);
            return;
        }

        var registerParams = {
            method: "registerNew",
            givenName: data.fname,
            familyName: data.lname,
            birthdayDay: "Day",
            birthdayMonth: "Month",
            birthdayYear: "Year",
            gender: data.gender,
            birthday: "00/Day/Year",
            email: data.username,
            password: data.password,

        }

        _registerAPI(registerParams, onCompleteCallback, onFailureCallback, "ZDC_ACCOUNT_REGISTER");
    }

    /**
     * Does a facebook connect signup
     */
    function SignupFB(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if( !(data && data.first_name && data.last_name && data.userID && data.accessToken) ) {
            // Looks like the data is incomplete
            onFailureCallback("Form data is incomplete: ", data);
            return;
        }

        var registerParams = {
            method: "registerFBAccount",

            fbid: data.userID,
            fbsession: data.accessToken,

            givenName: data.first_name,
            familyName: data.last_name,
            birthdayDay: "Day",
            birthdayMonth: "Month",
            birthdayYear: "Year",
            birthday: "00/Day/Year"
        }
        if(data.email !== undefined) {
            registerParams.email = data.email;
        }

        _registerAPI(registerParams, onCompleteCallback, onFailureCallback, "ZDC_FB_ACCOUNT_REGISTER");
    }

    return {
        ConfirmAssociation  : ConfirmAssociation,
        ConfirmNewPassword  : ConfirmNewPassword,
        Context             : Context,
        Exists              : Exists,
        ExistsFB            : ExistsFB,
        ForgotPassword      : ForgotPassword,
        CreatePassword      : CreatePassword,
        Is                  : Is,
        Login               : Login,
        Signup              : Signup,
        SignupFB            : SignupFB,
        SendVerificationEmail : SendVerificationEmail,

        Session             : ZyngaAuthSession,
    }
})();


export default Zynga;