// ZSD error codes
// Refer: ZSD/ninja/externals/Inbox1P/submodules/shared/lib/class.ZErrorCodes.php
var ZSDErrorCodes = {
  // Unknown Error Type
  DEFAULT_ERROR: "defaultError",
  // Email
  EMAIL_INVALID: "email.invalid",
  EMAIL_EMPTY: "email.empty",
  EMAIL_INVALID_FORMAT: "email.invalidFormat",
  EMAIL_ALREADY_REGISTERED: "email.alreadyRegistered",
  EMAIL_UNAVAILABLE: "email.unavailable",
  EMAIL_START_ZYNGAWF: "email.startZyngaWF",
  EMAIL_START_END_SPACES: "email.cannotStartEndSpaces",
  EMAIL_INVALID_CHARACTERS: "email.invalidCharacters",
  EMAIL_MAX_ADDRESS_COUNT: "email.addressCountLimit",
  EMAIL_SAVE_FAILED: "email.saveFailed",
  EMAIL_ACCOUNT_NOT_ASSOCIATED: "email.notAssociatedWithAccount",
  EMAIL_CANNOT_REMOVE_LAST: "email.cannotRemoveLast",
  EMAIL_CANNOT_REMOVE_LAST_VERIFIED: "email.cannotRemoveLastVerified",
  EMAIL_GWF_REMOVE_FAILED: "email.gwfRemoveFailed",
  EMAIL_DELETE_FAILED: "email.deleteFailed",
  EMAIL_ADD_FAILED: "email.addFailed",
  EMAIL_GWF_SET_PRIMARY_FAILED: "email.gwfSetPrimaryFailed",
  EMAIL_SET_PRIMARY_FAILED: "email.setPrimaryFailed",
  EMAIL_CANNOT_DISPUTE_VERIFIED: "email.cannotDisputeVerified",
  EMAIL_SET_VERIFIED_FAILED: "email.setVerifiedFailed",
  // Username
  USERNAME_INVALID: "username.invalid",
  USERNAME_ALREADY_REGISTERED: "username.AlreadyRegistered",
  USERNAME_UNAVAILABLE: "username.unavailable",
  USERNAME_GWF_LINK_FAILED: "username.gwfLinkFailed",
  USERNAME_NOT_STRING: "username.notString",
  USERNAME_START_END_SPACES: "username.cannotStartEndSpaces",
  USERNAME_LENGTH: "username.length",
  USERNAME_NOT_PRINTABLE: "username.notPrintable",
  USERNAME_START_ZYNGAWF: "username.startZyngaWF",
  // Password
  PASSWORD_INVALID: "password.invalid",
  PASSWORD_EMPTY: "password.empty",
  PASSWORD_LENGTH: "password.length",
  PASSWORD_INCORRECT: "password.incorrect",
  PASSWORD_TOO_SHORT: "password.tooShort",
  PASSWORD_FORMAT: "password.format",
  PASSWORD_CONFIRM_EMPTY: "password.confirmEmpty",
  PASSWORD_CONFIRM_MISMATCH: "password.confirmMismatch",
  PASSWORD_INVALID_EMAIL_USERNAME_PASSWORD:
    "password.invalidEmailUsernamePassword",
  PASSWORD_RESET_EMAIL_SEND_FAILED: "password.resetEmailSendFailed",
  PASSWORD_GWF_INCORRECT: "password.gwfIncorrect",
  PASSWORD_INVALID_EMAIL: "password.invalidEmail",
  PASSWORD_NOT_STRING: "password.notString",
  PASSWORD_GWF_UPDATE_FAILED: "password.gwfUpdateFailed",
  PASSWORD_UPDATE_FAILED: "password.updateFailed",
  // Birthday
  BIRTHDAY_INVALID: "birthday.invalid",
  BIRTHDAY_MONTH_EMPTY: "birthday.monthEmpty",
  BIRTHDAY_DAY_EMPTY: "birthday.dayEmpty",
  BIRTHDAY_YEAR_EMPTY: "birthday.yearEmpty",
  // Name
  NAME_INVALID: "name.invalid",
  NAME_BAD_WORD: "name.badWord",
  NAME_LENGTH: "name.length",
  NAME_INVALID_ENCODING: "name.encoding",
  FIRST_NAME_INVALID: "firstName.invalid",
  FIRST_NAME_EMPTY: "firstName.empty",
  LAST_NAME_INVALID: "lastName.invalid",
  LAST_NAME_EMPTY: "lastName.empty",
  // Gender
  GENDER_INVALID: "gender.invalid",
  // Token
  TOKEN_INVALID: "token.invalid",
  TOKEN_APP_ID_NOT_FOUND: "token.appIdNotFound",
  // ZAuth
  ZAUTH_CONTEXT_INVALID: "zauth.context",
  ZAUTH_TOKEN_INVALID: "zauthToken.invalid",
  // General account errors
  ACCOUNT_CREATION_ERROR: "account.creationError",
  ACCOUNT_DNE: "account.DNE",
  ACCOUNT_NOT_FOUND: "account.notFound",
  ACCOUNT_CREATE_FAILURE: "account.createFailed",
  ACCOUNT_UPDATE_FAILURE: "account.updateFailed",
  ACCOUNT_DELETE_FAILURE: "account.deleteFailed",
  ACCOUNT_LOCKED: "account.locked",
  ACCOUNT_INSUFFICIENT_PERMISSION: "account.insufficientPermission",
  ACCOUNT_CANNOT_DELETE_OWN: "account.cannotDeleteOwn",
  ACCOUNT_GWF_NOT_FOUND: "account.gwfNotFound",
  ACCOUNT_GFW_DATA_MISMATCH: "account.gwfMismatch",
  ACCOUNT_GWF_LOCK_FAILED: "account.gwfLockFailed",
  ACCOUNT_GWF_FB_TOKEN_FAILED: "account.gwfFBTokenFailed",
  ACCOUNT_GWF_UPDATE_FAILED: "account.gwfUpdateFailed",
  ACCOUNT_GWF_PASSWORD_UPDATE_FAILED: "account.gwfPasswordUpdateFailed",
  ACCOUNT_GWF_CREATE_FAILED: "account.gwfCreateFailed",
  // ID Mapping
  ID_MAPPING_INVALID: "IDMapping.invalid",
  ID_MAPPING_ALREADY_ASSOCIATED_ZDC: "IDMapping.alreadyAssociated",
  ID_MAPPING_INVALID_SNID: "IDMapping.invalidSNID",
  // Form Fields
  FIELD_EMPTY: "field.empty",
  // Phone
  PHONE_INVALID: "phone.invalid",
  // Migrate Accounts
  MIGRATE_REQUIRES_FB_CRED: "migrate.requiresFBCredentials",
  MIGRATE_ALREADY_PROCESSED: "migrate.alreadyProcessed",
  MIGRATE_MISSING_INFO: "migrate.missingInfo",
  // Identitites
  IDENTITIES_UPDATE_FAILED: "identities.updateFailed",
  // Email Verify
  EMAIL_VERIFY_NONE_FOUND: "email.noneFound",
  EMAIL_VERIFY_NOT_ADDED_ACCOUNT: "email.notAddedAccount",
  EMAIL_VERIFY_MULTIPLE_FOUND: "email.multipleFound",
  EMAIL_ALREADY_VERIFIED: "email.alreadyVerified",
  // WFN ID
  WFN_ACCOUNT_ID_FAILED_ALLOCATE: "wfn.accountIdFailedAllocate",
  // General profile errors
  PROFILE_NOT_FOUND: "profile.notFound",
  PROFILE_SET_FAILED: "profile.setFailed",
  // Flagging
  FLAG_FAILED: "flag.failed",
  CANNOT_FLAG_SELF: "flag.cannotFlagSelf",
  FLAG_LIMIT_REACHED: "flag.limitReached",
  // Profile Media
  PROFILE_MEDIA_FAILED: "media.failed",
  PROFILE_MEDIA_UPLOAD_FAILED: "media.uploadFailed",
  PROFILE_MEDIA_URL_INVALID: "media.urlInvalid",
  // Generic access errors
  CALL_NOT_ALLOWED: "call.notAllowed",
  // Privacy
  PRIVACY_INVALID: "privacy.invalid",
};

var UrlCodes = {
  Games: {
    Farm2: {
      GAME_URL: "/play/farmville-two",
      VIRAL_URL_PATHNAME: "/play/farmville-two/viral.php",
      VIRAL_URL_QUERY_PARAM: "viralId",
      NOTIF_URL_QUERY_PARAM: 'aff',
      FORUM_URL: "https://zyngaplayerforums.com/farmville2"
    },
  },
};

var LoginStateType = {
  LOGGED_IN: "logged_in",
  DEFAULT_LOGIN: "defaultLogin",
  EMAIL_LOGIN: "emailLogin",
  FB_LOGIN: "fbLogin"
};

var PermBenefitsExperiments = {
  MASTER_EXPERIMENT: "zdc_permanent_benefits_master",
  HUD_UI_VARIANT: "zdc_permanent_benefits_hud_ui"
}

var ZdcExpCouponGrantExperiments = {
  TEASER_EXPERIMENT: "fv2_exp_coupon_zdc_grant_v1_teaser",
  MASTER_EXPERIMENT: "fv2_exp_coupon_zdc_grant_v1_master"
}

var RedirectionExperiments = {
  MASTER_EXPERIMENT: "fv2_zdcredirection_v1_launcher_master",
  ZDC_COUNT_EXPERIMENT: "fv2_redirection_v1_zdc_login_count",
  FB_CHOOSE_COUNT_EXPERIMENT: "fv2_redirection_v1_deny_count",
  SELECT_RESURFACE_EXPERIMENT: "fv2_redirection_v1_repeat_login_count",
  FB_BUTTON_EXPERIMENT: "fv2_redirection_v1_button_align",
  FARM_BUCKS_EXPERIMENT:"fv2_zdcredirection_v1_reward_fb",
  ZDC_CHOICE_EXPERIMENT: "fv2_redirection_v1_zdc_choice"

}

var SiteConstants = (function () {
  var Time = {
    SECONDS_IN_HOUR: 3600,
  };

  return {
    Time: Time,
    ZSDErrorCodes: ZSDErrorCodes,
    UrlCodes: UrlCodes,
    LoginStateType: LoginStateType,
    PermBenefitsExperiments: PermBenefitsExperiments,
    RedirectionExperiments:RedirectionExperiments,
    ZdcExpCouponGrantExperiments:ZdcExpCouponGrantExperiments
  };
})();

export default SiteConstants;
