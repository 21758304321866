/**
 * NavComponent.js
 * Navigation UI component. Takes care of spawning the top
 * nav bar with related entities. Has available controls
 * for locale switch, Login/signup trigger and user actions.
 * @author Jaiwardhan Swarnakar
 */
import React from "react";
import BaseComponent from "../../BaseComponent";
import LoadingWidget from "../LoadingWidget";
import ServiceLocator from "../../../ServiceLocator";
import LocUtils from "../../../util/localization/LocUtils";
import Utils from "../../../managers/Network/Utils";
import NotificationBell from "../notification/NotificationBell";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Nav.css";
import "../../../../styles/generic.css";
import Button from "../buttons/Buttons";
import $ from "jquery";
import ContentLoading from "../contentLoading/contentLoading";
import SiteConstants from "../../../util/SiteConstants";
import FacebookButton from "../buttons/FacebookButton";
import HUD from "../permanentBenefit/HUD";

/**
 * Endpoint menu options that will lead to somewhere
 * or will do something.
 * Accepts props:
 * <== that             (optional - the referring context)
 * <== directPerform    (optional - if something has to be performed eg a fn block)
 * <== closeAndPerform  (optional - if something has to be performed additionally after closing the menu)
 * <== linkTo           (mandatory- Leads to a router path, eg. /play/farmville2)
 */
class MenuEndOption extends BaseComponent {
  constructor(props) {
    super(props);
    this.directPerform = props.perform;
    this.closeAndPerform = props.closeAndPerform;
    this.menuItemClick = this.menuItemClick.bind(this);
  }
  menuItemClick(evt) {
    if (evt) evt.preventDefault();
    if (this.directPerform) {
      this.directPerform();
    }
    // else nothing else to do
  }
  render() {
    return (
      <div className="div-button">
        {this.props.linkTo !== undefined && (
          <Link
            to={this.props.linkTo ? this.props.linkTo : ""}
            className="nav-link nav-link-style dropdown-item"
            onClick={() => {
              ServiceLocator.ZTrack.Cast({
                counter: "click",
                kingdom: "nav",
                phylum: "dropdown-item",
                zClass: this.props.desc ? this.props.desc : "general",
              });
              if (this.props.onClick !== undefined) this.props.onClick();
            }}
          >
            {this.props.iconPath && (
              <img
                src={this.props.iconPath}
                alt=">"
                className="menu-icon-styles"
              />
            )}
            {this.props.faClass && (
              <i
                className={this.props.faClass + " nav-fa-icon"}
                aria-hidden="true"
              ></i>
            )}
            <span className="menu-wrap-text-styles">{this.props.text}</span>
          </Link>
        )}

        {this.props.linkTo === undefined && (
          <div
            className="nav-link nav-link-style dropdown-item"
            onClick={() => {
              ServiceLocator.ZTrack.Cast({
                counter: "click",
                kingdom: "nav",
                phylum: "dropdown-item",
                zClass: this.props.desc ? this.props.desc : "general",
              });
              if (this.props.onClick !== undefined) this.props.onClick();
            }}
          >
            {this.props.iconPath && (
              <img
                src={this.props.iconPath}
                alt=">"
                className="menu-icon-styles"
              />
            )}
            {this.props.faClass && (
              <i
                className={this.props.faClass + " nav-fa-icon"}
                aria-hidden="true"
              ></i>
            )}
            <span className="menu-wrap-text-styles">{this.props.text}</span>
          </div>
        )}
      </div>
    );
  }
}

/**
 * Connect options when clicked on the login button.
 * Triggers a modal if unauthenticated, otherwise houses
 * the user options such as name, profile picture and other options.
 */
class ConnectOptions extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      jqModalId: "#loginModalCenter",
    };
    this.logoutHandler = props.logoutHandler;
    this.onClickProfile = this.onClickProfile.bind(this);
  }

  /**
   * When clicking on the profile dropdown
   * we want to close the notification dropdown
   */
  onClickProfile(e) {
    this.props.setHideWelcome();
    ServiceLocator.ZTrack.Cast({
      counter: "click",
      kingdom: "nav",
      phylum: "dropdown",
    });
    var container = $(e.currentTarget).parent();
    if (
      container.hasClass("show") &&
      $(".dropdown-container.expanded").hasClass("expanded")
    ) {
      $(".dropdown-container.expanded").removeClass("expanded");
      $(".dropdown-container").removeClass("expanded");
      $(".dropdown-container.fs-inherit.expanded").removeClass("expanded");
    }
  }

  render() {
    let UserState;
    if (this.props.loggedin === true) {
      // For facebook context
      if (ServiceLocator.Auth.Fb.Is(this.props.context)) {
        UserState = (
          <div>
            <div className="connect-user-state-loggedin">
              <div className="dropdown show" style={{ display: "inline" }}>
                <div
                  className={
                    "btn dropdown-toggle option-elements loggedin-button " +
                    (this.props.extraClass ? this.props.extraClass : "")
                  }
                  onClick={() => {
                    ServiceLocator.ZTrack.Cast({
                      counter: "click",
                      kingdom: "nav",
                      phylum: "dropdown",
                    });
                  }}
                  href="#"
                  style={{ cursor: "pointer", backgroundColor: "#E9E9E9" }}
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={this.props.data.picture.url}
                    className="connect-user-state-loggedin-picture"
                    alt="Player Pic"
                  />
                  {this.props.data.first_name}
                </div>

                <div
                  className="dropdown-menu dropdown-menu-customized dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <MenuEndOption
                    desc="profile"
                    linkTo="/profile/me"
                    text={LocUtils.T("breadcrumb.account")}
                    faClass="fa fa-user"
                  />
                  {/*<MenuEndOption
                    desc="payments"
                    text={LocUtils.T("breadcrumb.payments")}
                    onClick={() => {
                      this.props.settingsPaymentHandler();
                    }}
                    faClass="fa fa-credit-card"
                  />*/}
                  <MenuEndOption
                    desc="gdpr"
                    text={LocUtils.T("breadcrumb.gdpr_request")}
                    onClick={() => {
                      $("#gdprShowModal")
                        .trigger("prepareGDPRPin")
                        .trigger("prepareLoad")
                        .modal("show");
                    }}
                    faClass="fa fa-info"
                  />
                  <MenuEndOption
                    desc="email"
                    text={LocUtils.T("breadcrumb.email")}
                    onClick={() => {
                      window.open(
                        "https://emailsettings.zynga.com/accounts/",
                        "_blank"
                      );
                    }}
                    faClass="fa fa-envelope"
                  />

                  <div className="dropdown-divider"></div>
                  <MenuEndOption
                    desc="logout"
                    linkTo="/logout"
                    text={LocUtils.T("nav.logout")}
                    faClass="fa fa-sign-out"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else if (ServiceLocator.Auth.Zynga.Is(this.props.context)) {
        UserState = (
          <div className={this.props.extraClass ? this.props.extraClass : ""}>
            <div className="connect-user-state-loggedin">
              <div
                className="dropdown show option-elements"
                style={{ display: "inline" }}
              >
                <div
                  className={
                    "btn dropdown-toggle option-elements loggedin-button " +
                    (this.props.extraClass ? this.props.extraClass : "")
                  }
                  href="#"
                  onClick={this.onClickProfile}
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={this.props.data.userAttr.profilePicThumb}
                    className="connect-user-state-loggedin-picture"
                    alt="Player Pic"
                  />
                  {this.props.data.userAttr.firstName}
                </div>

                <div
                  className="dropdown-menu dropdown-menu-customized dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <MenuEndOption
                    desc="profile"
                    linkTo="/profile/me"
                    onClick={() => {
                      $(".modal").modal("hide");
                    }}
                    text={LocUtils.T("breadcrumb.account")}
                    faClass="fa fa-user"
                  />
                  {/*<MenuEndOption
                    desc="payments"
                    text={LocUtils.T("breadcrumb.payments")}
                    onClick={() => {
                      $(".modal").modal("hide");
                      this.props.settingsPaymentHandler();
                    }}
                    faClass="fa fa-credit-card"
                  />*/}
                  <MenuEndOption
                    desc="gdpr"
                    text={LocUtils.T("breadcrumb.gdpr_request")}
                    onClick={() => {
                      $(".modal").modal("hide");
                      $("#gdprShowModal")
                        .trigger("prepareGDPRPin")
                        .trigger("prepareLoad")
                        .modal("show");
                    }}
                    faClass="fa fa-info"
                  />
                  <MenuEndOption
                    desc="email"
                    text={LocUtils.T("breadcrumb.email")}
                    onClick={() => {
                      window.open(
                        "https://emailsettings.zynga.com/accounts/",
                        "_blank"
                      );
                    }}
                    faClass="fa fa-envelope"
                  />

                  <div className="dropdown-divider"></div>
                  <MenuEndOption
                    desc="logout"
                    linkTo="/logout"
                    text={LocUtils.T("nav.logout")}
                    faClass="fa fa-sign-out"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {

      UserState = (
        <div>
          <div className="connect-user-state-loggedout">
            <div
              className="option-elements"
              style={{ display: "inline-block" }}
            >
              <Button
                key="key-farm2-login"
                toggle="modal"
                forceClass="btn button-green btn-lg button-green-text vl-b"
                target={this.state.jqModalId}
                message={LocUtils.T("nav.login")}
                onClick={() => {
                  ServiceLocator.ZTrack.Cast({
                    counter: "click",
                    kingdom: "nav",
                    phylum: "login",
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="col connectOptions navbar-dark "
        style={{ visibility: this.props.locLoaded ? "visible" : "hidden" }}
      >
        <div style={{ display: "inline" }}>
          {/* <div className="option-elements" style={{ display: "inline-block" }}><LocalizationOptions locChangeHandler={this.props.locChangeHandler} /></div> */}
          <div className="option-elements" style={{ display: "inline-block" }}>
            {UserState}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * The component which houses the branding logo, loc component,
 * user connect options.
 */
class NavComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      communityVariant: false,
      username: this.props.cookieEmail,
      password: "",
      keepSignedIn: true,
      engaged: false,
      validation: {
        username: false,
        password: false,
        forgetEmail: false,
      },
      source: 1
    };
    this.logoutHandler = props.logoutHandler;
    this.loginAuthHandler = props.loginAuthHandler;
    this.onFbLoginClick = props.onFbLoginClick;
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
    this.onForgotPasswordLinkClick = this.onForgotPasswordLinkClick.bind(this);
    this.loginFBHandler = this.loginFBHandler.bind(this);

    // Subscription to turn of the field editing
    this.onEngageFinish = this.onEngageFinish.bind(this);
    if (!ServiceLocator.Events.isSubscribed("ZDC_LOGIN", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_LOGIN", this.onEngageFinish);
    if (!ServiceLocator.Events.isSubscribed("FACEBOOK_LOGIN", this.onEngageFinish)) ServiceLocator.Events.subscribe("FACEBOOK_LOGIN", this.onEngageFinish);
    if (!ServiceLocator.Events.isSubscribed("ZDC_CHECK_EMAIL_VERIFIED", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_CHECK_EMAIL_VERIFIED", this.onEngageFinish);

  }

  componentWillUnmount() {
    ServiceLocator.Events.unsubscribe("ZDC_LOGIN", this.onEngageFinish);
    ServiceLocator.Events.unsubscribe("FACEBOOK_LOGIN", this.onEngageFinish);
    ServiceLocator.Events.unsubscribe("ZDC_CHECK_EMAIL_VERIFIED", this.onEngageFinish);
  }

  onMouseOver() {
    this.setState({
      hovered: true,
    });
  }

  onMouseOut() {
    this.setState({
      hovered: false,
    });
  }

  /** Subscription handler */
  onEngageFinish(evt) {
    this.setState({
      engaged: false
    })
  }

  onChangeField(data) {
    this.pageActions.resetValidityCheck(data);
    this.props.resetInvalidCreds();
    this.setState(data);
  }

  onCheckboxChange(data) {
    var oldKeepSignedIn = this.state.keepSignedIn;
    this.setState({
      keepSignedIn: !oldKeepSignedIn,
    });
  }

  pageActions = {
    validityChecks: () => {
      const { forgetEmail, password, username } = this.state;
      let verdict = this.state;
      if (!password || password.length < 7) verdict.validation.password = true;
      if (!username || username.length === 0 || !Utils.ValidateEmail(username))
        verdict.validation.username = true;
      if (
        !forgetEmail ||
        forgetEmail.length === 0 ||
        !Utils.ValidateEmail(forgetEmail)
      )
        verdict.validation.forgetEmail = true;
      this.setState(verdict);
    },
    resetValidityCheck: (d = {}) => {
      let verdict = this.state;
      Object.keys(d).forEach((k) => {
        if (this.state.validation[k] !== undefined)
          verdict.validation[k] = false;
      });
      this.setState(verdict);
    },
    resetErrors: () => {
      let verdict = this.state;
      verdict.validation.username = false;
      verdict.validation.password = false;
      verdict.validation.forgetEmail = false;
      this.setState(verdict);
    },
  };

  onForgotPasswordLinkClick(evt) {
    $("#loginModalCenter").modal("show");
    this.pageActions.resetErrors();
    $("#login-carousel").carousel(1);
  }

  loginFBHandler() {
    this.onFbLoginClick();
    $("#loginModalCenter").modal("show");
    $("#login-carousel").carousel(0);
  }

  /** PANE1: When login button is clicked */
  onLoginButtonClick(evt) {
    // In the event that the user attempts multiple button click
    // we would want to avoid it till the time the network has
    // resolved itself
    if (this.state.engaged) return;

    this.pageActions.validityChecks();

    if (Utils.ValidateEmail(this.state.username) && Utils.ValidatePassword(this.state.password)) {
      this.setState({
        engaged: true,
      });
      this.loginAuthHandler(this.state);
    }

    // Clear password field if incorrect/invalid password is entered
    if (this.state.validation.password || this.state.validation.username) {
      let passwordId = "login-header-password";
      document.getElementById(passwordId).value = '';
    }
  }

  componentDidMount() {
    const variant = ServiceLocator.OptimizationManager.GoogleOptimize.GetExperimentVariant(
      process.env.REACT_APP_GOPT_COMMUNITY
    );
    if (variant === "2") {
      this.setState({ communityVariant: true });
    }
  }

  render() {
    return (
      <nav
        id="nav-header"
        className="navbar navbar-expand-lg navbar-dark sticky-top"
        style={{ zIndex: "9", backgroundColor: "#222222", padding: "0px", height: "95px" }}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        {!this.props.loggedin && (
          <div className="display-contents">

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse mr-3 ai-fs"
              id="navbarTogglerBurger"
            >
              <div
                className="col-md-6 p-0"
              >
                <Link
                  onClick={() => {
                    $(".modal").modal("hide");
                    ServiceLocator.ZTrack.Cast({
                      counter: "click",
                      kingdom: "nav",
                      phylum: "brand",
                    });
                  }}
                  className="navbar-brand"
                  to="/"
                >
                  {!!this.props.useBrandForHome && (
                    <div>
                      <img
                        className={
                          (this.state.hovered
                            ? "branding-hover-show"
                            : "branding-hover-hide") + " zynga-logo-branding"
                        }
                        src={process.env.REACT_APP_ASSET_PATH + "/nav/home.png"}
                        alt=""
                        style={{ position: "absolute" }}
                      />
                      <img
                        className={
                          (this.state.hovered
                            ? "branding-hover-hide"
                            : "branding-hover-show") + " zynga-logo-branding"
                        }
                        src={process.env.REACT_APP_ASSET_PATH + "/zyngalogo.png"}
                        alt=""
                        style={{ position: "relative" }}
                      />
                    </div>
                  )}
                  {!this.props.useBrandForHome && (
                      <img
                          className={"branding-hover-show zynga-logo-branding"}
                          src={process.env.REACT_APP_ASSET_PATH + "/zyngalogo.png"}
                          alt=""
                          style={{ position: "relative" }}
                      />

                  )}
                </Link>
                {!!this.props.isZDCExpCouponActive && (
                    <div  style={{ display: "inline-block", position:"relative" }}>
                      <img
                          className={"exp-coupon-image"}
                          src={process.env.REACT_APP_ASSET_PATH + "/icon_zdc_exp_coupon_freetag_combo.png"}
                          alt=""
                          style={{ position: "relative" }}
                      />
                      <h1 className={"exp-coupon-text"}>{LocUtils.T("feature.dialog.zdcExpCoupon.sideCardBody")}</h1>

                    </div>



                )}


              </div>

              {!this.props.loggedin && (
                <div className="col-md-6 p-0 loginSection">
                  <div className="row no-gutters">
                    <div className="col-md-7 p-0 m-0">
                      <div className="row no-gutters">
                        <div className="form-group mt-2 col-md-6 pr-2 mb-0">
                          <div
                            aria-describedby="emailHelp"
                            className="input-group input-group-sm"
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">
                                <i
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            <input
                              disabled={this.state.engaged}
                              type="email"
                              onChange={(evt) => {
                                if (evt) evt.preventDefault();
                                this.onChangeField({ username: evt.target.value });
                              }}
                              placeholder={LocUtils.TPure("login.email")}
                              className={
                                ((!!this.state.validation.username || (!!this.props.invalidCredentials && this.props.source === 1))
                                  ? "is-invalid no-cross-img "
                                  : "") + "form-control"
                              }
                              aria-label="Email"
                              id={"login-header-email"}
                              value={this.state.username}
                            />
                            {(!this.props.invalidCredentials && !!this.state.validation.username) && (
                              <div className="invalid-tooltip" style={{ "marginLeft": "67px", "backgroundColor": "#FFCDCD", "color": "#222222" }}>
                                {LocUtils.TPure("toasts.forget_password_invalid_email")}
                              </div>
                            )}
                          </div>
                          <small
                            id="emailHelp"
                            className="form-text input-text-below fs-i"
                          >
                            <input
                              disabled={this.state.engaged}
                              onChange={(evt) => {
                                this.onCheckboxChange({
                                  keepSignedIn: evt.target.value,
                                });
                              }}
                              type="checkbox"
                              defaultChecked={this.state.keepSignedIn}
                              style={{ marginRight: "5px" }}
                            />
                            {LocUtils.TPure("login.keep_logged_in")}
                          </small>
                        </div>
                        <div className="form-group mt-2 col-md-6 pr-0 mb-0">
                          <div
                            aria-describedby="passHelp"
                            className="input-group input-group-sm"
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">
                                <i className="fa fa-key" aria-hidden="true"></i>
                              </span>
                            </div>
                            <input
                              disabled={this.state.engaged}
                              type="password"
                              onChange={(evt) => {
                                if (evt) evt.preventDefault();
                                this.onChangeField({ password: evt.target.value });
                              }}
                              placeholder={LocUtils.TPure("login.password")}
                              className={
                                ((!!this.state.validation.password || (!!this.props.invalidCredentials && this.props.source === 1))
                                  ? "is-invalid no-cross-img "
                                  : "") + "form-control"
                              }
                              aria-label="Password"
                              id={"login-header-password"}
                            />
                            <div className="invalid-tooltip" style={{ "marginLeft": "48px", "backgroundColor": "#FFCDCD", "color": "#222222" }}>
                              {(!!this.props.invalidCredentials && this.props.source === 1) ? LocUtils.TPure("toasts.invalid_credentials") : LocUtils.TPure("toasts.forget_password_invalid_password")}
                            </div>

                          </div>
                          <a href="#login-carousel">
                            <small
                              id="passHelp"
                              className="form-text input-text-below fs-i"
                              onClick={this.onForgotPasswordLinkClick}
                            >
                              {LocUtils.TPure("login.login_forgot_password")}
                            </small>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 p-0 m-0">
                      <div className="row no-gutters">
                        <div className="col-md-3 mt-1">
                          {this.props.loggedin && this.state.communityVariant && (
                            <div className="community">
                              <a
                                onClick={() => {
                                  ServiceLocator.ZTrack.Cast({
                                    counter: "click",
                                    kingdom: "nav",
                                    phylum: "community",
                                  });
                                }}
                                href={SiteConstants.UrlCodes.Games.Farm2.FORUM_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-users home-button community"></i>
                                <i className="fa fa-external-link external-link"></i>
                              </a>
                            </div>
                          )}

                          <NotificationBell
                            loggedin={this.props.loggedin}
                            data={this.props.data}
                            setHideWelcome={this.props.setHideWelcome}
                          />
                          {/* <ConnectOptions
                    loggedin={this.props.loggedin}
                    data={this.props.data}
                    extraClass="nav-buttons"
                    logoutHandler={this.logoutHandler}
                    context={this.props.context}
                    locChangeHandler={this.props.locChangeHandler}
                    locLoaded={this.props.locLoaded}
                    settingsPaymentHandler={this.props.settingsPaymentHandler}
                    communityVariant={this.state.communityVariant}
                  /> */}
                          <div
                            className="col connectOptions navbar-dark "
                            style={{
                              visibility: this.props.locLoaded
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            <div style={{ display: "inline" }}>
                              {/* <div className="option-elements" style={{ display: "inline-block" }}><LocalizationOptions locChangeHandler={this.props.locChangeHandler} /></div> */}
                              <div
                                className="option-elements"
                                style={{ display: "inline-block" }}
                              >
                                <div>
                                  <div className="connect-user-state-loggedout btn-separator">
                                    <div
                                      className="option-elements mr-14"
                                      style={{ display: "inline-block" }}
                                    >
                                      {/* <Button key="key-farm2-login" toggle="modal" forceClass="btn button-green btn-lg button-green-text vl-b"
                            target={this.state.jqModalId} message={LocUtils.T("nav.login")}
                            onClick={() => {
                                ServiceLocator.ZTrack.Cast({
                                    counter: "click",
                                    kingdom: "nav",
                                    phylum: "login"
                                })
                            }} /> */}
                                      <Button
                                        onClick={() => {
                                          ServiceLocator.ZTrack.Cast({
                                            counter: "zdc_2",
                                            kingdom: "click",
                                            phylum: "header",
                                            zClass: "signin",
                                            family: "zdc_register"
                                          });
                                          this.onLoginButtonClick();
                                        }}
                                        forceClass="btn button-green btn-lg button-green-text vl-b"
                                        message={
                                          LocUtils.T("login.login_btn")
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!this.props.isZDCMigrationModule2 && (
                            <div className="col-md-9 mt-1 pl-5">
                              <FacebookButton
                                  onClick={() => {
                                    ServiceLocator.ZTrack.Cast({
                                      counter: "zdc_2",
                                      kingdom: "click",
                                      phylum: "header",
                                      zClass: "fbconnect",
                                      family: "zdc_register"
                                    });
                                    this.loginFBHandler();
                                  }}
                                  extraClass="fb-connect-btn"
                                  message={LocUtils.T("login.signup_connect")}
                              />
                            </div>
                        )}
                        {this.props.isZDCMigrationModule2 && (
                            <div className="col-md-9 mt-1 pl-5">
                              <Button
                                  type="link"
                                  to={"/create_password"}
                                  onClick={() => {
                                    $("#loginModalCenter").modal('hide');
                                    ServiceLocator.ZTrack.Cast({
                                      counter: "zdc_2",
                                      kingdom: "click",
                                      phylum: "header",
                                      zClass: "restore_facebook_farm",
                                      family: "zdc_register"
                                    });
                                  }}
                                  extraClass="btn-lg vl-b restore-farm-button"
                                  severity="action"
                                  message={LocUtils.T("dialogs.zdcMigration.restoreFacebookFarm.restoreFacebookFarmButton")}
                              />
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>)}

        {/* non logged in flow ending here */}

        {this.props.loggedin && (
          <div className="display-contents">

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerBurger">
              <Link
                onClick={() => {
                  $(".modal").modal("hide");
                  ServiceLocator.ZTrack.Cast({
                    counter: "click",
                    kingdom: "nav",
                    phylum: "brand",
                  });
                }}
                className="navbar-brand"
                to="/"
              >
                {!!this.props.useBrandForHome && (
                  <div>
                    <img
                      className={
                        (this.state.hovered
                          ? "branding-hover-show"
                          : "branding-hover-hide") + " zynga-logo-branding"
                      }
                      src={process.env.REACT_APP_ASSET_PATH + "/nav/home.png"}
                      alt=""
                      style={{ position: "absolute" }}
                    />
                    <img
                      className={
                        (this.state.hovered
                          ? "branding-hover-hide"
                          : "branding-hover-show") + " zynga-logo-branding"
                      }
                      src={
                        process.env.REACT_APP_ASSET_PATH +
                        "/zyngalogo.png"
                      }
                      alt=""
                      style={{ position: "relative" }}
                    />
                  </div>
                )}
                {!this.props.useBrandForHome && (
                  <img
                    className={"branding-hover-show zynga-logo-branding"}
                    src={
                      process.env.REACT_APP_ASSET_PATH +
                      "/zyngalogo.png"
                    }
                    alt=""
                    style={{ position: "relative" }}
                  />
                )}
              </Link>

              {this.props.loggedin && this.props.featureActive &&
                < HUD
                  featureActive={this.props.featureActive}
                  featureData={this.props.featureData}
                  loggedin={this.props.loggedin}
                  hudUIVariant={this.props.hudUIVariant}
                />
              }

              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="nav-item nav-center">
                  <LoadingWidget id="nav-widget" register="true" force="true" />
                </li>
              </ul>
            </div>

            {(this.props.alreadyLoggedin === undefined ||
              this.props.loginFetching === true) && (
                <div className="row no-gutters">
                  <div className="col">
                    <ContentLoading />
                  </div>
                </div>
              )}
            {this.props.alreadyLoggedin !== undefined &&
              this.props.loginFetching === false && (
                <div className="row no-gutters">
                  {this.props.loggedin && this.state.communityVariant && (
                    <div className="community">
                      <a
                        onClick={() => {
                          ServiceLocator.ZTrack.Cast({
                            counter: "click",
                            kingdom: "nav",
                            phylum: "community"
                          })
                        }}
                        href={SiteConstants.UrlCodes.Games.Farm2.FORUM_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-users home-button community"></i>
                        <i className="fa fa-external-link external-link"></i>
                      </a>
                    </div>
                  )}
                  {!!this.props.isZDCExpCouponActive && (
                      <div>
                        <img
                            className={"exp-coupon-image-right"}
                            src={process.env.REACT_APP_ASSET_PATH + "/icon_zdc_exp_coupon_freetag_combo.png"}
                            alt=""
                            style={{ position: "relative" }}
                        />
                        <h1 className={"exp-coupon-text-right"}>{LocUtils.T("feature.dialog.zdcExpCoupon.sideCardBody")}</h1>

                      </div>



                  )}
                  <NotificationBell
                    loggedin={this.props.loggedin}
                    data={this.props.data}
                    setHideWelcome={this.props.setHideWelcome}
                  />
                  <ConnectOptions
                    loggedin={this.props.loggedin}
                    data={this.props.data}
                    extraClass="nav-buttons"
                    logoutHandler={this.logoutHandler}
                    context={this.props.context}
                    locChangeHandler={this.props.locChangeHandler}
                    locLoaded={this.props.locLoaded}
                    settingsPaymentHandler={this.props.settingsPaymentHandler}
                    setHideWelcome={this.props.setHideWelcome}
                    communityVariant={this.state.communityVariant}
                  />
                </div>
              )}

          </div>
        )}
      </nav>
    );
  }
}

export default NavComponent;
