import React from "react";
import BaseComponent from "../../../BaseComponent";
import LocUtils from "../../../../util/localization/LocUtils"
import ServiceLocator from '../../../../ServiceLocator'

class StepComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'ftue_step_complete',
            phylum: this.props.step
        });
    }

    render() {
        const ftueText = "feature.ftue.step_" + this.props.step;
        return (
            <div className="row" style={{ marginTop: '8px' }}>
                <div className="col-4">
                    <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/marie_npc.png"}
                        style={{ height: '120px' }} />
                </div>

                <div className="col-8" style={{ textAlign: 'left' }}>
                    {this.props.step === 1 ?
                        LocUtils.T(ftueText, { name: this.props.firstName }) : LocUtils.T(ftueText)}
                </div>

            </div>
        );
    }
}

export default StepComponent;