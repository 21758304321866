class ZPersona {
    constructor(props) {
        Object.keys(props).forEach(key => {
            this[key] = props[key];
        })
        Object.freeze(this); // Do not allow this persona to be altered
    }

    email() {
        return String(this.email); // Subject to storage
    }

    alternateEmail() {
        return String(this["alternate emails"]); // Subject to storage
    }

    birthday() {
        return String(this.birthday); // Subject to storage
    }


}

export default ZPersona;