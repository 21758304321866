/**
 * Ajax.js
 * Ajax wrapper for Jquery $.ajax()
 * All website networking happens through these methods.
 * @author Jaiwardhan Swarnakar
 */
import $ from 'jquery'
import ServiceLocator from '../../ServiceLocator'

var Ajax = (function() {
    function Call({payload, onComplete, onFailure, token}) {
        payload = payload?payload:{};
        onComplete = onComplete?onComplete:()=>{};
        onFailure = onFailure?onFailure:()=>{};

        // Tell the network queue that a new network request is being added
        var _networkRequestToken = ServiceLocator.NetworkManager.Queue.Add({
            requestType: token
        });
        $.ajax(
            payload
        ).done(resp => {
            ServiceLocator.NetworkManager.Queue.Complete(_networkRequestToken);
            onComplete(resp);
        })
        .fail(err => {
            ServiceLocator.NetworkManager.Queue.Complete(_networkRequestToken);
            onFailure(err);
        })
    }

    return {
        Call    : Call
    }
})();

export default Ajax;