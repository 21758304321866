import React from 'react'
import BaseComponent from './BaseComponent';
import Routes from './routing/Routes'
import './Content.css'
class Content extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div id="contentSection">
                <Routes {...this.props} />
            </div>
        )
    }
}

export default Content;