/**
 * LocUtils.js
 * Localization utility to perform translations and pluralization
 * for us. Uses Airbnb PolyGlot package.
 * Picks up user's preference from localstorage and loads
 * appropriate translation library for that component.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react'
import Polyglot from 'node-polyglot'
import DataStore from '../../data/DataStore'
import ZLogger from '../zlogger/zlogger'
import pseudoLocalization from 'pseudo-localization'
import ReactHtmlParser from 'react-html-parser';
import './LocUtils.css'

/** 
 * A cache of loaded localization libs. */
var LocCache = (function () {
    var _cache = {};
    function Add(lang, data) {
        _cache[lang] = data;
    }

    function Remove(lang) {
        if (_cache[lang]) {
            delete _cache[lang];
        }
    }

    function Exists(lang) {
        return _cache[lang] !== undefined;
    }

    function Get(lang) {
        return _cache[lang];
    }

    // =========================================================== DEV TOOLS
    function Clear() {
        if (process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_ADMIN_CONSOLE === 'allowed'
        ) {
            _cache = {};
        }
    }
    // ===========================================================

    return {
        Add: Add,
        Clear: Clear,
        Exists: Exists,
        Get: Get,
        Remove: Remove
    }
})();

/**
 * Utility wrapper over polyglot, to do the translation and
 * pluralization for us. We change the locale by dynamically
 * loading locales on selection basis and store it as a user preference.
 */
var LocUtils = (function () {
    ZLogger.announce("Initializing LocUtils");
    var active_localizer = {
        lang: undefined,
        path: "",
        config: {}
    }
    var _initComplete = false;
    var _translator = new Polyglot();
    var _langStorageKey = "lang";
    var isRTL = false;

    /** 
     * Any new translation when added, has to be mapped here
     */
    var _translation_mappers = {
        "en": {
            loader: () => { return import("./packages/en_US.json" /* webpackChunkName: "Lang_en" */) },
            standardCode: "en_US",
            symbol: "En",
            localeGuide: "dialogs.locale.available.english"
        },

        // Dansk
        "da": {
            loader: () => { return import("./packages/da_DK.json" /* webpackChunkName: "Lang_dk" */) },
            standardCode: "da_DK",
            symbol: "da",
            localeGuide: "dialogs.locale.available.dansk"
        },

        // Deutsch
        "de": {
            loader: () => { return import("./packages/de_DE.json" /* webpackChunkName: "Lang_de" */) },
            standardCode: "de_DE",
            symbol: "de",
            localeGuide: "dialogs.locale.available.deutsch"
        },

        // Español es_ES
        "es": {
            loader: () => { return import("./packages/es_ES.json" /* webpackChunkName: "Lang_es" */) },
            standardCode: "es_ES",
            symbol: "es",
            localeGuide: "dialogs.locale.available.espanol"
        },

        // Français fr_FR
        "fr": {
            loader: () => { return import("./packages/fr_FR.json" /* webpackChunkName: "Lang_fr" */) },
            standardCode: "fr_FR",
            symbol: "fr",
            localeGuide: "dialogs.locale.available.french"
        },

        // Bahasa Indonesia id_ID
        "id": {
            loader: () => { return import("./packages/id_ID.json" /* webpackChunkName: "Lang_id" */) },
            standardCode: "id_ID",
            symbol: "id",
            localeGuide: "dialogs.locale.available.bahasa"
        },

        // Italiano it_IT
        "it": {
            loader: () => { return import("./packages/it_IT.json" /* webpackChunkName: "Lang_it" */) },
            standardCode: "it_IT",
            symbol: "it",
            localeGuide: "dialogs.locale.available.italian"
        },

        // Japanese ja_JP
        "ja": {
            loader: () => { return import("./packages/ja_JP.json" /* webpackChunkName: "Lang_ja" */) },
            standardCode: "ja_JP",
            symbol: "ja",
            localeGuide: "dialogs.locale.available.japanese"
        },

        // Korean ko_KR
        "ko": {
            loader: () => { return import("./packages/ko_KR.json" /* webpackChunkName: "Lang_ko" */) },
            standardCode: "ko_KR",
            symbol: "ko",
            localeGuide: "dialogs.locale.available.korean"
        },

        // Norsk nb_NO
        "nb": {
            loader: () => { return import("./packages/nb_NO.json" /* webpackChunkName: "Lang_nb" */) },
            standardCode: "nb_NO",
            symbol: "nb",
            localeGuide: "dialogs.locale.available.norsk"
        },

        // Netherlands nl_NL
        "nl": {
            loader: () => { return import("./packages/nl_NL.json" /* webpackChunkName: "Lang_nl" */) },
            standardCode: "nl_NL",
            symbol: "nl",
            localeGuide: "dialogs.locale.available.netherlands"
        },

        // Português pt_BR
        "pt": {
            loader: () => { return import("./packages/pt_BR.json" /* webpackChunkName: "Lang_pt" */) },
            standardCode: "pt_BR",
            symbol: "pt",
            localeGuide: "dialogs.locale.available.portugues"
        },

        // Svensk sv_SE
        "sv": {
            loader: () => { return import("./packages/sv_SE.json" /* webpackChunkName: "Lang_sv" */) },
            standardCode: "sv_SE",
            symbol: "sv",
            localeGuide: "dialogs.locale.available.svensk"
        },

        // thai th_TH
        "th": {
            loader: () => { return import("./packages/th_TH.json" /* webpackChunkName: "Lang_th" */) },
            standardCode: "th_TH",
            symbol: "th",
            localeGuide: "dialogs.locale.available.thai"
        },

        // Turkish tr_TR
        "tr": {
            loader: () => { return import("./packages/tr_TR.json" /* webpackChunkName: "Lang_tr" */) },
            standardCode: "tr_TR",
            symbol: "tr",
            localeGuide: "dialogs.locale.available.turkish"
        },

        // chinese Taiwan zh_TW
        "zh": {
            loader: () => { return import("./packages/zh_TW.json" /* webpackChunkName: "Lang_zh" */) },
            standardCode: "zh_TW",
            symbol: "zh",
            localeGuide: "dialogs.locale.available.taiwan"
        },

        // arabic Egyptian
        "ar": {
            loader: () => { return import("./packages/ar_EG.json" /* webpackChunkName: "Lang_ar" */) },
            standardCode: "ar_EG",
            symbol: "ar",
            localeGuide: "dialogs.locale.available.egyptian",
            isRTL: true
        },
    }

    /**
     * A loc adapter to put in front of the translator module
     * to modify translation outputs.
     * Override the exposed module of your choice. See usage 
     * for more info.
     */
    var _locAdapter = {
        T: (t) => { return t }
    }

    /**
     * Returns an array of all available translation symbols and its codes
     */
    function GetTranslationSymbols() {
        var result = [];
        for (var k in _translation_mappers) {
            var val = _translation_mappers[k];
            result.push({ symbol: val.symbol, code: k, localeGuide: val.localeGuide });
        }
        return result;
    }

    /**
     * Returns the currently active locale code. 
     * Default is assumed to be en
     */
    function GetActiveLocaleCode() {
        return active_localizer.lang ? active_localizer.lang : "en";
    }

    /**
     * Returns the currently active locale symbol.
     * Default is assumed to be en
     */
    function GetActiveLocaleSymbol() {
        var code = active_localizer.lang ? active_localizer.lang : "en";
        return _translation_mappers[code].symbol;
    }

    function GetActiveLocaleStandardCode() {
        var code = active_localizer.lang ? active_localizer.lang : "en";
        return _translation_mappers[code].standardCode;
    }

    function GetLocaleKeyFromLocaleCode(localeCode) {
        localeCode = (localeCode === undefined) ? "" : localeCode;
        for (var key in _translation_mappers) {
            var localeData = _translation_mappers[key];
            if (localeData.standardCode === localeCode) {
                return key;
            }
        }
        return GetActiveLocaleCode();
    }

    function GetLocaleSymbolFromLocaleKey(localeKey) {
        localeKey = (localeKey === undefined) ? "en" : localeKey;
        if (localeKey in _translation_mappers) { return _translation_mappers[localeKey].standardCode }
        return GetActiveLocaleSymbol();
    }

    function tBase(key, ...rest) {
        // The incoming string from _translator can contain some HTML object as strings.
        // We parse them to DOM objects safely with ReactHtmlParser
        let parsedHTML = ReactHtmlParser(_translator.t(key, ...rest));
        // If an adapter is active, such as psuedoloc, we will need to translate the children
        // text of objects and normal string as well to psuedoloc for testing to be consistent.
        for (var eachIndex in parsedHTML) {
            var _type = typeof (parsedHTML[eachIndex]);
            var e = parsedHTML[eachIndex];
            switch (_type) {
                case "object":
                    if (e.props && e.props.children
                        && Array.isArray(e.props.children)
                    ) {
                        for (var i = 0; i < e.props.children.length; ++i) {
                            e.props.children[i] = _locAdapter.T(e.props.children[i]);
                        }
                        parsedHTML[eachIndex] = e;
                    }
                    break;
                case "string":
                    e = _locAdapter.T(e);
                    parsedHTML[eachIndex] = e;
                    break;
                default:
                    parsedHTML[eachIndex] = e; // don't do anything
            }
        }

        return parsedHTML;
    }

    /**
     * Translates a key and rest of the parameters.
     */
    function T(key, ...rest) {
        if (_initComplete && !_translator.has(key)) {
            ZLogger.fatal("LocUtils.T:: LockeyNotFoundError: ", key);
            return "==:==";
        }
        const translation = tBase(key, ...rest);

        return isRTL
            ? <text className="bilingual-excerpt">
                {translation}
            </text>
            : translation;
    }

    function TPure(key, ...rest) {
        if (_initComplete && !_translator.has(key)) {
            ZLogger.fatal("LocUtils.T:: LockeyNotFoundError: ", key);
            return "==:==";
        }
        const translation = tBase(key, ...rest);

        return translation;
    }

    function CurrentIsRTL() {
        return (active_localizer.lang && !!_translation_mappers[active_localizer.lang].isRTL);
    }

    /** 
     * Loads a new translation locale dynamically and 
     * dumps it into polyglot. Changing the locale code
     * takes care of the pluralization as well.
     */
    function Load(to, onComplete, onFailure) {
        onComplete = onComplete ? onComplete : () => { };
        onFailure = onFailure ? onFailure : () => { };
        if (to === undefined || to.length === 0 || _translation_mappers[to] === undefined) {
            to = "en";
        }
        var packagePath = _translation_mappers[to];
        if (active_localizer.lang !== to) {
            ZLogger.announce("LocUtils:Load:: Attempting to change localizer to: ", to);
            // Check if this already cached? If yes,
            // then retrieve the config and extend the 
            // translator.
            if (LocCache.Exists(to)) {
                ZLogger.log("LocUtils:Load:: Cache exists..", to);
                var config = LocCache.Get(to);
                active_localizer.lang = to;
                active_localizer.path = packagePath;
                active_localizer.config = config;
                _translator.extend(active_localizer.config);
                _translator.locale({ locale: to });
                // Store as a user preference
                DataStore.LocalStorage.AsyncStore(_langStorageKey, to).then((data) => {
                    isRTL = !!_translation_mappers[to].isRTL;
                    onComplete(to);
                });
            } else {
                // Else we will need to download the new package and extend the
                // translator to override all the keys
                ZLogger.log("LocUtils:Load:: Attempting to load packages..", to);
                packagePath.loader().then(data => {
                    active_localizer.lang = to;
                    active_localizer.path = packagePath;
                    active_localizer.config = data.default;
                    // Cache the config, so that if switching back and forth
                    // then translations are fast enough
                    LocCache.Add(to, active_localizer.config);
                    _translator.extend(active_localizer.config);
                    _translator.locale({ locale: to });
                    // Store as a user preference
                    DataStore.LocalStorage.AsyncStore(_langStorageKey, to).then((data) => {
                        ZLogger.log("==> completing: ", to);
                        isRTL = !!_translation_mappers[to].isRTL;
                        onComplete(to);
                    })
                }).catch(reason => {
                    ZLogger.fatal("LocUtils:Load:: Failure when loading packages..");
                    onFailure(reason);
                })

            }
        }
    }

    /**
     * Initializes the localizer with a lang, en default
     */
    function Init(onComplete) {
        onComplete = onComplete ? onComplete : () => { };
        var langPref = DataStore.LocalStorage.Get(_langStorageKey);
        if (langPref === null || _translation_mappers[langPref] === undefined) {
            langPref = "en";
        }

        // Check if pseudo localization adapter is required
        if (process.env.REACT_APP_LOCALIZATION_PSUEDO === 'allowed') {
            _locAdapter.T = pseudoLocalization.localize;
            // Also watch any plain text coming on this page
            pseudoLocalization.start();
        }
        Load(langPref, (to) => { _initComplete = true; onComplete(to) });
    }

    function IsInitialized() {
        const ready = !!_initComplete;
        return ready;
    }

    /**
     * =========================================================== DEV TOOLS
     * Hot reload for locales only available in development mode
     * ===========================================================
     */
    function HotReload(contents, onDone) {
        if (process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_ADMIN_CONSOLE === 'allowed'
        ) {
            onDone = onDone ? onDone : (() => { })
            // First lets clear the cache
            LocCache.Remove(active_localizer.lang);
            _translator.extend(contents);
            window.onLocLoaded({ locLoaded: true, locCode: active_localizer.lang });
            onDone(active_localizer.lang);
        }
    }

    return {
        CurrentIsRTL: CurrentIsRTL,
        GetActiveLocaleCode: GetActiveLocaleCode,
        GetActiveLocaleStandardCode: GetActiveLocaleStandardCode,
        GetLocaleSymbolFromLocaleKey: GetLocaleSymbolFromLocaleKey,
        GetActiveLocaleSymbol: GetActiveLocaleSymbol,
        GetLocaleKeyFromLocaleCode: GetLocaleKeyFromLocaleCode,
        GetTranslationSymbols: GetTranslationSymbols,
        HotReload: HotReload,
        Init: Init,
        IsInitialized: IsInitialized,
        Load: Load,
        T: T,
        TPure: TPure
    }
})();
window.L = LocUtils;
window.R = ReactHtmlParser;
export default LocUtils;