/** 
 * Queue.js
 * Ensure the loading GIF on top right is visible when a network call
 * is made.
 * Essentially Keeps track of network requests being wired.
 * Simply update this queue before making a network request and remove it
 * after your relevant work is done.
 */
import ServiceLocator from '../../ServiceLocator'
import ZLogger from '../../util/zlogger/zlogger'

var Queue = (function() {
    var queue = {};
    function Add({requestType, onComplete}, data) {
        var requestObj = _get(requestType, onComplete);
        if(Object.keys(queue).length === 0) {
            ServiceLocator.Events.dispatch(ServiceLocator.Events.EVENT_LIST.NETWORK.LOAD_PENDING, data);
        }
        if(queue[requestObj.requestType] === undefined) {
            queue[requestObj.requestType] = {};
        }
        queue[requestObj.requestType][requestObj.ts] = requestObj.onComplete;
        return requestObj;
    }

    function Complete(requestObj, data) {
        ZLogger.log("Queue:Complete:: ", requestObj);
        if(queue[requestObj.requestType] !== undefined) {
            ZLogger.log("Queue:Complete:: Clearing Network request.. ", requestObj.requestType);
            // Extract the event before destruction
            var onComplete = queue[requestObj.requestType][requestObj.ts];

            // Signal that the network request is complete
            ServiceLocator.Events.dispatch(ServiceLocator.Events.EVENT_LIST.NETWORK.LOAD_COMPLETE, data);
            // And remove the request key
            delete queue[requestObj.requestType][requestObj.ts];
            if(Object.keys(queue[requestObj.requestType]).length === 0) {
                delete queue[requestObj.requestType];
            }
            // If there are no pending requests left, signal that total load is cleared
            if(Object.keys(queue).length === 0) {
                ServiceLocator.Events.dispatch(ServiceLocator.Events.EVENT_LIST.NETWORK.LOAD_CLEARED, data);
                ServiceLocator.Events.dispatch(requestObj.requestType, data);
            }

            // Finally if the handler is valid, trigger it
            if(onComplete !== undefined) {
                onComplete(data);
            }
        }
    }

    function _get(requestType, onComplete) {
        if(requestType === undefined) requestType = Id.Generate();
        return {
            requestType: requestType,
            ts: TS.getTimeString(),
            onComplete: (onComplete === undefined)?(function() {}):onComplete
        }
    }
    
    return {
        Add         : Add,
        Complete    : Complete    
    }
})();

/**
 * Returns the current timestamp
 */
var TS = (function() {

    function getTimeString() {
        return String((new Date()).getTime());
    }

    return {
        getTimeString: getTimeString
    }
})();

/**
 * Generates a random Id
 */
var Id = (function() {
    var _len = 10;
    function Generate() {
        return Array(_len+1).join((Math.random().toString(36)+'00000000000000000').slice(2, 18)).slice(0, _len);
    }

    return {
        Generate    : Generate
    }
})();

export default Queue;