/**
 * NotificationItem.js
 * This react component renders a notification item that is responsive and
 * can be configured to add additional actions.
 */
import React from 'react';
import BaseComponent from "../../BaseComponent";
import './NotificationBell.css'
import '../../../../styles/generic.css'
import LocUtils from '../../../util/localization/LocUtils';
import ServiceLocator from '../../../ServiceLocator';
import NotifLoading from './NotifLoading'
import Button from '../buttons/Buttons';
import Toast from '../../../util/toasts/Toast';

class NotificationItem extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            fname: "",
            pictureURL: "https://zlive.cdn.zynga.com/zdc_static/zdc-c436fe34_b1882547_1081b27d-static/images/common_web/ZDC_default_profile.png",
            type: 300,
            viewed: 0,
            fromZid: 0,
            identified: false,
            pending: false,
            selfDestruct: false,    // When you want to hide this item, just do a self destruct
            processing: false,
        }
        this.onIdentified = this.onIdentified.bind(this);
        this.transform = this.transform.bind(this);
    }
    /*
    The below fields are comm codes from the
    comms api.
        const TYPE_GENERIC_REQUEST = 300;
        const TYPE_FRIEND_REQUEST = 301;
        const TYPE_HELP_REQUEST = 302;
        const TYPE_GIFT_REQUEST = 303;
        
        // Notifications
        const TYPE_FRIEND_ACCEPTED = {
            code: 410,
            template: ""
        };
        const TYPE_FRIEND_ACCEPTED = 410; 
        const TYPE_WALL_POST = 420;
        const TYPE_LIKE = 430;
        const TYPE_LIKE_ON_LIKE = 431;
        const TYPE_COMMENT = 440;
        const TYPE_COMMENT_ON_COMMENTED = 441;
        const TYPE_HELP = 450;
        const TYPE_HELP_ON_HELPED = 451;
        const TYPE_ENERGY = 460;
    */

    /**
     * After a person is identified, the profile data is passed to the states
     * so that all notification item features can be shown.
     * @param {Object} identifiedGuy Should contain the user attributes
     */
    onIdentified(identifiedGuy = {}) {
        try {
            if (identifiedGuy.e !== 0 || !identifiedGuy.users || !identifiedGuy.users[this.state.fromZid]) {
                super.fatal("Hmm this is embarrassing. ", this.state.fromZid, " was not found :/, self destruct");
                this.setState({
                    selfDestruct: true
                })
                return
            }
            const { firstName, lastName, profilePicThumb } = identifiedGuy.users[this.state.fromZid];
            this.setState({
                fname: firstName,
                lname: lastName,
                pictureURL: profilePicThumb,
                pending: false
            })
        } catch (e) {
            super.fatal("Exception when person was identified..", e);
        }
    }

    componentDidMount() {
        // See if we can find this person's data in the Directory
        // If not, we need to request the directory service to get us this
        // profile data and then only render this item afterwards and show a
        // loading template till then
        const { fromZid, type, viewed } = this.props;
        if (!!ServiceLocator.SocialNetworkManager.People.Directory.Lookup(String(fromZid))) {
            const { firstName, lastName, profilePicThumb } = ServiceLocator.SocialNetworkManager.People.Directory.Lookup(String(fromZid));
            this.setState({
                fname: firstName,
                lname: lastName,
                fromZid: fromZid,
                type: type,
                viewed: viewed,
                pictureURL: profilePicThumb
            })
        } else {
            ServiceLocator.SocialNetworkManager.People.Directory.Identify(
                fromZid,
                this.onIdentified
            )
            this.setState({
                fromZid: fromZid,
                type: type,
                viewed: viewed,
                pending: true
            })
        }

    }

    getTitleKey(type) {
        const rootKey = "notification.types."
        switch (type) {
            case 301:
                return rootKey + "friendRequest.title"
            case 302:
                return rootKey + "help.title"
            case 303:
                return rootKey + "giftRequest.title"
            case 410:
                return rootKey + "friendAccept.title"
            case 450:
                return rootKey + "help.title"
            case 451:
                return rootKey + "helped.title"
            default:
                return rootKey + "default"
        }
    }

    transform() {
        // If we are still loading some user data, then show the
        // notifLoading template.
        if (!!this.state.pending) return <div className="nav-link nav-link-style dropdown-item notification-item-customized">
            <NotifLoading />
        </div>
        // Bear with me, this can be lengthy, use the editor collapse
        // to gain some clarity :) If you have a better way to do this, please
        // contib.  _//\\_
        switch (this.props.notificationType) {
            // Requests
            case "friendRequest":
                return <div className={(this.state.viewed === 0
                    ? "notification-item-unseen"
                    : "") + " notification-list-item"}>
                    <div className="row no-gutters">
                        <div className="col">
                            <div className="row no-gutters">
                                {/* Profile picture */}
                                <div className="col-2">
                                    <img src={this.state.pictureURL}
                                        alt="." style={{ width: "40px", height: '40px', border: "2px white solid" }} className="rounded-corners img-fluid" />
                                </div>
                                <div className="col-10">
                                    {/* Title */}
                                    <div className="row no-gutters">
                                        <div className="col" style={{ fontSize: '15px', left: '5px', top: '10px' }}>
                                            {LocUtils.T(this.getTitleKey(this.props.type), {
                                                name: "<b>" + this.state.fname + " " + this.state.lname + "</b>"
                                            })}
                                        </div>
                                    </div>
                                    {/* Actions */}
                                    {
                                        !!this.state.processing &&
                                        <div className="row no-gutters">
                                            <div className="col-4"></div>
                                            <div className="col">
                                                <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{ fontSize: "1rem" }}></i>
                                            </div>
                                            <div className="col-4"></div>
                                        </div>
                                    }
                                    {
                                        !this.state.processing &&
                                        <div className="row no-gutters">
                                            <div className="col" style={{ marginLeft: '-10px' }}>
                                                <Button onClick={() => {
                                                    this.setState({ processing: true }, () => {
                                                        ServiceLocator.SocialNetworkManager.People.Accept({
                                                            suggestedZid: this.props.fromZid,
                                                            gameId: 118
                                                        }, (resp) => {
                                                            if (resp.e !== 0) {
                                                                Toast.Show({
                                                                    message: LocUtils.TPure("toasts.something_went_wrong")
                                                                })
                                                                this.setState({ processing: false })
                                                            } else {
                                                                ServiceLocator.ZTrack.Cast({
                                                                    counter: "zdc_2",
                                                                    kingdom: "accept_friend",
                                                                    family: resp.isFbFrnd,
                                                                    phylum: this.props.fromZid,

                                                                });
                                                                Toast.Show({
                                                                    message: LocUtils.TPure("toasts.friend_added", {
                                                                        name: this.state.fname + " " + this.state.lname
                                                                    })
                                                                })
                                                                const { gid, id, msgtype } = this.props;
                                                                ServiceLocator.SocialNetworkManager.Me.Notifications.Seen({
                                                                    gid: gid,
                                                                    id: id,
                                                                    msgtype: msgtype,
                                                                    nid: ServiceLocator.SocialNetworkManager.SessionData().zid,
                                                                });
                                                                this.props.onDismiss(this.props.notificationType, this.props.id);
                                                            }
                                                        })
                                                    });
                                                }} extraClass="notification-buttons"
                                                    message={LocUtils.T("notification.types.friendRequest.accept")} />
                                            </div>
                                            <div className="col" style={{ marginLeft: '-100px', top: '8px' }}>
                                                <Button onClick={() => {
                                                    this.setState({ processing: true }, () => {
                                                        ServiceLocator.SocialNetworkManager.People.Reject({
                                                            rejectZid: this.props.fromZid,
                                                            gameId: 118
                                                        }, () => {
                                                            const { gid, id, msgtype } = this.props;
                                                            ServiceLocator.SocialNetworkManager.Me.Notifications.Seen({
                                                                gid: gid,
                                                                id: id,
                                                                msgtype: msgtype,
                                                                nid: ServiceLocator.SocialNetworkManager.SessionData().zid,
                                                            });
                                                            this.props.onDismiss(this.props.notificationType, this.props.id);
                                                        })
                                                    })
                                                }} extraClass="notification-buttons" severity="info"
                                                    message={LocUtils.T("notification.types.friendRequest.ignore")} />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-1" style={{ top: '5px' }}>
                            <button type="button" onClick={() => { this.props.onDismiss(this.props.notificationType, this.props.id); }} className="close login-modal-close-btn notification-close-override">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <hr className="addFriendHr" />
                </div>

            // general items
            case "general":
                return <div className={(this.state.viewed === 0
                    ? "notification-item-unseen"
                    : "") + " notification-list-item"}
                    style={{"fontSize": "1rem"}}>
                    <div className="row no-gutters">
                        <div className="col">
                            <div className="row no-gutters">
                                {/* Profile picture */}
                                <div className="col-2">
                                    <img src={this.state.pictureURL}
                                        alt="." style={{ width: "40px", height: '40px', border: "2px white solid" }} className="rounded-corners img-fluid" />
                                </div>
                                <div className="col-10">
                                    {/* Title */}
                                    <div className="row no-gutters">
                                        <div className="col">
                                            {LocUtils.T(this.getTitleKey(this.props.type), {
                                                name: "<b>" + this.state.fname + " " + this.state.lname + "</b>"
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" onClick={() => {
                                const { gid, id, msgtype } = this.props;
                                ServiceLocator.SocialNetworkManager.Me.Notifications.Seen({
                                    gid: gid,
                                    id: id,
                                    msgtype: msgtype,
                                    nid: ServiceLocator.SocialNetworkManager.SessionData().zid,
                                });
                                this.props.onDismiss(this.props.notificationType, this.props.id);
                            }} className="close login-modal-close-btn notification-close-override">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>

            default:
                super.error("Unknown notification type: ", this.state.type);
                return <div></div>;
        }
    }

    render() {
        return (
            <li>
                {
                    !this.state.selfDestruct &&
                    this.transform()
                }

            </li>
        )
    }
};

export default NotificationItem;