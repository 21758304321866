/**
 * EventManager.js
 * A pub-sub collection utility which can be used
 * game wide to communicate across components or services
 * in tricky situations. The usage of this module should
 * be restricted and instead the problem should be solved
 * the 'react'-ive way. However this is a support for odd ones.
 * Supports custom events a.w.a. standard events,
 * subscribe, unsubscribe, dispatch
 * @author Jaiwardhan Swarnakar
 */
import ZLogger from '../util/zlogger/zlogger'

/**
 * Queuing utility which can be used to synchronise jobs.
 * Wire() => Enqueues jobs with a context
 * Fire() => Executes the jobs in the queue
 */
var Queue = (() => {
    let queues = {}
    let Contexts = {
        EnvLoad: {
            name: "envLoad",
            recurring: false
        },
        FetchPublicFeed: {
            name: "fetchPublicFeed",
            recurring: false
        }
    }

    /**
     *
     * @param {Object} context Contains the metadata 'name' and 'recurring' for the job you want to enqueue.
     * Set 'recurring' to true if the job needs to be executed more than once.
     * @param {*} job Specify any method call
     */
    let Wire = (context = undefined, job = () => { }) => {
        if (!context) return
        context = (!!context && typeof (context) === 'object') ? context : {}
        if (!context["name"] || context["recurring"] === undefined) return

        // If this is a first time setup, lets enqueue things
        if (!queues[String(context.name)]) {
            queues[String(context.name)] = {
                jobs: [],
                recurring: context.recurring,
                fired: false
            }
        } else {
            // The queue already exists, check if its already fired.
            // If yes, fire the job immediately.
            if (!!queues[String(context.name)].fired) {
                job();
                return;
            }
        }
        // Enqueue the job, wait for fire
        queues[String(context.name)].jobs.push(job)
    }

    /**
     * Fire the events that are enqueued.
     * If its a non-recurring event and fire is called when the queue is empty, it ensures
     * all subsequent 'Wire' jobs will be triggered.
     *
     * @param {Object} context Metadata for job that needs to be executed.
     */
    let Fire = (context = undefined) => {
        if (!context) return
        context = (!!context && typeof (context) === 'object') ? context : {}
        if (!context["name"] || context["recurring"] === undefined) return

        // No Wire has been set up till now.
        if (!queues[String(context.name)]) {
            if (!context.recurring) {
                // Create and push in Queue if its not recurring
                queues[String(context.name)] = {
                    jobs: [],
                    recurring: context.recurring,
                    fired: true
                }
            }
        } else {
            let jobs = queues[String(context.name)].jobs;
            jobs.forEach(eachJob => {
                eachJob();
            });
            // Flush
            queues[String(context.name)].jobs = [];
            queues[String(context.name)].fired = !queues[String(context.name)].recurring
        }
    }
    return {
        Contexts: Contexts,
        Fire: Fire,
        Wire: Wire
    }
})();

var Events = (function () {

    var events = {}
    var EVENT_LIST = {
        LOGIN: {
            LOGGED_IN: "logged-in-user",
            LOGGED_OUT: "logged-out-user"
        },
        NETWORK: {
            LOAD_PENDING: "load-pending",
            LOAD_COMPLETE: "load-complete",
            LOAD_CLEARED: "load-cleared"
        },
        NOTIFICATION: {
            UPDATE: "notification-update"
        }
    }

    function Init() {
        ZLogger.announce("Initing EM");
    }

    function _subscriptionExists(eventName, callback) {
        var res = events[eventName] !== undefined && events[eventName].refs[callback] !== undefined;
        return res;
    }

    function subscribe(eventName, callback, force = false) {
        if (callback === undefined || typeof (callback) !== 'function') {
            ZLogger.error("Events:subscribe:: unable to subscribe with an unknown callback", eventName);
            return;
        }
        if (events[eventName] === undefined) {
            events[eventName] = { callbacks: [], refs: {} };
        }
        if (!force && _subscriptionExists(eventName, callback)) {
            ZLogger.error("Events:subscribe:: Already registered event under: " + eventName);
            return;
        }
        ZLogger.log("Events:subscribe:: subscribing: " + eventName);
        events[eventName].callbacks.push(callback);
        events[eventName].refs[callback] = events[eventName].callbacks.length - 1;
    }

    function unsubscribe(eventName, callback) {
        if (callback === undefined || typeof (callback) !== 'function') {
            ZLogger.error("Events:unsubscribe:: unable to unsubscribe to unknown callback", eventName);
            return;
        }
        if (events[eventName] === undefined) {
            return;
        }
        if (!_subscriptionExists(eventName, callback)) {
            ZLogger.error("Events:unsubscribe:: Callback not subscribed");
            return;
        }

        var refIndex = events[eventName].refs[callback];
        events[eventName].callbacks = events[eventName].callbacks.slice(0, refIndex).concat(events[eventName].callbacks.slice(refIndex + 1))
        delete events[eventName].refs[callback];
    }

    function dispatch(eventName, data) {
        if (events[eventName] === undefined) {
            return;
        }

        var dispatchList = events[eventName].callbacks;
        dispatchList.map(
            callback => callback(data)
        )
    }

    return {
        EVENT_LIST: EVENT_LIST,
        Init: Init,
        events: events,
        dispatch: dispatch,
        isSubscribed: _subscriptionExists,
        Queue: Queue,
        subscribe: subscribe,
        unsubscribe: unsubscribe,

    }
})();

Events.Init();
export default Events;