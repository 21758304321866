/**
 * notifications.js
 * Works in conjunction under Comms and syncs data that qualifies as a 
 * notification in a register. Components can use this method to fetch 
 * the notification pool by registering to the supported methods.
 * @author jswarnakar
 */
import ServiceLocator from '../../../../ServiceLocator';
import ZLogger from '../../../../util/zlogger/zlogger';

var Notifications = (function () {
    let processQueue = [];
    let notificationLedger = {}
    let updateRegister = {};
    let latestTs = 0; // The latest time stamp out of all notifications

    /**
     * Takes a list of notification Comms as sent by the server. Updates any new notification
     * into its queue which can be consumed by any component for processing. 
     * - Dispatches the update to any component that has registered with this service.
     * - Dispatches any 'unseen' update count via a global event.
     * @param {Object} data A set of comms data notifications sent by a server update from Comms.js
     */
    function Update(data) {
        if (data !== undefined) {
            data = Array.isArray(data) ? data : [data];
            let changed = false;
            let unread = 0;
            data.forEach(eachData => {
                if (notificationLedger[eachData.id] === undefined) {
                    // Put this in the front of the queue
                    processQueue.unshift(eachData);
                    notificationLedger[eachData.id] = true;
                    changed = true;
                    if (eachData.viewed === 0)++unread;
                    if (eachData.timestamp) latestTs = (latestTs < eachData.timestamp) ? eachData.timestamp : latestTs;
                }
            })
            if (!!changed) dispatchUpdate();
            ServiceLocator.Events.dispatch(
                ServiceLocator.Events.EVENT_LIST.NOTIFICATION.UPDATE,
                unread
            )
        }
    }

    function Get() {
        const currentQueue = processQueue;
        return currentQueue;
    }

    /**
     * Marks a notification as Seen/dismissed so that its not populated again.
     * @param {Object} params Should contain the notification id, gid and 
     * @param {fn} onFailureCallback When a failure is encountered on the server call
     */
    function Seen(params = {}, onFailureCallback = ZLogger.error) {
        params.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:connect_remove",
            payload: payload,
            token: "ZDC_NOTIF_SEEN"
        })
            .then(data => { ServiceLocator.SocialNetworkManager.Comms.Sync() })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Acknowledge to the server that notification bell was clicked and all
     * notifications were at least seen.
     * @param {fn} onFailureCallback When a failure occurs on the server call
     */
    function Acknowledge(onFailureCallback = ZLogger.error) {
        let params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
            t: latestTs,
            type: "n"   // To the api maker => WOW! What a param -__-
        };
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:connect_clearCount",
            payload: payload,
            token: "ZDC_NOTIF_BELL_CLICK"
        })
            .then(data => { return data.json() })
            .then(data => { return; })
            .catch(err => { onFailureCallback(err) })
    }

    function Checkout() {
        // TODO
    }

    async function dispatchUpdate() {
        Object.keys(updateRegister).forEach(registered => {
            try {
                updateRegister[registered](processQueue);
            } catch (e) {
                ZLogger.fatal("Unknown function or error occurred when dispatching for: ", String(registered));
            }
        })
    }

    /**
     * Registers any global function with its own update cycle. Updates are dispatched to
     * these registered functions when an update is ready.
     * @param {String} fnName A unique name you want to give to the registration
     * @param {fn} fn A function that should be executed corresponding to this registration
     */
    function UpdateRegister(fnName, fn) {
        if (!fnName || typeof (fn) !== "function") return;
        updateRegister[fnName] = fn; // 1-1 mapping helps us manage callbacks
    }

    /**
     * Unregister from the dispatch update of the update cycle.
     * @param {String} fnName Name of the registration that you would had done earlier
     */
    function UpdateUnregister(fnName = "") {
        if (updateRegister[fnName] !== undefined) delete updateRegister[fnName];
    }

    return {
        Acknowledge: Acknowledge,
        Get: Get,
        Seen: Seen,
        Checkout: Checkout,
        UpdateRegister: UpdateRegister,
        UpdateUnregister: UpdateUnregister,
        Update: Update
    }
})();

export default Notifications;