import Contexts from './managers/ContextManager'
import Events from './managers/EventManager'
import NetworkManager from './managers/NetworkManager'
import OptimizationManager from './managers/OptimizationManager'
import Auth from './managers/AuthManager'
import ZLogger from './util/zlogger/zlogger'
import Site from './site/SiteServices'
import Xing from './site/Xing'
import SocialNetworkManager from './managers/SocialNetworkManager'
import ZTrack from './managers/ZTrack/ZTrack'
import Env from './env/Env'
import PB from './managers/Feature/PermanentBenefitManager'

var ServiceLocator = (function () {
    function Init() {
        ZLogger.announce("Initializing service locator");
    }

    return {
        Auth: Auth,
        Contexts: Contexts,
        Env: Env,
        Events: Events,
        Init: Init,
        NetworkManager: NetworkManager,
        OptimizationManager: OptimizationManager,
        Site: Site,
        SocialNetworkManager: SocialNetworkManager,
        Xing: Xing,
        ZTrack: ZTrack,
        PB: PB
    }
})();

ServiceLocator.Init();
window.ServiceLocator = ServiceLocator;
export default ServiceLocator;