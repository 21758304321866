import ServiceLocator from "../../../ServiceLocator";
import ZLogger from "../../../util/zlogger/zlogger";

var Comms = (function () {

    /** Sync Interval */
    const commIntervalTimeMS = 120000;
    let commIntervalRef = undefined;

    /** Keeps track of the last request */
    let lastRequestData = {
        timestamp: { code: "t", value: 0 },
        requestHeight: { code: "h", value: 0 }
    }

    /** Keeps a mapping of category handlers to 
     * their filter and flushers 
     */
    let categoryHandlers = (() => {
        return {
            Notif: {
                filter: [],
                flush: ServiceLocator.SocialNetworkManager.Me.Notifications.Update
            },
            Request: {
                filter: [],
                flush: ServiceLocator.SocialNetworkManager.Me.Notifications.Update
            }
        }
    });

    function clearHandleFilters() {
        Object.keys(categoryHandlers).forEach(eachCategory => {
            categoryHandlers[eachCategory].filter.length = 0;
        })
    }

    function flushHandleFilters() {
        Object.keys(categoryHandlers).forEach(eachCategory => {
            categoryHandlers[eachCategory].flush(categoryHandlers[eachCategory].filter);
        })
    }

    /**
     * Initializes Comm intervals to periodically Sync
     */
    function Init() {
        if (commIntervalRef === undefined) {
            // To handle dep, mutate categoryHandlers to reassign and execute it
            categoryHandlers = categoryHandlers();
            // first trigger a sync and then set the interval
            ServiceLocator.SocialNetworkManager.Comms.Sync();
            commIntervalRef = setInterval(ServiceLocator.SocialNetworkManager.Comms.Sync, commIntervalTimeMS);
            ZLogger.announce("Comms => enabled..")
        } else {
            ZLogger.error("Comms:Init Attempted to init comms when already running another one. ( O_o )");
        }
    }

    /**
     * Obviously does what it means - this ain't a joke.
     */
    function Kill() {
        if (commIntervalRef !== undefined) {
            clearInterval(commIntervalRef);
            ZLogger.announce("Comms => disabled..")
        }
    }

    /**
     * Updates if ts passed is greater than the lastRequestData.timestamp.
     * Increases if ts passed is same as lastRequestData.timestamp
     * @param {int} ts Epoch seconds
     */
    function updateTs(ts) {
        if (ts > lastRequestData.timestamp.value) {
            lastRequestData.timestamp.value = ts;
            lastRequestData.requestHeight.value = 1;
        } else if (ts === lastRequestData.timestamp.value) {
            lastRequestData.requestHeight.value += 1;
        }
    }

    /**
     * Parses Sync() responses data.
     * Passes appropriate sectional data to respective handlers
     * Updates People.Dictionary with news guys.
     * @param {dict} payloadData A payload data received from the server containing comms
     * @param {fn} onFailureCallback Report to method when an error is encountered
     */
    function parse(payloadData, onFailureCallback = ZLogger.error) {
        if (payloadData !== undefined && payloadData.error === 0) {
            const result = payloadData.result;

            // Lets Start parsing people directory
            for (let eachPerson in result.users) {
                result.users[eachPerson]["zid"] = eachPerson; // Since zid is not part of the value block
                //This was happening on getUserAttribute call, after Prodsec 1017 fix we are handling friend request notification details here
                const profile = {};
                profile["firstName"] = result.users[eachPerson].fname;
                profile["lastName"] = result.users[eachPerson].lname;
                profile["profilePicThumb"] = result.users[eachPerson].profile_url;
                profile["zid"] = eachPerson;
                result.users[eachPerson]["profile"] = profile;
            }
            ServiceLocator.SocialNetworkManager.People.Directory.Identify(Object.values(result.users));

            // Start parsing comm and update ts accordingly
            let maxTs = -1;
            result.comms.forEach(eachComm => {
                if (!!categoryHandlers[eachComm.msgtype]) {
                    eachComm.timestamp = (eachComm.timestamp === undefined) ? maxTs : parseInt(eachComm.timestamp);
                    maxTs = parseInt(Math.max(maxTs, eachComm.timestamp));
                    categoryHandlers[eachComm.msgtype].filter.push(eachComm);
                } else {
                    // Ignore for now
                    ZLogger.warn("Comm:Parse ===> Unknown message type received: ", eachComm.msgtype);
                }
            })
            // Once done update ts with +1 for next cycle
            updateTs(maxTs + 1);
            // and flush the filters to their handlers for batch
            // processing at once (upon preference).
            flushHandleFilters();

        } else {
            onFailureCallback("Comm:Parse===> Found an error when requesting notifications");
        }
    }

    /**
     * Does the job of Syncing comms - such as Notifs from server.
     * Sends over the data to parse() for further processing.
     * @param {fn} onFailureCallback Report to method when an error is encountered
     */
    function Sync(onFailureCallback = ZLogger.error) {
        clearHandleFilters();

        var params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }

        if (lastRequestData.timestamp.value > 0) {
            params[lastRequestData.timestamp.code] = lastRequestData.timestamp.value;
            params[lastRequestData.requestHeight.code] = lastRequestData.requestHeight.value;
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:connect_get",
            payload: payload,
            token: "ZDC_COMM_SYNC"
        })
            .then(data => { return data.json() })
            .then(data => { return parse(data, onFailureCallback) })
            .catch(err => { onFailureCallback(err) })

    }

    return {
        Init: Init,
        Kill: Kill,
        Sync: Sync
    }
})();

export default Comms;