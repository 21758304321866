/**
 * facebookLogin.js
 * [Managed by LoginComponent]
 * [Housed in login tab]
 * Takes care of loggind users with facebook.
 * @author Juhi Sharma
 */
import React from 'react';
import BaseComponent from '../../BaseComponent';
import LocUtils from '../../../util/localization/LocUtils'
import '../../../../styles/generic.css'
import './Login.css'
import ServiceLocator from '../../../ServiceLocator';
import Button from '../buttons/Buttons';

class FacebookLogin extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            engaged: false
        };

        // Subscription to turn of the field editing 
        this.onEngageFinish = this.onEngageFinish.bind(this);
        if (!ServiceLocator.Events.isSubscribed("ZDC_ACCOUNT_REGISTER", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_ACCOUNT_REGISTER", this.onEngageFinish);
        if (!ServiceLocator.Events.isSubscribed("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish);

        this.loginFBHandler = props.loginFBHandler;
    }

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "ui",
            kingdom: "load",
            phylum: "signup"
        })
    }

    /** Subscription handler */
    onEngageFinish(evt) {
        this.setState({
            engaged: false
        })
    }

    componentWillUnmount() {
        ServiceLocator.Events.unsubscribe("ZDC_ACCOUNT_REGISTER", this.onEngageFinish);
        ServiceLocator.Events.unsubscribe("ZDC_FB_ACCOUNT_REGISTER", this.onEngageFinish);
    }

    render() {
        return (
            <div className="col-sm-12 col-12 col-md-6 col-lg-6" id="signup_fb_pane">
                <div id="signup-carousel-fb" className="carousel slide" data-keyboard="false" data-ride="carousel" data-interval="false">
                    <div className="carousel-inner carousel-min-pad">
                        <div className="carousel-item active">
                            <div className="signup-fb-content ml-2">
                                <div className="input-group mb-3">
                                    <p className="h4">{LocUtils.T("login.signup_fb_header")}</p>
                                </div>
                                <div className="input-group mb-3">
                                    <p className="h6">{LocUtils.T("login.signup_fb_desc")}</p>
                                </div>
                                <div className="input-group mb-3 text-center content-center">
                                    <Button
                                        onClick={() => {
                                            ServiceLocator.ZTrack.Cast({
                                                counter: "dialog",
                                                kingdom: "click",
                                                phylum: this.props.source,
                                                zClass: "fbconnect",
                                                family: "zdc_register",
                                                value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                                            });
                                            this.loginFBHandler();
                                        }}
                                        extraClass="h6"
                                        severity="link"
                                        message={LocUtils.T("login.signup_connect")}
                                    />
                                </div>
                                <div className="input-group mb-3 text-left" style={{ maxWidth: '95%' }}>
                                    <div className="invalid-tooltip error-message-container"
                                        style={{ "position": "static", "display": String(this.props.loginErrMsg).length ? "block" : "none" }}>
                                        {this.props.loginErrMsg}
                                    </div>
                                </div>
                                <br/>
                                <hr/>
                                <br/>
                                <div className="input-group mb-3">
                                    <p className="h6">{LocUtils.T("login.signup_button_header")}</p>
                                </div>
                                <div className="input-group mb-3 text-center content-center">
                                    <Button
                                        onClick={() => {
                                            ServiceLocator.ZTrack.Cast({
                                                counter: "dialog",
                                                kingdom: "click",
                                                phylum: "emailLogin",
                                                zClass: "signup",
                                                family: "zdc_register",
                                                value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                                            });
                                            this.props.parentSwitchTabHandle("signup")
                                        }}
                                        extraClass="form-signin-button"
                                        message={LocUtils.T("login.signup_confirm_deny")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default FacebookLogin;