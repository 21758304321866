/**
 * PaymentController.js
 * The payment adapter for Xing which helps convey payment protcols
 * to and from the payment frame.
 * @author: jswarnakar
 * @copyright Zynga Inc 2019
 */
import ServiceLocator from "../../../../ServiceLocator";
import ZLogger from "../../../../util/zlogger/zlogger";

/** The Payment controller NS */
var PaymentController = {};
/** Register a Zbillr NS so that future payment options have flexibility */
PaymentController.Zbillr = {};


PaymentController.Zbillr.Constants = (function () {
	return {
		UWG_TYPE_INGRESS_PAY_ZBILLR: "zbillr_pay_init",

		UWG_TYPE_INGRESS_PAY_SETTINGS: "zbillr_pay_settings",

		UWG_TYPE_EGRESS_PAY_ZBILLR: "zbillr_pay_resp",

		UWG_SENDER_EGRESS_FARM2: "FARM2",

		UWG_GAME_IFRAME_IDS: {
			FARM2: "farm2-game-frame"
		},

		UWG_LISTENER_XFRAME_MSG: "message"
	};
})();

PaymentController.Zbillr.Instance = (function () {
	var _rules = [
		{
			description: "A rule which initializes the zbillr init rule. Incoming Xing messages are checked for an init command to start this.",
			activated: false,
			handler: (d, original) => {
				var sender = original.sender;
				var checkoutURL = d.payURL;
				if (sender in PaymentController.Zbillr.Constants.UWG_GAME_IFRAME_IDS &&
					_that !== undefined && checkoutURL) {
					ZLogger.log("++ Updating modal data: ", checkoutURL);
					// Update the dialog components like iframe src, sender id etc.
					var currentState = _that.state;
					currentState.modals.pay.frameData = {
						frameURL: checkoutURL,
						sender: sender,
						referrerId: PaymentController.Zbillr.Constants.UWG_GAME_IFRAME_IDS[sender]
					}
					_that.setState(currentState, _that.openSelf);
				}
			},
			passes: [
				ServiceLocator.Xing.XingChecker.Validate,
				// Is zbillr init
				(d) => {
					return d !== undefined && d.type === PaymentController.Zbillr.Constants.UWG_TYPE_INGRESS_PAY_ZBILLR
				}
			],
			name: "for_pay_zbillr_init"
		},
		{
			description: "A rule which listens to the zbillr checkout dialog iframe messages. Checkout dialog resizing happens based on these messages.",
			activated: false,
			handler: (d, original) => {
				if (d.resizeIframe && d.resizeIframe.h !== undefined && d.resizeIframe.w !== undefined) {
					_that.resizeModal(d.resizeIframe);
				}
			},
			passes: [
				// Check if this a resize event from zynga.com
				(d) => {
					return d !== undefined && d.data !== undefined && d.sourceOrigin &&
						d.sourceOrigin.indexOf("zynga.com") >= 0;
				}
			],
			name: "pay_zbillr_resize"
		},
		{
			description: "A rule which listens to a 'close' call of the checkout dialog. Generally a dialog is closed on user trigger or on a txn status.",
			activated: false,
			handler: (d, original) => {
				var thatReferrerId = _that.state.modals.pay.frameData.referrerId;
				// Close data and flush states
				_that.closeSelf();
				_that.resetSelf();
				// Tell Xing to dump this data to that frame
				ServiceLocator.Xing.Send(
					thatReferrerId,
					PaymentController.Zbillr.Constants.UWG_TYPE_EGRESS_PAY_ZBILLR,
					{},
					original
				)
			},
			passes: [
				// Check if this is payComplete type of event.
				(d) => {
					return d !== undefined && d.data !== undefined && d.sourceOrigin &&
						d.sourceOrigin.indexOf("zynga.com") >= 0 && d.data.payComplete !== undefined;
				}
			],
			name: "pay_zbillr_status_close"
		},
		{
			description: "A rule which listens to a 'close' call of the checkout dialog. Generally a dialog is closed on user trigger or on a txn status.",
			activated: false,
			handler: (d, original) => {
				var thatReferrerId = _that.state.modals.pay.frameData.referrerId;
				ZLogger.log("XING DEBUG: Completing payment for: " + thatReferrerId, d);
				// Tell Xing to dump this data to that frame
				ServiceLocator.Xing.Send(
					thatReferrerId,
					PaymentController.Zbillr.Constants.UWG_TYPE_EGRESS_PAY_ZBILLR,
					{
						"type": "resolve",
						"resolve": "success",
						"data": d.payComplete
					}
				)
			},
			passes: [
				// Check if this is payComplete type of event.
				(d) => {
					return d !== undefined && d.data !== undefined && d.sourceOrigin &&
						d.sourceOrigin.indexOf("zynga.com") >= 0 && d.data.payComplete !== undefined &&
						d.data.payComplete.status !== undefined && d.data.payComplete.status === "settled";
				}
			],
			name: "pay_zbillr_status_setlled"
		},
		{
			description: "A rule which listens to the Payment settings option which should trigger a dialog with user's saved cards information",
			activated: false,
			handler: (d, original) => {
				var settingsURL = d.settingsURL;
				var settingsSessionId = d.settingsSessionId;
				if (settingsURL && settingsSessionId) {
					ZLogger.log("++ Updating Settings modal data: ", settingsURL, settingsSessionId);
					// Update the dialog components like iframe src, sender id etc.
					var currentState = _that.state;
					currentState.modals.paySettings.frameData = {
						frameURL: settingsURL,
						sessionId: settingsSessionId
					}
					_that.setState(currentState, _that.openPaymentSettings);
				}
			},
			passes: [
				// Check if this is a settings type option or not
				(d) => {
					return d !== undefined && d.data !== undefined && d.type === PaymentController.Zbillr.Constants.UWG_TYPE_INGRESS_PAY_SETTINGS
				}
			],
			name: "for_pay_zbillr_settings"
		}
	];

	/** 
	 * The reference of the component using this controller.
	 * Protocol actions can be taken on the component based on a condition.
	 */
	var _that = undefined;

	function _registerRules() {
		ServiceLocator.Xing.RegisterRules(_rules);
	}

	function _activateRules() {
		ServiceLocator.Xing.ActivateRules(_rules);
	}

	// Exposed method to the Close (x) button on the checkout dialog
	// PS: This is called on a manual trigger
	function ExternalClose() {
		// since we need to mimic a cancel resp, hence use this to signal Xing to cancel purchase
		var closeSignal = { "data": { "payComplete": { 
			"error_code": "1000", "error_message": "User Closed/Canceled" } 
		}, "sourceOrigin": "https://secure1.zynga.com", "targetOrigin": "*", "sourceWindowName": "paymentFrame", "targetWindowName": "" };
		ServiceLocator.Xing.Send(
			_that.state.modals.pay.frameData.referrerId,
			PaymentController.Zbillr.Constants.UWG_TYPE_EGRESS_PAY_ZBILLR,
			{},
			closeSignal
		);
	}

	function Init({ that }) {
		if (that) {
			_that = that;
			_registerRules();
			_activateRules();
		}
	}

	return {
		Init: Init,
		ExternalClose: ExternalClose
	};
})();

export default PaymentController;
