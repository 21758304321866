/**
 * Loading.js
 * A temporary component that can be used as a filler
 * while another component is being dynamically downloaded
 * or being process.
 * @author Jaiwardhan Swarnakar
 */
import React from "react";

const Loading = props => {
  if (props.error) {
    return <div>Error!</div>;
  } else {
    return <div><img src={process.env.REACT_APP_ASSET_PATH + "/zdog4-anim.gif"}
      alt="." style={{display: "block", margin: "auto", width: "10vw"}} /></div>;
  }
};

export default Loading;