/**
 * Permanent Benefit HUD
 * @author Rutvi Thakkar
 */

import React from 'react';
import $ from 'jquery';
import './HUD.css'
import BaseComponent from "../../BaseComponent";
import ServiceLocator from '../../../ServiceLocator';
import DataStore from '../../../data/DataStore';
import LocUtils from '../../../util/localization/LocUtils'
import PermanentBenefitController from './helpers/PermanentBenefitController'
import RewardClaimFTUE from './FTUE/RewardClaimFTUE';

class HUD extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            didGameLoad: false,
            seconds: 0,
            time: {}
        }
        this.timer = 0;
        this.getInfoText = this.getInfoText.bind(this);
        this.nextRewardTimer = this.nextRewardTimer.bind(this);
        this.secondsToTime = this.secondsToTime.bind(this);
        this.countDown = this.countDown.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.getTimerDiv = this.getTimerDiv.bind(this);
        this.hideGameFrame = this.hideGameFrame.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));
        if (hours < 10)
            hours = "0" + hours;

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        if (minutes < 10)
            minutes = "0" + minutes;

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if (seconds < 10)
            seconds = "0" + seconds;

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        PermanentBenefitController.Instance.Init({
            that: this
        });

        let countdownTimeInSecs = ServiceLocator.Site.TimeLeftUntilEOD();
        let countdownTime = this.secondsToTime(countdownTimeInSecs);
        this.setState({
            time: countdownTime,
            seconds: countdownTimeInSecs
        })
    }

    getInfoText() {
        let text = "";
        text = ServiceLocator.PB.EligibleForReward()
            ? this.state.didGameLoad ? LocUtils.T("feature.hud.state.1") : LocUtils.T("feature.hud.state.3")
            : this.nextRewardTimer();
        return text;
    }

    nextRewardTimer() {
        if (ServiceLocator.PB.FeatureExpired()) {
            return LocUtils.T("feature.hud.state.4");
        }

        return LocUtils.T("feature.hud.state.2");
    }

    getTimerDiv() {
        return LocUtils.T("feature.hud.dailyTimer", {
            hours: this.state.time.h,
            mins: this.state.time.m,
            sec: this.state.time.s,
        })
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
        }
    }

    startTimer() {
        this.timer = setInterval(this.countDown, 1000);
    }

    hideGameFrame(){
        ServiceLocator.Xing.Send(
            "farm2-game-frame",
            "UWG_HIDE_GAME",
            {}
        );
    }

    render() {
        const eligibleForReward = ServiceLocator.PB.EligibleForReward();
        const featureTimer = ServiceLocator.PB.FeatureTimer();
        const infoText = this.getInfoText();
        const rewardAlreadyClaimed = !eligibleForReward && !ServiceLocator.PB.FeatureExpired();
        let ftueStep2 = DataStore.LocalStorage.Get("ftue2Step")

        if (this.timer === 0 && this.state.seconds > 0) {
            this.startTimer();
        }

        return (
            <>
                <div className="col" style={{ marginLeft: '10px' }}>
                    <div className="reward-timer-text">
                        <span> {featureTimer}</span>
                    </div>
                    <div className="row hud-container">
                        <div className="row hud-contents" style={{ marginLeft: '5px', backgroundSize: '300px', backgroundImage: " url('" + process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_permenetbenefit_hud_icon.png')" }}>
                            <div className="col-6" style={{ paddingRight: '3px' }}>
                                <div className="claim-reward-text">
                                    {infoText}
                                </div>
                                {rewardAlreadyClaimed && <div className="highlighted-timer">
                                    {this.getTimerDiv()}
                                </div>}
                            </div>
                            <div className="col-6" style={{ marginTop: '18px', paddingLeft: '0px', paddingRight: '0px' }}>
                                <div className="row no-gutters">
                                    <div className={"col-2 col-xs-4 col-sm-4 col-md-4 " + (this.state.didGameLoad && eligibleForReward ? "gift-box-shake" : "")}
                                        style={{ zIndex: '100' }}>
                                        <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_permenetbenefit_hud_giftbox.png"}
                                            style={{ width: '50px', height: "50px", position: 'relative', verticalAlign: 'middle', marginLeft: '4px', marginTop: '4px' }} />
                                    </div>
                                    <div className="col-10 col-xs-8 col-sm-8 col-md-8">
                                        <button className="btn button-green button-green-text claim-button"
                                            disabled={this.state.didGameLoad ? !eligibleForReward : !this.state.didGameLoad}
                                            onClick={() => {
                                                ServiceLocator.ZTrack.Cast({
                                                    counter: "zdc_perm_benefit",
                                                    kingdom: 'buttton_click',
                                                    phylum: 'reward_redeem'
                                                });
                                                this.hideGameFrame();
                                                $("#rewardModal").modal('show');
                                            }}>
                                            {LocUtils.T("feature.hud.cta")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ margin: "30px", width: "55px", height: "auto" }}>
                    <button className="btn fa-gift" style={{ cursor: "pointer" }}
                        onClick={() => {
                            ServiceLocator.ZTrack.Cast({
                                counter: "zdc_perm_benefit",
                                kingdom: 'button_click',
                                phylum: 'help_icon'
                            });
                            // depending on variant
                            if (this.props.hudUIVariant === 1) {
                                ServiceLocator.ZTrack.Cast({
                                    counter: "zdc_perm_benefit",
                                    kingdom: 'open',
                                    phylum: 'help_dialog',
                                    zClass: "v1"
                                });
                                this.hideGameFrame();
                                $("#variant1Modal").trigger('getFeatureTimer').modal('show');
                            } else {
                                ServiceLocator.ZTrack.Cast({
                                    counter: "zdc_perm_benefit",
                                    kingdom: 'open',
                                    phylum: 'help_dialog',
                                    zClass: "v2"
                                });
                                this.hideGameFrame();
                                $("#variant2Modal").trigger('getFeatureTimer').modal('show');
                            }
                        }}>
                        <Tag featureData={this.props.featureData} />
                        <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_permenetbenefit_hud_giftbox.png"}
                            className="gift-box-icon" />

                    </button>
                </div>
                
                {this.props.featureData.daysPassed === 0 && this.state.didGameLoad && ftueStep2 !== "1" &&
                    <RewardClaimFTUE />}
            </>
        )
    }
}

class Tag extends BaseComponent {
    render() {
        return (
            <>
                <img alt="" src={process.env.REACT_APP_ASSET_PATH + "/dialogs/permanentBenefits/zdc_permenetbenefit_giftboxtag.png"}
                    className="tag-icon" />

                <div className="tag-text">
                    {this.props.featureData.daysPassed === 0 ? LocUtils.T("feature.hud.new") : LocUtils.T("feature.hud.claim")}
                </div>
            </>
        )
    }
}

export default HUD;