import ServiceLocator from "../../ServiceLocator";
import ZLogger from "../../util/zlogger/zlogger";

var ZyngaAuthSession = (function () {

    function DeferredConnect(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            method: 'deferredConnect',
            fbid: data.fbId,
            fbsession: data.fbSessionToken,
            csrf: data.csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_registerloggedin",
            payload: payload,
            token: "DEFERRED_CONNECT"
        })
            .then((res) => { return res.json() })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    /**
     *  Change account type from 1 to 2
     * account type 1 means account was created via facebook, and fbid is stored
     * account type 2 means we are storing email and password also for same account
     * Next time user can login via facebook or email and password login
     */ 
    function ChangeAccountType(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if( !(data && data.username && data.password) ) {
            // Looks like the form is incomplete
            onFailureCallback("Form data is incomplete: ", data);
            return;
        }
        var params = {
            method: 'changeAccountToTypeTwo',
            email: data.username,
            password: data.password,
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_registerloggedin",
            payload: payload,
            token: "CHANGE_ACCOUNT_TYPE"
        })
            .then((res) => { return res.json() })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    function GenerateGDPRPin(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        ZLogger.log("trying to fetch gdpr data with: ", data);
        var params = {
            csrf: data.csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:gdpr_getGDPRPin",
            payload: payload,
            token: "GENERATE_GDPR_PIN"
        })
            .then((res) => { return res.json() })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    function GetAccountInfo(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            method: 'getAccountInfo',
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept' : "application/json"
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_registerloggedin",
            payload: payload,
            token: "GET_ACCOUNT_INFO"
        })
            .then((res) => { 
                return res.json() 
            })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    /**
     * Get a user's privacy settings and related options from his account.
     */
    function GetPrivacyInfo(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            csrf: data.csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_getPrivacyInfo",
            payload: payload,
            token: "PRIVACY_GET"
        })
            .then((res) => { return res.json() })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    /**
     * Checks whether a game or an app has been installed for a player.
     * data payload schema: 
     * {
     *      gid: "118",  => the game id
     *      method: "appInstalled|*", => if appInstalled then checks for app else for game
     *      csrf: "" => the session csrf token
     * }
     * PS: The API only works with Farmville2
     */
    function HasInstalled(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (!data || !data.csrf || !data.gname) {
            return onFailureCallback("hasInstalledGame: Invalid data parameters");
        }
        var params = {
            zpipe: 1,
            csrf: data.csrf
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        try {
            ServiceLocator.NetworkManager.Fetch.Call({
                url: process.env.REACT_APP_AUTH_ISSUETOKEN + '/page/' + ((new Date()).getTime()) + '/play/' + data.gname,
                payload: payload,
                token: "ZDC_HAS_INSTALLED_GAME_OR_APP"
            })
                .then(res => { return res.text() })
                .then(respText => {
                    // The response string is mostly of the format:
                    // {....}====={....}=====<some html>=====some_more_data
                    // Which is not json parsable, hence we split and check for
                    // our favorable case.
                    let splits = respText.split("=====");
                    for (let each_split = 0; each_split < splits.length; ++each_split) {
                        try {
                            var o = JSON.parse(splits[each_split]);

                            // Handle non-exception-throwing cases:
                            // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                            // but... JSON.parse(null) returns null, and typeof null === "object", 
                            // so we must check for that, too. Thankfully, null is falsey, so this suffices:
                            if (o && typeof o === "object") {
                                if (o.payload && o.payload.gameboardInfo && o.payload.gameboardInfo.offerConnect !== undefined) {
                                    onCompleteCallback(o.payload.gameboardInfo.offerConnect);
                                    return;
                                }
                            }
                        }
                        catch (e) { }
                    }
                    onFailureCallback("hasInstalled: gameboardInfo.offerConnect not found!");
                    return false;

                })
                .catch(err => { onFailureCallback(err) })
        } catch (e) {
            onFailureCallback("hasInstalled: exception when checking for game already played!");
        }
    }

    /**
	 * Does a Logout from a loggedin state
	 * @param {The context in which the login was done} context 
	 * Caution: This might not work on localhost
	 * possibly due to a facebook bug.
	 */
    function Logout(data, onCompleteCallback, onFailureCallback) {
        onCompleteCallback = (onCompleteCallback === undefined) ? (() => { }) : onCompleteCallback;
        onFailureCallback = (onFailureCallback === undefined) ? (() => { }) : onFailureCallback;
        if (!data || !data.csrf) { onFailureCallback("Logout: Undefined data block") }
        var logoutParams = {
            "csrf": data.csrf
        }

        var logoutPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(logoutParams)
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:session_logout",
            payload: logoutPayload,
            token: "ZDC_LOGOUT"
        }).then((d) => {
            onCompleteCallback(d);
        }).catch(err => {
            onFailureCallback(err);
        })
    }

    /**
     * Gets a payments session id and redirect URL which would contain payment
     * settings related to the player.
     * data payload schema: 
     * {
     *      csrf: xxxxxxxxxxxxxxxx //...the session csrf token
     * }
     */
    function PaymentSettings(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            csrf: data.csrf
        };
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_user_getPaymentsInfo",
            payload: payload,
            token: "ZDC_PAY_SETTINGS"
        })
            .then(data => { return data.json() })
            .then(data => { onCompleteCallback(data) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * When a logged in user accepts the TOS
     */
    function TOSAccept(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        if (!data || !data.csrf) {
            onFailureCallback("Empty Data without session");
            return;
        }

        var tosParams = {
            csrf: data.csrf
        }

        var tosPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(tosParams)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:gdpr_tosAccepted",
            payload: tosPayload,
            token: "ZDC_TOS_ACCEPT"
        })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Verifies user identity.
     * Should get you the following on success:
     * --> csrf         (the one you would had got in the last login)
     * --> zid          (the associated zid)
     */
    function Verify(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var verificationParams = {
            method: "verify"
        }
        var verificationPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(verificationParams)
        };
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_issueToken",
            payload: verificationPayload,
            token: "ZDC_VERIFY"
        })
            .then(data => { return data.json() })
            .then(data => { onCompleteCallback(data) })
            .catch(err => { onFailureCallback(err) })
    }


    function VerifyEmail(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        //var url = (window.location.href.split("sendkey"))[0];
        var verificationParams = {
            method: "verifyUser",
            email: data.email,
            token: data.token,
            userid: data.userId,
        }
        var verificationPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(verificationParams)
        };
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_verify",
            payload: verificationPayload,
            token: "ZDC_VERIFY_USER"
        })
            .then(data => { return data.json() })
            .then(data => { onCompleteCallback(data) })
            .catch(err => { onFailureCallback(err) })
    }

    return {
        DeferredConnect: DeferredConnect,
        GenerateGDPRPin: GenerateGDPRPin,
        GetPrivacyInfo: GetPrivacyInfo,
        HasInstalled: HasInstalled,
        Logout: Logout,
        PaymentSettings: PaymentSettings,
        TOSAccept: TOSAccept,
        Verify: Verify,
        ChangeAccountType: ChangeAccountType,
        GetAccountInfo: GetAccountInfo,
        VerifyEmail: VerifyEmail
    }
})();

export default ZyngaAuthSession;