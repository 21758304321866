/**
 * FacebookButton.js
 * Extension of buttons lib specifically with facebook
 * standard logo and text. Supports round mode.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react';
import { Link } from "react-router-dom";
import './Buttons.css'
import Button from './Buttons';
import BaseComponent from '../../BaseComponent';

function getButtonClass(severity, extraClass, forceClass) {
    let options = {
        "default": " btn button-facebook button-green-text ",
        "round": "fb-round"
    }
    severity = !severity || !(severity in options)
        ? "default"
        : severity;
    let result = options[severity];
    if (extraClass) {
        result = result + " " + extraClass;
    }
    if (forceClass) {
        result = forceClass;
    }
    return result;
}

class GenericButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div type="button" onClick={this.props.onClick} className={getButtonClass(
                this.props.severity,
                this.props.extraClass,
                this.props.forceClass
            )} name={this.props.name ? this.props.name : ""}
                data-toggle={this.props.toggle ? this.props.toggle : ""}
                data-target={this.props.target ? this.props.target : ""}>
                <div className="row no-gutters">
                    <div className="col">
                        <img alt="facebook-play"
                            className="button-logo"
                            src={process.env.REACT_APP_ASSET_PATH + "/trademarks/facebook/logos/transparent/facebook.png"} />
                        <span className="button-text-white">
                            {this.props.message}
                        </span>
                    </div>
                </div>

            </div>
        )
    }
}

class LinkButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
        if (!this.props.to) {
            super.fatal("Unable to render Link without a 'to' property");
        }
        this.onClick = this.props.onClick ? this.props.onClick : () => { };
    }

    render() {
        return (
            <Link
                className={getButtonClass(
                    this.props.severity,
                    this.props.extraClass,
                    this.props.forceClass
                )}
                to={this.props.to}
                onClick={this.onClick}
            >
                <div className="row no-gutters">
                    <div className="col">
                        <img alt="facebook-play" className="button-logo"
                            src={process.env.REACT_APP_ASSET_PATH + "/trademarks/facebook/logos/transparent/facebook.png"} />
                        <span className="button-text-white">
                            {this.props.message}
                        </span>
                    </div>
                </div>
            </Link>
        )
    }
}

class RoundButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div class="button-facebook-round" onClick={this.props.onClick}>
                <img alt="f" src={process.env.REACT_APP_ASSET_PATH + "/trademarks/facebook/logos/white/PNG/f_logo_RGB-White_58.png"} />
                <span>{this.props.message}</span>
            </div>
        )
    }
}

/**
 * Generic button class that can behave as base <a> or
 * a react <Link> component based on passed props.
 * Input schema:
 * @param {String}  type        link|*  renders as a <Link> or <a>
 * @param {String}  severity    default|info|action renders the appropriate button style
 * @param {String} extraClass   *   Space separated classes to apply
 * @param {String} forceClass   *   Space separated classes to override all button.css classes
 * @returns {Button}
 */
class FacebookButton extends Button {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const buttonDetect = (bProps) => {
            if (bProps.type === "link") {
                return <LinkButton {...this.props} {...bProps} />
            } else if (bProps.type === "round") {
                return <RoundButton {...this.props} {...bProps} />
            }

            return <GenericButton {...this.props} {...bProps} />
        }
        return (
            buttonDetect(this.props)
        )
    }
}

export default FacebookButton;