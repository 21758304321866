/**
 * GenericCache.js
 * An in memory registry that can be used to store data
 * to be used in this live session. Doesn;t write to LS
 * or indexDB etc. 
 * [Warning] Contents of this cache would be volatile
 * @author: jswarnakar
 * @copyright Zynga Inc 2019
 */
var GenericCache = (function () {

    var _registry = {};

    function _keyCheck(key) {
        return key && key.length > 0;
    }

    function Exists(key) {
        if (!_keyCheck(key)) { return false }
        return _keyCheck[key] !== undefined;
    }

    function Get(key) {
        if (!_keyCheck(key)) {
            return {}
        }
        if (!GenericCache.Exists(key)) _keyCheck[key] = {};
        return _keyCheck[key];
    }

    function Set(key, obj, force = true) {
        if (!_keyCheck(key)) return;
        if (!force && _registry[key]) { return }
        _registry[key] = obj;
    }

    return {
        Exists: Exists,
        Get: Get,
        Set: Set
    }
})();

export default GenericCache;