/**
 * Beta survey dialog.
 * To be removed later
 * @author jswarnakar
 */
import React from 'react';
import $ from 'jquery';
import BaseComponent from "../../BaseComponent";

import './BetaSurvey.css'
import Button from '../buttons/Buttons';
import ZLogger from '../../../util/zlogger/zlogger';
import DataStore from '../../../data/DataStore';
import LocUtils from '../../../util/localization/LocUtils';
import ServiceLocator from '../../../ServiceLocator';
class BetaSurvey extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            currentPageAnswer: "",
            textBoxSelected: false,
            totalPages: 4,   // But actually we have 5
            maxlen: 250,
            answerFilledOnCurrentPage: false,
        }
        this.crossClose = this.crossClose.bind(this);

        this.radioSelect = this.radioSelect.bind(this);
        this.textBoxSelect = this.textBoxSelect.bind(this);
        this.textBoxTextChange = this.textBoxTextChange.bind(this);
    }

    pageActions = {
        next: () => {
            const pageValue = this.state.currentPage;
            const pageAnswer = this.state.currentPageAnswer;
            let surveryData = JSON.stringify({
                "counter": "beta-survey",
                "kingdom": "opt-out",
                "phylum": "answer",
                "family": String(ServiceLocator.SocialNetworkManager.SessionData().zid), // If not logged in this would be 0
                "zClass": String(pageValue),
                "genus": String(pageAnswer),
            });
            ZLogger.dump(surveryData);

            this.setState({
                currentPageAnswer: "",
                textBoxSelected: false,
                answerFilledOnCurrentPage: false,
                currentPage: this.state.currentPage + 1
            }, () => {
                $("#beta-carousel").carousel('next');
            })
        }
    }

    SetCookie(name, value) {
        document.cookie = name + '=' + value + '; Path=/;';
    }

    DeleteCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    componentDidMount() {
        try {
            if (window.localStorage) {
                window.localStorage.setItem("UWG_LROITS", (new Date()).getTime());
            }
            this.DeleteCookie("UWG_SET");
            $("#surveyContainer").modal('show');
            const device = (DataStore && DataStore.Platform) ? JSON.stringify(DataStore.Platform.Fetch()) : "{}";
            ServiceLocator.ZTrack.Cast({
                counter: "ui",
                kingdom: "load",
                phylum: "beta-survey",
                family: String(ServiceLocator.SocialNetworkManager.SessionData().zid), // If not logged in this would be 0
                zClass: "device",
                genus: device
            });
        } catch (e) {
            ZLogger.error("Unable to load device data ", e);
        }
    }

    crossClose() {
        ServiceLocator.ZTrack.Cast({
            counter: "click",
            kingdom: "beta-survey",
            phylum: "cross-close"
        })
        const currentPage = this.state.currentPage;
        const currentPageAnswer = this.state.currentPageAnswer;
        if (currentPageAnswer && String(currentPageAnswer).length > 0) {
            let surveryData = JSON.stringify({
                "counter": "beta-survey",
                "kingdom": "opt-out",
                "phylum": "answer",
                "family": String(ServiceLocator.SocialNetworkManager.SessionData().zid), // If not logged in this would be 0
                "zClass": String(currentPage),
                "genus": String(currentPageAnswer),
            });
            ZLogger.dump(surveryData);
        }
        this.reloadExit();
    }

    radioSelect(ans) {
        this.setState({
            currentPageAnswer: ans,
            answerFilledOnCurrentPage: true,
            textBoxSelected: false
        })
    }

    textBoxSelect() {
        this.setState({
            currentPageAnswer: "",
            answerFilledOnCurrentPage: false,
            textBoxSelected: true
        })
    }

    textBoxTextChange(e) {
        console.log(e.target.value);

        this.setState({
            currentPageAnswer: String(e.target.value),
            answerFilledOnCurrentPage: true,
            textBoxSelected: true
        })
    }

    reloadExit() {
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    render() {

        return (
            <div>
                <div className="modal fade" id="surveyContainer" data-keyboard="false" data-backdrop="static" tabIndex="1" role="dialog" aria-labelledby="surveyContainer" aria-hidden="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-common-props" role="document">
                        <div className="modal-content" style={{ border: "none" }}>
                            <div className="modal-body survey-dialog">
                                {/* <script>{getSurveyBetaExit(window, document, "script", "smcx-sdk")}</script> */}
                                {/* The (X) close button */}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.crossClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <div className="row">
                                    <div className="col">
                                        <h3>{LocUtils.T("dialogs.beta_survey.header")}</h3>
                                        <h5><i>{LocUtils.T("dialogs.beta_survey.desc")}</i></h5>
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                                                aria-valuenow={String(parseInt((this.state.currentPage / this.state.totalPages) * 100))} aria-valuemin="0" aria-valuemax="100" style={{ width: String(parseInt((this.state.currentPage / this.state.totalPages) * 100)) + "%" }}></div>
                                        </div>
                                    </div>
                                </div>


                                <div id="beta-carousel" className="carousel slide" data-keyboard="false" data-ride="carousel" data-interval="false">
                                    <div className="carousel-inner">

                                        {/* Survey question 1 */}
                                        <div className="carousel-item active">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>{LocUtils.T("dialogs.beta_survey.q1.question")}</h4>
                                                    <div className="row no-gutters">
                                                        <div className="col">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-a")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-a"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-a")}>{LocUtils.T("dialogs.beta_survey.q1.a1")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-b")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-b"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-b")}>{LocUtils.T("dialogs.beta_survey.q1.a2")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-c")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-c"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-c")}>{LocUtils.T("dialogs.beta_survey.q1.a3")}</i>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="betaSurveyTextarea1">{LocUtils.T("dialogs.beta_survey.q1.a4")}</label>
                                                                <textarea maxLength={this.state.maxlen} className="form-control" id="betaSurveyTextarea1" rows="3"
                                                                    onClick={this.textBoxSelect}
                                                                    onChange={this.textBoxTextChange} value={this.state.textBoxSelected ? this.state.currentPageAnswer : ""} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Survey question 2 */}
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>{LocUtils.T("dialogs.beta_survey.q2.question")}</h4>
                                                    <div className="row no-gutters">
                                                        <div className="col">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-a")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-a"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-a")}>{LocUtils.T("dialogs.beta_survey.q2.a1")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-b")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-b"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-b")}>{LocUtils.T("dialogs.beta_survey.q2.a2")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-c")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-c"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-c")}>{LocUtils.T("dialogs.beta_survey.q2.a3")}</i>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="betaSurveyTextarea2">{LocUtils.T("dialogs.beta_survey.q2.a4")}</label>
                                                                <textarea maxLength={this.state.maxlen} className="form-control" id="betaSurveyTextarea2" rows="3"
                                                                    onClick={this.textBoxSelect}
                                                                    onChange={this.textBoxTextChange} value={this.state.textBoxSelected ? this.state.currentPageAnswer : ""} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Survey question 3 */}
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>{LocUtils.T("dialogs.beta_survey.q3.question")}</h4>
                                                    <div className="row no-gutters">
                                                        <div className="col">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-a")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-a"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-a")}>{LocUtils.T("dialogs.beta_survey.q3.a1")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-b")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-b"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-b")}>{LocUtils.T("dialogs.beta_survey.q3.a2")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-c")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-c"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-c")}>{LocUtils.T("dialogs.beta_survey.q3.a3")}</i>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-text survey-input-hole" onClick={() => this.radioSelect("r-d")}>
                                                                    {
                                                                        this.state.currentPageAnswer === "r-d"
                                                                            ? <i className="fa fa-circle" aria-hidden="true"></i>
                                                                            : <i className="fa fa-circle-o" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <i className="survey-radio-options" onClick={() => this.radioSelect("r-d")}>{LocUtils.T("dialogs.beta_survey.q3.a4")}</i>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="betaSurveyTextarea3">{LocUtils.T("dialogs.beta_survey.q3.a5")}</label>
                                                                <textarea maxLength={this.state.maxlen} className="form-control" id="betaSurveyTextarea3" rows="3"
                                                                    onClick={this.textBoxSelect}
                                                                    onChange={this.textBoxTextChange} value={this.state.textBoxSelected ? this.state.currentPageAnswer : ""} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Survey question 4 - open suggestions */}
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>{LocUtils.T("dialogs.beta_survey.q4.question")}</h4>
                                                    <div className="row no-gutters">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <textarea maxLength={this.state.maxlen} className="form-control" id="betaSurveyTextarea4" rows="3"
                                                                    onClick={this.textBoxSelect}
                                                                    onChange={this.textBoxTextChange} value={this.state.textBoxSelected ? this.state.currentPageAnswer : ""} >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Survey final slide - thanks */}
                                        <div className="carousel-item">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>{LocUtils.T("dialogs.beta_survey.ty.head")}</h4>
                                                    <h5><i>{LocUtils.T("dialogs.beta_survey.ty.desc")}</i></h5>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                {/* Bottom button controller */}
                                <div className="row no-gutters">
                                    <div className="col" style={{ textAlign: "right", marginTop: "2rem" }}>
                                        {
                                            this.state.currentPage === this.state.totalPages - 1 &&
                                            <Button message={LocUtils.T("dialogs.beta_survey.nav.skip")}
                                                onClick={this.pageActions.next} />
                                        }
                                        {
                                            (
                                                true
                                            ) &&
                                            <Button
                                                severity={
                                                    this.state.currentPage === this.state.totalPages
                                                        ? "default"
                                                        :
                                                        this.state.answerFilledOnCurrentPage
                                                            ? "default"
                                                            : "info"
                                                }
                                                message={this.state.currentPage === this.state.totalPages
                                                    ? LocUtils.T("dialogs.beta_survey.nav.end")
                                                    : LocUtils.T("dialogs.beta_survey.nav.next")
                                                }
                                                onClick={
                                                    this.state.currentPage === this.state.totalPages
                                                        ? this.reloadExit
                                                        :
                                                        this.state.answerFilledOnCurrentPage
                                                            ? this.pageActions.next
                                                            : () => { }}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BetaSurvey;