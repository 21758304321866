/**
 * Splunk.js
 * A module to dump client logs to splunk indexer on the server.
 * Essentially only used by the central logging service, ZLogger.
 * @author jswarnakar/
 */
import ServiceLocator from "../../ServiceLocator";

/**
 * Meow
 * Contains various splunk params that can be used as constants
 * for better readability
 */
var Cat = (function () {

    return {
        LOG_LEVEL: {
            ERROR: "error",
            WARN: "warning",
            FATAL: "fatal",
            NOTICE: "notice"
        },
        LOG_SECTION: {
            DEFAULT: "default"
        }
    }
})();

/**
 * Zynga's splunk wrapper.
 */
var Splunk = (function () {

    /**
     * dumps the collected data to the server for indexing
     * @param {String} msg A string containing the complete message to be dumped
     * @param {fn} onCompleteCallback Optionally to execute once this service call works fine
     * @param {fn} onFailureCallback Optionally to execute if service call fails
     */
    async function dumper(msg, onCompleteCallback = () => { }, onFailureCallback = () => { console.error("Error when dumping logs") }) {
        const params = {
            "data[]": msg,
            "csrf": ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        const payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/logger",
            payload: payload,
            token: "SPLUNK-" + (new Date()).getTime() + "-" + msg.length + "-" + Math.random()
        })
            .then(res => { return res.json() })
            .then(parsed => {
                if (parsed && parsed.success) {
                    onCompleteCallback(parsed);
                } else {
                    onFailureCallback("Something went wrong with splunk dump");
                }
            })
            .catch(err => { onFailureCallback(err) })
    }

    function getISOTime() {
        if (Date.prototype.toISOString) {
            return (new Date()).toISOString();
        }
        return false;
    }

    /**
     * Dump some data to Splunk for error indexing. Values can be a mixed combination as mentioned under
     * params below. Log length > 60000 is truncated for optimization.
     * @todo: Can be batched even if length goes above 60000.
     * @param {String} level Logging level. Values as available in Cat.LOGGING_LEVEL
     * @param {string} logSection The section to which this log belongs as available in Cat.LOGGING_SECTION
     * @param  {...any} params A set of params that need to be logged. Mixed contents: String|Object|Error|Numbers supported
     */
    function Dump(level = Cat.LOG_LEVEL.ERROR, logSection = Cat.LOG_SECTION.DEFAULT, ...params) {
        try {
            let total = [
                getISOTime(),
                "zid=" + ServiceLocator.SocialNetworkManager.SessionData().zid,
                "level=" + level,
                "tag=" + logSection,
            ];
            let msgTotal = "";
            params.forEach(p => {
                let stringConverted = "";
                if (p instanceof Error && p.message) {
                    stringConverted = (stringConverted + " [Error] " + p.message).trim();
                } else if (typeof p === "object") {
                    try {
                        stringConverted = (stringConverted + " " + JSON.stringify(p)).trim();
                    } catch (je) {
                        stringConverted = (stringConverted + " [JSON Serialize error: " + je.message + "]").trim();
                    }
                } else {
                    stringConverted = (stringConverted + " " + String(p)).trim();
                }

                // Truncate string length
                if ((msgTotal + " " + stringConverted).length > 60000) {
                    stringConverted = stringConverted.slice(0, ((total.length + 1 + stringConverted.length) - 60000))
                }
                msgTotal = msgTotal + " " + stringConverted;
            });
            total.push("msg=" + msgTotal.trim());
            dumper(total.join(" "));

        } catch (error) {
            console.log("Encountered error when attempting to dump logs to splunk.")
        }
    }

    return {
        Cat: Cat,
        Dump: Dump
    }
})();

export default Splunk;