import Notifications from "./Notifications/notifications";
import Profile from "./Profile/Profile";
import ServiceLocator from "../../../ServiceLocator";
import ZLogger from "../../../util/zlogger/zlogger";
import DataStore from '../../../data/DataStore';
import GenericUtils from '../../../util/GenericUtils';

var Identity = (function () {
    /**
     * Retrieves ZID if it is stored in Local storage and compares with ZID in the url,
     * If local storage zid is not present, treat them as a new player and assign a random 8 digit ID
     */
    function getDeviceID() {
        let result = -1;
        let zidFromLS = -1;

        if (DataStore.LocalStorage.Has("DEVICE_ID")) {
            zidFromLS = parseInt(DataStore.LocalStorage.Get("DEVICE_ID"));
        }

        let urlZidValue = getZidFromUrl();

        if (zidFromLS !== -1 && !isNaN(zidFromLS)) {
            // Local storage contains Zid
            if (zidFromLS !== urlZidValue && urlZidValue !== -1) {
                // Fire zid change stat, maps FB zid and random Zid
                ServiceLocator.ZTrack.Cast({
                    counter: "zdc_2",
                    phylum: "zid_change",
                    family: urlZidValue,
                    value: zidFromLS
                });
                result = urlZidValue;
            } else {
                result = zidFromLS;
            }
        } else {
            // If zid value doesnt exist in LS, get from URL or generate and assign a random 8 digit ID
            if (urlZidValue !== -1) {
                result = urlZidValue;
            } else {
                var randomID = GenericUtils.generateRandomNum(10000000, 99999999);
                result = randomID;
            }
        }

        updateZidInLS(result);
        return result;
    }

    function updateZidInLS(value) {
        DataStore.LocalStorage.Store("DEVICE_ID", value);
    }

    // Retrieves ZID from URL parameters
    function getZidFromUrl() {
        let params = ServiceLocator.NetworkManager.Utils.ParseQueryParameters();
        const hasZid = !!params["zid"];
        let zidValue = -1;
        if (hasZid) {
            zidValue = String(params["zid"])
        }
        return parseInt(zidValue);
    }

    return {
        getDeviceID: getDeviceID
    }
})();


var Me = (function () {

    /**
     * Get the user's social graph, which includes profile attributes of
     * his connected social network.
     * Returns a dict containing 'mappings' of zid to profile attributes
     * {
     *      e: 0,
     *      mappings: {
     *          36498853859: {....}
     *      }
     * }
     */
    function GetGraph(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var graphParams = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }
        var graphPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(graphParams)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:getGraph",
            payload: graphPayload,
            token: "USER_GRAPH"
        })
            .then((res) => { return res.json() })
            .then((res) => { onCompleteCallback(res) })
            .catch((err) => { onFailureCallback(err) })
    }

    return {
        GetGraph: GetGraph,
        Notifications: Notifications,
        Profile: Profile,
        Identity: Identity
    }
})();

export default Me;