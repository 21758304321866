/**
 * DataStore.js
 * A collection of in memory data storage managment interfaces.
 * Things like localstorage or in memory data can be manipulated
 * with the below interfaces.
 * This is a servicelocator equivalent for data storage.
 * @author Jaiwardhan Swarnakar
 */
import AuthData from './Auth/AuthData'
import GenericCache from './Generic/GenericCache'
import Platform from './Platform/Platform'
import LocalStorage from './LocalStorage/LocalStorage'
import ZLogger from '../util/zlogger/zlogger'

var DataStore = (function() {
    function Init() {
        ZLogger.announce("Initing Data Stores");
    }
    return {
        AuthData        : AuthData,
        GenericCache    : GenericCache,
        Init            : Init,
        LocalStorage    : LocalStorage,
        Platform        : Platform
    }
})();

DataStore.Init();
window.DataStore = DataStore;
export default DataStore;