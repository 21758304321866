/**
 * NotificationBell.js
 * A react component that gets you a notification bell :bell:
 * 
 */
import React from 'react';
import BaseComponent from '../../BaseComponent';
import $ from 'jquery';
import ServiceLocator from '../../../ServiceLocator';
import LocUtils from '../../../util/localization/LocUtils';
import NotificationItem from './NotificationItem';

class NotificationBell extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            numberNotifications: 0,
            notificationData: [],
            bifurcation: {
                friendRequests: {
                    key: "",
                    type: "friendRequest",
                    notifications: []
                },
                general: {
                    key: "",
                    type: "general",
                    notifications: []
                },
                others: {
                    key: "",
                    type: "others",
                    notifications: []
                },
                ledger: {}
            },
        };
        this.updateNotificationCount = this.updateNotificationCount.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);

        this.onClickNotificationBell = this.onClickNotificationBell.bind(this);
        this.onClickDropdownClose = this.onClickDropdownClose.bind(this);
        this.onClickDropdownCollapsibleTabs = this.onClickDropdownCollapsibleTabs.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount() {
        if (!ServiceLocator.Events.isSubscribed(ServiceLocator.Events.EVENT_LIST.NOTIFICATION.UPDATE, this.updateNotificationCount)) {
            ServiceLocator.Events.subscribe(ServiceLocator.Events.EVENT_LIST.NOTIFICATION.UPDATE, this.updateNotificationCount);
        }
        ServiceLocator.SocialNetworkManager.Me.Notifications.UpdateRegister("notification-bell", this.updateNotifications);
        this.updateNotifications(ServiceLocator.SocialNetworkManager.Me.Notifications.Get());
    }

    componentWillUnmount() {
        ServiceLocator.Events.unsubscribe(ServiceLocator.Events.EVENT_LIST.NOTIFICATION.UPDATE, this.updateNotificationCount);
        ServiceLocator.SocialNetworkManager.Me.Notifications.UpdateUnregister("notification-bell");
        // Close drop-down on document click
        $(document).off('click', '#dropdownOverlay', this.onClickDropdownClose)
    }

    /**
     * Closes the notification popup
     * @todo TODO: This doesn't seem to work possibly coz of the missing components.
     * @param {event} e When any other document section is clicked
     */
    onClickDropdownClose(e) {
        var el = $(e.currentTarget)[0].activeElement;

        if (typeof $(el).attr('data-dropdown') === 'undefined') {
            $('#dropdownOverlay').remove();
            $('.dropdown-container.expanded').removeClass('expanded');
        }
    }

    /**
     * Further toggles the collapsable notification section
     * @param {event} e When any collapsable notification section is clicked
     */
    onClickDropdownCollapsibleTabs(e) {
        if ($(e.currentTarget).parent().hasClass('expanded')) {
            $('.notification-group').removeClass('expanded');
        }
        else {
            $('.notification-group').removeClass('expanded');
            $(e.currentTarget).parent().toggleClass('expanded');
        }
    }

    /**
     * Updates the count on the bell. This is updated via the update event
     * as subscribed above. In case the subscription is "late", then it should
     * still get update on the next cycle check.
     * @param {int} unread The number of unread notifications (viewed = 0)
     */
    updateNotificationCount(unread) {
        let newCount = this.state.numberNotifications + parseInt(unread);
        this.setState({ numberNotifications: newCount })
    }

    /**
     * When clicking on the notification bell, we would want to mark
     * all the 'unseen' notifications data as 'seen'.
     */
    onClickNotificationBell(e) {
        this.props.setHideWelcome();
        var container = $(e.currentTarget).parent();
        if (container.hasClass("expanded")) {
            $(document).off('click', '#dropdownOverlay', this.onClickDropdownClose);
            $('#dropdownOverlay').remove();
            // $('.dropdown-container.expanded').removeClass('expanded');
        } else {
            // $('body').prepend('<div id="dropdownOverlay"' +
            //     ' style="    background-color: rgba(0,0,0,0.5); z-index: 5; height:100%; width:100%; position:fixed;"></div>');
            let dropdown = container.find('.dropdown');
            let containerWidth = container.width();
            dropdown.css({
                'right': containerWidth / 2 + 'px'
            })
            ServiceLocator.SocialNetworkManager.Me.Notifications.Acknowledge();
            this.setState({ numberNotifications: 0 });
            $(document).on('click', '#dropdownOverlay', this.onClickDropdownClose);

            // Track the notification bell interaction
            ServiceLocator.ZTrack.Cast({
                counter: "click",
                kingdom: "nav",
                phylum: "bell"
            })
        }
        container.toggleClass('expanded');
    }

    updateNotifications(data) {
        this.setState({ notificationData: data }, () => {
            let bifurcation = this.state.bifurcation;
            data.forEach(eachNotification => {
                if (eachNotification && eachNotification.id && bifurcation.ledger[eachNotification.id] === undefined) {
                    bifurcation.ledger[eachNotification.id] = true;
                    switch (eachNotification.type) {
                        case 301:
                            bifurcation.friendRequests.notifications.push(eachNotification);
                            break;
                        case 410:
                            bifurcation.general.notifications.push(eachNotification);
                            break;
                        default:
                            bifurcation.others.notifications.push(eachNotification);
                    }
                }
            })
            this.setState({ bifurcation: bifurcation });
            if (bifurcation.others.notifications.length > 0) {
                super.error("Looks like we have unknown notifications as well: ", bifurcation.others.notifications);
            }
        })
    }

    onDismiss(notificationType = "", notificationId = "") {
        let bifurcation = this.state.bifurcation;
        let part = undefined;
        Object.keys(bifurcation).forEach(k => {
            if (bifurcation[k].type === notificationType) {
                part = k;
            }
        })
        if (!part) return; // Looks like nothing needs to be done
        let foundIndex = -1;
        bifurcation[part].notifications.map((n, idx) => {
            if (n.id === notificationId) foundIndex = idx;
            return null;
        })
        if (foundIndex >= 0 && foundIndex < bifurcation[part].notifications.length) {
            bifurcation[part].notifications = bifurcation[part].notifications.filter(function (value, index, arr) {
                return index !== foundIndex;
            });
            this.setState({ bifurcation: bifurcation })
        }
    }

    render() {

        // When no notifications are present, show em this. 
        const allCaughtUp = () => {
            return <div className="nav-link nav-link-style dropdown-item">
                <span className="menu-wrap-text-styles">{LocUtils.T("notification.none")}&nbsp;<i className="fa fa-flag-checkered" aria-hidden="true"></i></span></div>

        }

        return (
            <div >
                {
                    this.props.loggedin === true &&
                    <div className={"dropdown-container fs-inherit"}
                        style={{ display: 'inline-block' }} >
                        {/* The drowdown icon for the bell. Also shows a number if the
                        number of fresh notifications are > 0. */}
                        <div className={"menu-link has-notifications circle btn " + (this.props.extraClass ? this.props.extraClass : "")}
                            data-dropdown="notificationMenu"
                            id="notificationDropdownLink"
                            onClick={this.onClickNotificationBell} >
                            <i className={"div-button fa " + (this.props.customIcon ? this.props.customIcon : "fa-bell") + " notification-bell"} aria-hidden="true" ></i>
                            <i className={(this.state.numberNotifications > 0 ? "visible" : "invisible") + " div-button small-font badge badge-danger notification-number"}>
                                {this.state.numberNotifications}
                            </i>
                        </div>
                        {/* A list of things to be rendered inside the notification dropdown.
                            \__Category-1
                                \__notification-1
                                \__notification-2
                            \__Category-2
                                \__notification-1
                                \__notification-2
                        */}
                        <ul
                            name="notificationMenu"
                            className=" dropdown "
                            aria-labelledby="notificationDropdownLink" style={{ marginTop: "1rem", zIndex: '100', right: '200px' }} >
                            {/* Category - Friend Requests */}
                            <li className="notification-group">
                                <div className="notification-tab" onClick={this.onClickDropdownCollapsibleTabs}>
                                    <i className="fa fa-user-plus"></i>
                                    <span className="h5">{LocUtils.T("notification.sections.friendRequests.title")}</span>
                                    <span className="label">{this.state.bifurcation.friendRequests.notifications.length}</span>
                                </div>
                                <ul className="notification-list">
                                    {
                                        this.state.bifurcation.friendRequests.notifications.length === 0
                                        && allCaughtUp()
                                    }
                                    {
                                        this.state.bifurcation.friendRequests.notifications.map(i => {
                                            return <NotificationItem
                                                notificationType="friendRequest"
                                                onDismiss={this.onDismiss}
                                                key={i.id}
                                                {...i} />
                                        })
                                    }
                                </ul>
                            </li>
                            {/* Category - General (accepts, helps etc.) */}
                            <li className="notification-group">
                                <div className="notification-tab" onClick={this.onClickDropdownCollapsibleTabs}>
                                    <i className="fa fa-flag"></i>
                                    <span className="h5">{LocUtils.T("notification.sections.general.title")}</span>
                                    <span className="label">{this.state.bifurcation.general.notifications.length}</span>
                                </div>
                                <ul className="notification-list">
                                    {
                                        this.state.bifurcation.general.notifications.length === 0
                                        && allCaughtUp()
                                    }
                                    {
                                        this.state.bifurcation.general.notifications.length > 0
                                        && this.state.bifurcation.general.notifications.map(i => {
                                            return <NotificationItem
                                                notificationType="general"
                                                onDismiss={this.onDismiss}
                                                key={i.id}
                                                {...i} />
                                        })
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

export default NotificationBell;