/**
 * AuthManager.js
 * Maintains a collection of differnt auth interfaces
 * under it. This further granularizes methods to their
 * respective boundaries.
 * @author Jaiwardhan Swarnakar
 */
import Fb from './Auth/FacebookAuth'
import Zynga from './Auth/ZyngaAuth'
import Game from './Auth/GameAuth'

/**
 * Interface for various Auth methods integrated
 */
var Auth = (function() {
    return {
        Fb      : Fb,
        Game    : Game,
        Zynga   : Zynga
    }
})();

export default Auth;