/**
 * UnauthenticateRoute.js
 * Components on this route are mostly open and dont
 * require authentication. 
 * Supports redirects in the URL (such as redirect after
 * login complete)
 * @author Jaiwardhan Swarnakar
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import ServiceLocator from "../../ServiceLocator";
import ScrollTop from "../minions/scrollTop/ScrollTop";

export default ({ component: C, props: cProps, ...rest }) => {
  const getMethod = (props) => {
    let params = ServiceLocator.NetworkManager.Utils.ParseQueryParameters()
    const hasRedirect = !!params["redirect"];
    let redirectValue = "";
    if (hasRedirect) {
      redirectValue = String(params["redirect"])
    }
    delete params["redirect"]
    let restParams = ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
    if(restParams)
    if (redirectValue.indexOf("?") >= 0) {
      restParams = "&" + restParams
    } else {
      restParams = "?" + restParams
    }
    if (!hasRedirect) {
      return <ScrollTop>
        <C {...props} {...cProps} />
      </ScrollTop>
    } else {
      return <ScrollTop>
        <Route {...rest}
          render={props =>
            !cProps.loggedin
              ? <C {...props} {...cProps} />
              : (<Redirect to={redirectValue + restParams} />)
          }
        />
      </ScrollTop>
    }
  }
  return (
    getMethod()
  );
};