/**
 * Toast.js
 * A utlity to fire a toaster with appropriate message
 * and severity. 
 * --> Supports themes which can be dynamically imported
 * and loaded on the fly.
 * --> supports change in position, timeout
 * --> Supports various severity modes such as ERROR, SUCCESS etc.
 * @author Jaiwardhan Swarnakar
 */
import Noty from 'noty';
import ZLogger from '../zlogger/zlogger'
import "../../../../node_modules/noty/lib/noty.css";
import LocUtils from '../localization/LocUtils';

var Toast = (function () {

    var _loader_cache = {};

    var POSITIONS = {
        DEFAULT: "bottomLeft",
        TOP: "top",
        TOP_L: "topLeft",
        TOP_R: "topRight",
        TOP_C: "topCenter",
        CENTER: "center",
        CENTER_L: "centerLeft",
        CENTER_R: "centerRight",
        BOT: "bottom",
        BOT_L: "bottomLeft",
        BOT_R: "bottomRight",
        BOT_C: "bottomCenter"
    }

    var SEVERITY_CODES = {
        DEFAULT: "info",
        ALERT: "alert",
        ERROR: "error",
        INFO: "info",
        SUCCESS: "success",
        WARNING: "warning"
    }

    /** 
     * A set of themes. If a theme is not present in the loader cache
     */
    var THEMES = {
        RELAX: {
            value: "relax",
            loader: () => { return import("../../../../node_modules/noty/lib/themes/relax.css" /* webpackChunkName: "TOAST_THEME_RELAX" */) }
        },
        MINT: {
            value: "mint",
            loader: () => { return import("../../../../node_modules/noty/lib/themes/mint.css" /* webpackChunkName: "TOAST_THEME_MINT" */) }
        },
        METRO: {
            value: "metroui",
            loader: () => { return import("../../../../node_modules/noty/lib/themes/metroui.css" /* webpackChunkName: "TOAST_THEME_METRO" */) }
        },
        BS4: {
            value: "bootstrap-v4",
            loader: () => { return import("../../../../node_modules/noty/lib/themes/bootstrap-v4.css" /* webpackChunkName: "TOAST_THEME_BS4" */) }
        },
        SUNSET: {
            value: "sunset",
            loader: () => { return import("../../../../node_modules/noty/lib/themes/sunset.css" /* webpackChunkName: "TOAST_THEME_SUNSET" */) }
        }
    }
    var _THEME_DEFAULT = THEMES.MINT;

    /** 
     * A sticky timeout sticks to the page unless
     * interacted by a click on the toast
     */
    var TIMEOUTS = {
        STICKY: false,
        DEFAULT: 5000
    }

    function _loader_cache_contains(theme) {
        return theme in _loader_cache;
    }

    function _loader_cache_store(key) {
        _loader_cache[key] = true;
    }

    function _show({ message, severity, position, theme, timeout }) {
        new Noty({
            layout: position,
            progressBar: true,
            text: message,
            timeout: timeout,
            theme: theme,
            type: severity
        }).show();
    }

    /**
     * Fires a toast message. Requires:
     * --> message
     * --> severity     (optional)
     * --> position     (optional) 
     * --> theme        (optional)
     * --> timeout      (optional)
     */
    function Show({ message, severity, position, theme, timeout }) {
        if (!message || message.length === 0) {
            ZLogger.error("Toast:Show:: Illegal value passed to show toaster: ", message);
            return
        }
        // Don't show toasts if we are not ready yet
        if(!LocUtils.IsInitialized()) return;
        severity = severity ? severity : SEVERITY_CODES.DEFAULT;
        position = position ? position : POSITIONS.DEFAULT;
        theme = theme ? theme : _THEME_DEFAULT;
        timeout = timeout && (
            typeof (timeout) === "number" ||
            (typeof (timeout) === "boolean" &&
                timeout === TIMEOUTS.STICKY)
        ) ? timeout : TIMEOUTS.DEFAULT;

        if (_loader_cache_contains(theme.value)) {
            _show({
                message: message,
                position: position,
                theme: theme.value,
                timeout: timeout,
                severity: severity
            });
        } else {
            theme.loader().then(data => {
                _loader_cache_store(theme.value);
                _show({
                    message: message,
                    position: position,
                    theme: theme.value,
                    timeout: timeout,
                    severity: severity
                })
            })
        }

    }

    return {
        POSITIONS: POSITIONS,
        SEVERITY_CODES: SEVERITY_CODES,
        THEMES: THEMES,
        TIMEOUTS: TIMEOUTS,
        Show: Show
    }
})();

export default Toast;