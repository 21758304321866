/**
 * /index.js
 * Top level loader class.
 * @author: jswarnakar
 * @copyright Zynga Inc 2019
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"

/** External lib imports */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';

import ServiceLocator from './js/ServiceLocator'
import DataStore from './js/data/DataStore'

/** Components */
import BaseComponent from './js/components/BaseComponent'
import NavComponent from './js/components/minions/nav/NavComponent'
import SiteTour from './js/components/minions/nav/SiteTour'
import LoginComponent from './js/components/minions/login/LoginComponent'
import Footer from './js/components/minions/footer/footer'
import LocUtils from './js/util/localization/LocUtils'
import ZLogger from './js/util/zlogger/zlogger'
import Toast from './js/util/toasts/Toast'
/** local styles */
import './styles/index.css';
import Content from './js/components/Content';
import PaymentComponent from './js/components/minions/payment/PaymentComponent';
import GdprComponent from './js/components/minions/gdpr/GdprComponent';
import BetaSurvey from './js/components/minions/betaSurvey/BetaSurvey';
import ScrollHelper from './js/components/minions/scrollHelper/ScrollHelper';
import WelcomeComponent from './js/components/minions/welcome/WelcomeComponent';
import SiteConstants from './js/util/SiteConstants';
import RewardInfoDialog from './js/components/minions/permanentBenefit/RewardInfoDialog';
import RewardPopup from './js/components/minions/permanentBenefit/RewardPopup';
import EmailVerification from './js/components/minions/login/EmailVerification';
import VerifyEmail from "./js/components/minions/login/VerifyEmail";
import VerifyEmailOnSignUp from "./js/components/minions/login/VerifyEmailOnSignUp";

// ================================================================ CONDITIONALS
if (process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ADMIN_CONSOLE === 'allowed'
) {
    import("./js/env/dev/admin");
}
// ================================================================

class Page extends BaseComponent {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            hudVariant: 0,
            featureData: {},
            featureActive: false,
            locLoaded: false,
            locCode: undefined,
            loggedin: undefined,
            alreadyLoggedin: undefined,
            loginFetching: false,
            data: {},
            context: undefined,
            needsTOS: true,
            zFriends: {},
            fbLoaded: false,
            optedOut: false,
            accountType: 999,
            fbLoginClicked: false,
            cookieEmail: "",
            isZDCMigrationModule2: false,
            isZDCExpCouponActive:false,
            invalidCredentials: false,
            hideWelcome: false,
            friendProfiles: [],
            source: 0, // 1 => login header, 2=> login popup
            loginType: 0, // 0 => never logged in before, 1 => logged in via facebook, 2 => logged in via email and password
            loginErrMsg: "",
            needs_gdpr_verification: false
        }
        this.syncStatus = {
            requiredForLogin: {
                "userAttributes": false,
                "zFriends": false,
                "eosLoad": false
            }
        }
        DataStore.Platform.Fetch();

        this.loginSync = this.loginSync.bind(this);

        /** Bind auth updaters */
        this.onAuthStatusChange = this.onAuthStatusChange.bind(this);
        this.onAutoAuthFetch = this.onAutoAuthFetch.bind(this);

        /** Bind the handlers */
        this.onLocLoaded = this.onLocLoaded.bind(this);
        this.onLocChange = this.onLocChange.bind(this);
        this.onLocChangeFromProfile = this.onLocChangeFromProfile.bind(this);
        this.loginFBHandler = this.loginFBHandler.bind(this);
        this.loginAuthHandler = this.loginAuthHandler.bind(this);
        this.signupAuthHandler = this.signupAuthHandler.bind(this);
        this.logoutHandler = this.logoutHandler.bind(this);
        this.onFBLoginComplete = this.onFBLoginComplete.bind(this);
        this.onZyngaLogin = this.onZyngaLogin.bind(this);
        this.forgotPasswordHandler = this.forgotPasswordHandler.bind(this);
        this.fbConnectHandler = this.fbConnectHandler.bind(this);
        this.settingsPaymentHandler = this.settingsPaymentHandler.bind(this);

        this.tryZyngaVerification = this.tryZyngaVerification.bind(this);
        this.getZyngaUserAttributes = this.getZyngaUserAttributes.bind(this);
        this.reloadZyngaUserAttributes = this.reloadZyngaUserAttributes.bind(this);
        this.getZDCFriends = this.getZDCFriends.bind(this);
        this.getEOSExperiments = this.getEOSExperiments.bind(this);
        this.getUserGraph = this.getUserGraph.bind(this);
        this.checkLocaleNeed = this.checkLocaleNeed.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.getLoginType = this.getLoginType.bind(this);
        this.getZDCMigrationModule2ActiveStatus = this.getZDCMigrationModule2ActiveStatus.bind(this);
        this.getZDCExpCouponCardActiveStatus = this.getZDCExpCouponCardActiveStatus.bind(this);
        this.getLoginEmail = this.getLoginEmail.bind(this);
        this.onFbLoginClick = this.onFbLoginClick.bind(this);
        this.resetFbLoginClick = this.resetFbLoginClick.bind(this);
        this.resetInvalidCreds = this.resetInvalidCreds.bind(this);
        this.setHideWelcome = this.setHideWelcome.bind(this);
        this.setTosSeen = this.setTosSeen.bind(this);

        this.loadFBWithoutStatus = this.loadFBWithoutStatus.bind(this);
        this.fbLoaded = this.fbLoaded.bind(this);

        this.onOptOutClick = this.onOptOutClick.bind(this);
        this.onOptOutClose = this.onOptOutClose.bind(this);

        this.getLoginStateType = this.getLoginStateType.bind(this);

        this.initPermanentBenefit = this.initPermanentBenefit.bind(this);
        this.onRewardClaimed = this.onRewardClaimed.bind(this);
       // this.onEmailVerificationCheck = this.onEmailVerificationCheck.bind(this);


        // ================================================================ DEV TOOLS
        // Set this accessibility for development env for translators
        // enabling admin tools like hot reloads.
        if (process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_ADMIN_CONSOLE === 'allowed'
        ) {
            window.onLocLoaded = this.onLocLoaded;
        }
        // ================================================================
    }

    /* ---------------------------------- Lifecycle METHODS --------------------------------- */
    componentDidMount() {
        if (window.needs_tos !== undefined && window.needs_tos === true) {
            this.setState({
                needsTOS: true
            })
        }
        // Let fb sdk load without checking in the login status
        setTimeout(() => { this.loadFBWithoutStatus() }, 300);
        this.tryZyngaVerification();

        // Init locs
        LocUtils.Init(this.onLocLoaded);

        // Stat for page loaded
        ServiceLocator.ZTrack.Cast({
            counter: "load"
        })

        ServiceLocator.OptimizationManager.GoogleOptimize.Initialize();
        var type = this.getLoginType();
        var email = this.getLoginEmail();
        var isZDCMigrationModule2Active = this.getZDCMigrationModule2ActiveStatus();
        var isZDCExpCouponCardActive = this.getZDCExpCouponCardActiveStatus();
        this.setState({
            loginType: type,
            cookieEmail: email,
            isZDCMigrationModule2: isZDCMigrationModule2Active,
            isZDCExpCouponActive: isZDCExpCouponCardActive
        });
    }

    /**
     * On 'loggedin' state change (either players comes logged in/ logged out to the site), trigger landing page stat.
     * Adding a check to avoid firing the stat twice when user logs in and page refreshes.
     *
     * @param {*} prevProps
     * @param {*} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if ((prevState.loggedin === undefined && this.state.loggedin !== prevState.loggedin)) {
            ServiceLocator.Events.Queue.Wire(ServiceLocator.Events.Queue.Contexts.EnvLoad, () => {
                ServiceLocator.ZTrack.Cast({
                    counter: "zdc_2",
                    kingdom: "land",
                    phylum: ServiceLocator.Env.PageMaps.GetFriendlyName("Farm2", ServiceLocator.NetworkManager.Utils.GetUrlPath()),
                    zClass: this.getLoginStateType(),
                    genus: ServiceLocator.NetworkManager.Utils.GetSourceFromUrl(),
                    family: ServiceLocator.NetworkManager.Utils.GetCampaignFromUrl(),
                    value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                });
            })
        }
    }

    /**
     * If player is logged out, populate the login dialog names (defaultLogin/fbLogin/emailLogin).
     * Otherwise, populate to indicate player has logged in.
     */
    getLoginStateType() {
        if (this.state.loggedin) {
            return SiteConstants.LoginStateType.LOGGED_IN;
        }

        let dialogname = SiteConstants.LoginStateType.DEFAULT_LOGIN;
        if (this.state.loginType === "1") {
            dialogname = SiteConstants.LoginStateType.FB_LOGIN;
        } else if (this.state.loginType === "2") {
            dialogname = SiteConstants.LoginStateType.EMAIL_LOGIN;
        }
        return dialogname;
    }

    onFbLoginClick() {
        this.setState({
            fbLoginClicked: true
        });
    }

    resetFbLoginClick() {
        this.setState({
            fbLoginClicked: false
        });
    }

    resetInvalidCreds() {
        this.setState({
            invalidCredentials: false,
            source: 0
        });
    }

    setHideWelcome() {
        this.setState({
            hideWelcome: true
        });
    }
    setTosSeen() {
        this.setState({
            needs_gdpr_verification: false
        });
    }

    getLoginEmail() {
        var cookie = this.getCookie("zdc_last_email");
        var data = unescape(cookie);
        if (data.length > 0) {
            var parsedData = JSON.parse(data);
            if (parsedData.hasOwnProperty("em")) {
                return parsedData.em;
            }
            return "";
        }
        return "";
    }

    getLoginType() {
        var cookie = this.getCookie("zdc_login_type_new");
        var url = (window.location.href.split("sendkey"))[0];
        if(url.indexOf("account_verification") >= 0){
            cookie = "2";
        }
        return cookie.length > 0 ? cookie : "0";
    }

    getZDCMigrationModule2ActiveStatus() {
        var cookie = this.getCookie("zdc_migration_module_2_active");
        return cookie === "1";
    }

    getZDCExpCouponCardActiveStatus() {
        var cookie = this.getCookie("zdc_exp_coupon_card_active");
        console.log("ZdcExpCoupon cookie value = "+cookie);
        return cookie === "1";
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // State updater. Checks if all requirements are met to establish that
    // we are completely logged in. This method avoids command chaining and
    // allows us to keep all async methods to work independently reducing
    // the load by a couple of seconds due to xfer.
    loginSync(sig, value = true) {
        if (sig && value !== undefined && this.syncStatus.requiredForLogin[sig] !== undefined) {
            this.syncStatus.requiredForLogin[sig] = value;
            // Now check if all required are true or not. If all is true then we can assume that
            // we have logged in.
            if (!this.state.loggedin) {
                var allTrue = true;
                for (var eachReq in this.syncStatus.requiredForLogin) {
                    if (this.syncStatus.requiredForLogin[eachReq] === false) {
                        allTrue = false;
                    }
                }
                if (allTrue) {
                    this.setState({
                        loggedin: true,
                        loginFetching: false
                    })
                    Toast.Show({
                        message: LocUtils.TPure("toasts.logged_in"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    })
                }
            }
        }
    }

    // Method to allow facebook login. Doesn't automatically check
    // if a previous session is present.
    loadFBWithoutStatus() {
        // declare standard FB handlers before loading the sdk
        window.fbAsyncInit = () => {
            window.FB.Event.subscribe('xfbml.render', this.fbLoaded);
            window.FB.init({
                xfbml: true,
                version: "v5.0",
                appId: process.env.REACT_APP_FB_APP_ID
            });
        };
        /** This asynchronously loads facebook sdk into the page */
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    fbLoaded() {
        let currentState = this.state;
        currentState.fbLoaded = true;
        this.setState(currentState);
    }

    /* ---------------------------------- HANDLER METHODS --------------------------------- */
    /** onComplete handler when local has finished loading */
    onLocLoaded(data) {
        this.setState({ locLoaded: true, locCode: data });
    }

    // param eg:
    // to => en
    // standardCode => en_US
    onLocChange(to, standardCode) {
        if (to) {
            LocUtils.Load(to, this.onLocLoaded);
            standardCode = (standardCode === undefined) ? LocUtils.GetLocaleSymbolFromLocaleKey(to) : standardCode;
            if (standardCode) {
                ServiceLocator.SocialNetworkManager.Me.Profile.ChangeLocale({
                    to: standardCode
                }, (r) => {
                    super.log("Changed locale: ", to);
                }, (err) => {
                    super.fatal("Encountered error when attempting to save the user locale: ", to, err);
                })
            }
        }
    }

    onLocChangeFromProfile(to) {
        if (to) {
            this.onLocChange(LocUtils.GetLocaleKeyFromLocaleCode(to), to);
        }
    }

    /** Auth status changes when facebook auth sends a change event */
    onAuthStatusChange(data) {
        if (data !== undefined && data.status === "connected") {
            var storedData = this.state.data;
            storedData.accessToken = data.authResponse.accessToken;
            storedData.signedRequest = data.authResponse.signedRequest;
            storedData.data_access_expiration_time = data.authResponse.data_access_expiration_time;
            storedData.expiresIn = data.authResponse.expiresIn;
            storedData.reauthorize_required_in = data.authResponse.reauthorize_required_in;
            storedData.userID = data.authResponse.userID;
            this.setState({
                data: storedData
            })
            // User has logged in previously, auto login, fetch data
            // and change states when done
            ServiceLocator.Auth.Fb.Get(
                data.authResponse.userID,
                this.onAutoAuthFetch,
                (() => { }),
                (err) => { super.fatal(err) }
            )
        } else {
            this.setState({ loggedin: false });
            this.tryZyngaVerification();
        }
    }

    /** When auto auth works and its able to fetched logged in data */
    onAutoAuthFetch(data) {
        if (data === undefined) { data = {}; }
        if (data.email !== undefined) {
            var storedData = this.state.data;
            storedData.picture = data.picture;
            storedData.first_name = data.first_name;
            storedData.last_name = data.last_name;
            storedData.email = data.email;
            storedData.friends = data.friends;
            $("#loginModalCenter").modal('hide');
        }
    }

    onFBLoginComplete(data) {
        if (data.authResponse && data.status) {
            Toast.Show({
                message: LocUtils.TPure("toasts.logged_in"),
                severity: Toast.SEVERITY_CODES.SUCCESS
            });
        }
        /**
         * No need to do anything further since, auth.StatusChange event
         * will automatically induce the changes and data should be updated
         * in the Page state due to its follow up methods.
         */

    }

    /** 
     * Method to handle signup form data. Does in a step process:
     * 1) verify if user already exists
     * 2) if (1) OK, then complete registration
     * 3) if (2) OK, then Auto sign in with creds
     * x) Error/Toasts on all other failures.
     */
    signupAuthHandler(signupFormData) {
        ServiceLocator.Auth.Zynga.Exists(
            signupFormData,
            ((resp) => {
                // Check if exits then decide whether to go ahead with signup
                if (resp && resp.data && resp.data.wfn === false) {
                    // Good to go ahead with register
                    Toast.Show({
                        message: LocUtils.TPure("toasts.user_signup_user_wait_while_registering"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    })
                    ServiceLocator.Auth.Zynga.Signup(
                        signupFormData,
                        ((resp) => {
                            if (resp && resp.e !== undefined && resp.e === 0) {
                                ServiceLocator.ZTrack.Cast({
                                    counter: "zdc_2",
                                    kingdom: "register",
                                    family: "zdc_register"
                                });
                                // Registration Complete
                                Toast.Show({
                                    message: LocUtils.TPure("toasts.user_signup_registration_complete"),
                                    severity: Toast.SEVERITY_CODES.SUCCESS
                                })
                                Toast.Show({
                                    message: LocUtils.TPure("toasts.user_signup_registration_do_verify_email"),
                                    severity: Toast.SEVERITY_CODES.INFO,
                                    timeout: Toast.TIMEOUTS.STICKY
                                })
                                Toast.Show({
                                    message: LocUtils.TPure("toasts.user_signup_registration_page_auto_login"),
                                    severity: Toast.SEVERITY_CODES.INFO
                                })
                                // AUTO SIGN IN THE USER
                                this.loginAuthHandler(signupFormData);
                            } else {
                                // Looks like a DAPI error happened here
                                // Lets send a OOPS error
                                Toast.Show({
                                    message: LocUtils.TPure("toasts.something_went_wrong"),
                                    severity: Toast.SEVERITY_CODES.ERROR
                                })
                            }
                        }),
                        ((err) => {
                            // Seems like an error with registration
                            Toast.Show({
                                message: LocUtils.TPure("toasts.something_went_wrong"),
                                severity: Toast.SEVERITY_CODES.ERROR
                            })
                            super.fatal(err);
                        })
                    )
                } else {
                    // Looks like the account already exists
                    Toast.Show({
                        message: LocUtils.TPure("toasts.user_signup_user_already_exists"),
                        severity: Toast.SEVERITY_CODES.WARNING
                    })
                }
            }),
            ((err) => {
                Toast.Show({
                    message: LocUtils.TPure("toasts.something_went_wrong"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
                super.fatal(err);
            })
        )
    }

    loginFBHandler(event) {
        let storedData;
        ServiceLocator.Auth.Fb.Login(
            (data) => {
                console.log(data);
                if (data.accessToken && data.userID) {
                    Toast.Show({
                        message: LocUtils.TPure("toasts.user_signup_fb_connected"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    });
                    storedData = this.state.data;
                    storedData.picture = data.picture;
                    storedData.first_name = data.first_name;
                    storedData.last_name = data.last_name;
                    if (data.email !== undefined) {
                        storedData.email = data.email;
                    }
                    storedData.friends = data.friends;
                    this.setState({
                        fbConnect: {
                            connected: true,
                            data: storedData
                        }
                    }, () => {
                        // Now check if this is already connected?
                        ServiceLocator.Auth.Zynga.SignupFB(
                            data,
                            ((resp) => {
                                console.log(resp);
                                if (resp && resp.e !== undefined && resp.e === 0) {
                                    // Registration Complete
                                    Toast.Show({
                                        message: LocUtils.TPure("toasts.user_signup_registration_page_auto_login"),
                                        severity: Toast.SEVERITY_CODES.INFO
                                    })
                                    // AUTO SIGN IN THE USER
                                    ServiceLocator.Auth.Zynga.Login({
                                        fbtoken: data.accessToken,
                                        keepSignedIn: "true",
                                        onCompleteCallback: this.onZyngaLogin,
                                        onFailureCallback: (err) => {
                                            this.setState({
                                                loginErrMsg: LocUtils.TPure("toasts.something_went_wrong")
                                            });
                                        }
                                    })
                                } else {
                                    // Looks like a DAPI error happened here
                                    // Lets send a OOPS error
                                    ServiceLocator.ZTrack.Cast({
                                        counter: "zdc_2",
                                        kingdom: "login",
                                        phylum: "connect_error1",
                                        zClass: data.userID,
                                        family: "zdc_register"
                                    });
                                    ZLogger.error("zauth registerFBAccount has returned error for fbid :" + data.userID);
                                    this.setState({
                                        loginErrMsg: LocUtils.TPure("toasts.fb_login_failed")
                                    });
                                }
                            }),
                            ((err) => {
                                // Seems like an error with registration
                                ServiceLocator.ZTrack.Cast({
                                    counter: "zdc_2",
                                    kingdom: "login",
                                    phylum: "connect_error2",
                                    zClass: data.userID,
                                    family: "zdc_register"
                                });
                                ZLogger.error("zauth registerFBAccount has failed for fbid :" + data.userID);
                                this.setState({
                                    loginErrMsg: LocUtils.TPure("toasts.fb_login_failed")
                                });
                            })
                        )
                    })
                }
            },
            (err) => {
                this.setState({
                    loginErrMsg: LocUtils.TPure("toasts.login_failed")
                });
                super.error("Error occurred => ", err)
            }
        )
    }


    /**
     * Checks if the server sent a csrf and a zid for session continuation
     */
    tryZyngaVerification() {
        // If the client is authenticated then we should have the page rendered
        // the csrf and zid in the window property. Lets see if we have it or
        // not. If yes, the we go ahead with resuming session, otherwise we will
        // look for facebook dep.
        var data = {};
        var session_present = true;
        data.csrf = (window.csrf && window.csrf.length > 0) ? window.csrf : (() => { session_present = false; return "" })();
        data.zid = (window.zid && window.zid.length > 0) ? window.zid : (() => { session_present = false; return "" })();
        data.needs_gdpr_verification = (window.is_tos_seen !== undefined && String(window.is_tos_seen) === "false");
        if (session_present) {
            // // Let fb sdk load without checking in the login status
            // setTimeout(() => { this.loadFBWithoutStatus() }, 300);
            // Get user attributes with zynga
            this.setState({
                alreadyLoggedin: true,
                loginFetching: true,
                needs_gdpr_verification: data.needs_gdpr_verification
            })
            this.onZyngaLogin(data, false);
        } else {
            this.setState({
                alreadyLoggedin: false,
                loggedin: false
            })
        }
    }

    /**
     * Attempts to get the user attributes from zdc
     */
    getZyngaUserAttributes(data) {
        var _selfSig = "userAttributes";
        ServiceLocator.SocialNetworkManager.Me.Profile.Get(
            ((data) => {
                if (data) {
                    var storedData = this.state.data;
                    var zid = this.state.data.zid;
                    storedData.userAttr = data["users"][zid];
                    $("#loginModalCenter").modal('hide');
                    this.setState({
                        context: ServiceLocator.Auth.Zynga.Context,
                        data: storedData
                    })
                    this.loginSync(_selfSig, true);
                    if (window.needs_tos !== undefined && window.needs_tos === true) {
                        // we need to redirect
                    }
                    this.checkLocaleNeed(storedData);
                }
            }),
            ((err) => {
                super.error("Failed to get user attribs");
                Toast.Show({
                    message: LocUtils.TPure("toasts.something_went_wrong"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
            })
        )
    }

    /**
     * Attempts to get the account type
     */
    getZyngaAccountInfo() {
        ServiceLocator.Auth.Zynga.Session.GetAccountInfo(() => { },
            ((resp) => {
                if (resp && resp.e !== undefined && resp.e === 0) {
                    // Registration Complete
                    var type = resp.data.r.type;
                    this.setState({
                        accountType: type
                    });
                } else {
                    // Looks like a DAPI error happened here
                    // Lets send a OOPS error
                    Toast.Show({
                        message: LocUtils.TPure("toasts.something_went_wrong"),
                        severity: Toast.SEVERITY_CODES.ERROR
                    })
                }
            }),
            ((err) => {
                super.error("Failed to get account info");
                Toast.Show({
                    message: LocUtils.TPure("toasts.something_went_wrong"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
            })
        )
    }

    checkLocaleNeed(data) {
        if (data.userData && data.userData.user && data.userData.user.locale) {
            super.log("Changing the user site language");
            this.onLocChangeFromProfile(LocUtils.GetLocaleKeyFromLocaleCode(data.userData.user.locale));
        }
    }

    reloadZyngaUserAttributes() {
        this.getZyngaUserAttributes(this.state.data);
        this.getZyngaAccountInfo();
    }

    /**
     * Attempts to get the zdc friends of this user for his csrf.
     * Requires a session
     */
    getZDCFriends() {
        var _selfSig = "zFriends";
        ServiceLocator.SocialNetworkManager.People.Friends.Get(
            ((data) => {
                if (data && data.zFriendBaseInfo) {
                    var zFriends = this.state.zFriends;
                    for (var i = 0; i < Object.keys(data.zFriendBaseInfo).length; ++i) {
                        var each_friend = data.zFriendBaseInfo[i];
                        if (each_friend.zid) {
                            zFriends[each_friend.zid] = each_friend;
                        }
                    }
                    // super.log("Found friends: ", zFriends);
                    // sync it back to the state
                    this.setState({
                        zFriends: zFriends
                    })
                    this.loginSync(_selfSig, true);
                }
            }),
            ((err) => {
                // At least continue login
                this.loginSync(_selfSig, true);
                super.fatal("Got an error while fetching friends..", err)
            }))
    }

    /**
     * Attempts to get load the EOS experiments of this user.
     * Requires a session
     */
    getEOSExperiments() {
        var _selfSig = "eosLoad";
        super.log("Fetching Lab Sauce!");
        ServiceLocator.OptimizationManager.EOS.Init((status) => {
            this.loginSync(_selfSig, true);
            this.initPermanentBenefit();
            // In case EOS didn't load
            // throw an error log.
            if (status === false) {
                super.fatal("EOS Load: Failed to load EOS on login.");
            }
        }, true);
    }

    /**
     * Loads user and friend profile data, stores with the site wide mapping.
     */
    getUserGraph() {
        ServiceLocator.SocialNetworkManager.Me.GetGraph(
            ((res) => {
                if (res.e === 0 && res.mappings) {
                    ServiceLocator.Site.SetSocialMappings(res.mappings);

                    //Store zFriends
                    var friendProfiles = [];
                    Object.keys(res.mappings).forEach((item) => {
                        if (item !== this.state.data.zid)
                            friendProfiles.push(res.mappings[item]);
                    })

                    this.setState({
                        friendProfiles: friendProfiles
                    })
                }
            }),
            ((err) => {
                super.fatal("Error when attempting to fetch user friends, ", err);
            })
        )
    }

    /** Attempt to login with zdc credentials */
    loginAuthHandler(data) {
        ServiceLocator.Auth.Zynga.Login({
            username: data.username,
            password: data.password,
            keepSignedIn: data.keepSignedIn,
            source: data.source,
            onCompleteCallback: this.onZyngaLogin,
            onFailureCallback: (err) => {
                Toast.Show({
                    message: LocUtils.TPure("toasts.something_went_wrong"),
                    severity: Toast.SEVERITY_CODES.ERROR
                })
            }
        })
    }

    /** Once zynga login is complete, extract and set state data */
    onZyngaLogin(data, refresh = true) {
        if (refresh) {
            if (data !== undefined && data.e === 1) {
                // We will assume that something went wrong in the backend and hence wont
                // be going ahead with the stage changes.
                super.error("Something went wrong when logging in with ZDC. Please contact a dev and show him this: ", data);
                this.setState({
                    invalidCredentials: true,
                    source: data.source
                });
            } else if(data !== undefined && data.e === 2){
                DataStore.LocalStorage.Store("emailIdToSendVerification", data.email);
                //window.location.reload();
                $("#loginModalCenter").modal("hide");
                $("#emailVerificationModal").modal('show');
            }else {
                // successful login
                if (data.source === 1 || data.source === 2) {
                    ServiceLocator.ZTrack.Cast({
                        counter: "zdc_2",
                        kingdom: "login",
                        phylum: window.location.href,
                        zClass: "email",
                        family: "zdc_register",
                        genus: window.location.pathname + window.location.search,
                        value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                    });
                    window.location.reload();
                } else if (data.source === 3) {
                    ServiceLocator.ZTrack.Cast({
                        counter: "zdc_2",
                        kingdom: "login",
                        phylum: window.location.href,
                        zClass: "email_restore",
                        family: "zdc_register",
                        genus: window.location.pathname + window.location.search,
                        value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                    });
                    window.location.href = "/";
                } else {
                    ServiceLocator.ZTrack.Cast({
                        counter: "zdc_2",
                        kingdom: "login",
                        zClass: "fbconnect",
                        phylum: window.location.href,
                        family: "zdc_register",
                        genus: window.location.pathname + window.location.search,
                        value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                    });
                    window.location.reload();
                }
            }
        } else {
            if (data !== undefined && data.zid !== undefined && data.csrf !== undefined) {
                // We have the complete data, start extracting stuff and update the states
                var storedData = this.state.data;
                storedData.zid = data.zid;
                storedData.userData = data.userData;
                storedData.userAttr = data.userAttr;
                storedData.csrf = data.csrf;
                this.setState({
                    data: storedData,
                    loginFetching: true
                })

                //window.location.reload();
                // !Important: Init SNManager first to ensure all
                // SN components load correctly
                ServiceLocator.SocialNetworkManager.Init(data.csrf, data.zid);

                // Now we shall fetch all the required user information
                // and let them aggregate till all of them sign
                this.getUserGraph();
                this.getZyngaUserAttributes(storedData);
                this.getZDCFriends();
                this.getEOSExperiments();
                this.getZyngaAccountInfo();
                // Switch the site language to the user preference only when the user data
                // contains relevant indicator.
                // super.log("Checking user profile locale: ", storedData.userData.user.locale, storedData);
                if (storedData.userData && storedData.userData.user && storedData.userData.user.locale) {
                    super.log("Changing the user site language");
                    this.onLocChangeFromProfile(storedData.userData.user.locale);
                }

            } else {
                // We will assume that something went wrong in the backend and hence wont
                // be going ahead with the stage changes.
                super.error("Something went wrong when logging in with ZDC. Please contact a dev and show him this: ", data);
                this.setState({
                    invalidCredentials: true,
                    source: data.source
                });
            }
        }
    }

    /**
     * Logs out the user based on the logged in context (obviously)
     */
    logoutHandler(onComplete) {
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_2",
            kingdom: "logout"
        });
        onComplete = (onComplete) ? onComplete : (() => { });
        // Based on the appropriate context, perform logout
        if (ServiceLocator.Auth.Fb.Is(this.state.context)) {
            ServiceLocator.Auth.Fb.Logout(
                (resp) => {
                    this.setState({
                        loggedin: false,
                        data: {},
                        context: undefined
                    });
                    onComplete(resp);
                    Toast.Show({
                        message: LocUtils.TPure("toasts.logged_out"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    })
                },
                (err) => { super.error("Logout failed with err: ", err) }
            )
        } else if (ServiceLocator.Auth.Zynga.Is(this.state.context)) {
            ServiceLocator.Auth.Zynga.Session.Logout(
                this.state.data,
                (resp) => {
                    this.setState({
                        loggedin: false,
                        data: {},
                        context: undefined
                    });
                    Toast.Show({
                        message: LocUtils.TPure("toasts.logged_out"),
                        severity: Toast.SEVERITY_CODES.SUCCESS
                    });
                    onComplete(resp);
                },
                (err) => { super.error("Logout failed with err: ", err) }
            )
        } else {
            super.error("Logout: unknown context registered: this page will reload in 3 seconds");
            setTimeout(function () {
                window.location.href = "/";
            }, 1000);
        }
    }

    /** handler for forget password */
    forgotPasswordHandler(email, onCompleteCallback, onFailureCallback) {
        if (email) {
            ServiceLocator.Auth.Zynga.ForgotPassword(email, onCompleteCallback, onFailureCallback);
        }
    }

    /** Handler when fb connect is to be processed */
    fbConnectHandler() {
    }

    settingsPaymentHandler() {
        ServiceLocator.Auth.Zynga.Session.PaymentSettings(
            this.state.data,
            (resp) => {
                if (resp.e === 0 && resp.users && resp.users[this.state.data.zid]) {
                    var payInfo = resp.users[this.state.data.zid].paymentsInfo;
                    var payload = {
                        data: {
                            settingsSessionId: payInfo.session_id,
                            settingsURL: payInfo.userSettingsUrl + "#proxy=https://zlive-s3-assets.s3.amazonaws.com/zdcrelease/proxy.html"
                        },
                        type: "zbillr_pay_settings"
                    }
                    // super.log("sending for payment settings", payload);
                    window.postMessage(JSON.stringify(payload), window.location.origin);
                }
            }
        )
    }

    onOptOutClick() {
        this.setState({
            optedOut: true
        })
    }

    onOptOutClose() {
    }

    /** Fetch backend data related to Permanent benefits feature */
    initPermanentBenefit() {
        ServiceLocator.PB.FetchData(
            ((res) => {
                if (res.e !== undefined && res.e === 0 && res.data) {
                    const variant = ServiceLocator.OptimizationManager.EOS.GetExperimentVariant(SiteConstants.PermBenefitsExperiments.MASTER_EXPERIMENT);
                    if (variant === 2) {
                        let hudVariant = ServiceLocator.OptimizationManager.EOS.GetExperimentVariant(SiteConstants.PermBenefitsExperiments.HUD_UI_VARIANT);
                        this.setState({
                            featureActive: !ServiceLocator.PB.FeatureExpired(),
                            featureData: res.data,
                            hudVariant: hudVariant
                        })
                    }
                }
            }),
            ((err) => {
                super.fatal("Error when attempting to fetch perm_benefit data, ", err);
            })
        )
    }

    /** Records reward claimed and changes state to reflect on HUD */
    onRewardClaimed() {
        ServiceLocator.PB.UpdateRewardClaimed(
            ((res) => {
                if (res.e !== undefined && res.e === 0 && res.data) {
                    if (this.state && this.state.featureData) {
                        this.setState({
                            featureData: res.data
                        })
                    }
                }
            }),
            ((err) => {
                super.fatal("Error when attempting to fetch perm_benefit data, ", err);
            })
        );
    }

    render() {

        const AdminConsole = () => {
            if (process.env.NODE_ENV === "development" ||
                process.env.REACT_APP_ADMIN_CONSOLE === 'allowed'
            ) {
                return <div id="adminConsole">
                    {/* <input style={{visibility: "hidden", height: "1px"}} id="localeUpload" type="file" name="localeUpload" size="chars" />     */}
                </div>
            }
            return (
                <div>
                </div>
            )
        }
        if (!this.state.locLoaded) {
            return (<div></div>); // Or maybe some animation?
        }
        return (
            <div className="farm2-pageroot farm2-pageroot-font">
                {AdminConsole()}
                <div id="fb-root"></div>
                {/* 
                    Since most components rely on the route, hence wrap all the components
                    with the browserRouter to use Links and Routes throughout the page flow.
                */}
                <BrowserRouter>
                    <GdprComponent data={this.state.data} />
                    {
                        !!this.state.optedOut &&
                        <BetaSurvey surveyId="betaExit" onOptOutClose={this.onOptOutClose} />
                    }
                    {!!this.state.loggedin &&
                        <RewardPopup
                            onRewardClaimed={this.onRewardClaimed}
                            featureData={this.state.featureData}
                        />
                    }
                    {!!this.state.loggedin &&
                        <RewardInfoDialog
                            variant={this.state.hudVariant}
                            featureData={this.state.featureData} />
                    }

                    <PaymentComponent />
                    <WelcomeComponent hideWelcome={this.state.hideWelcome} />
                    <NavComponent useBrandForHome={false}
                        loggedin={this.state.loggedin}
                        alreadyLoggedin={this.state.alreadyLoggedin}
                        loginFetching={this.state.loginFetching}
                        data={this.state.data}
                        logoutHandler={this.logoutHandler}
                        context={this.state.context}
                        locChangeHandler={this.onLocChange}
                        locLoaded={this.state.locLoaded}
                        settingsPaymentHandler={this.settingsPaymentHandler}
                        onFbLoginClick={this.onFbLoginClick}
                        cookieEmail={this.state.cookieEmail}
                        isZDCMigrationModule2={this.state.isZDCMigrationModule2}
                        isZDCExpCouponActive={this.state.isZDCExpCouponActive}
                        invalidCredentials={this.state.invalidCredentials}
                        source={this.state.source}
                        resetInvalidCreds={this.resetInvalidCreds}
                        setHideWelcome={this.setHideWelcome}
                        loginAuthHandler={this.loginAuthHandler}
                        featureActive={this.state.featureActive}
                        featureData={this.state.featureData}
                        hudUIVariant={this.state.hudVariant}
                    />

                    <SiteTour loggedin={this.state.loggedin}
                        alreadyLoggedin={this.state.alreadyLoggedin}
                        loginFetching={this.state.loginFetching}
                        variant={ServiceLocator.OptimizationManager.GoogleOptimize.GetExperimentVariant(process.env.REACT_APP_GOPT_SITE_INTRO)} />

                    <LoginComponent loggedin={this.state.loggedin}
                        loginFBHandler={this.loginFBHandler}
                        loginErrMsg={this.state.loginErrMsg}
                        loginAuthHandler={this.loginAuthHandler}
                        signupAuthHandler={this.signupAuthHandler}
                        forgotPasswordHandler={this.forgotPasswordHandler}
                        location={window.location.href}
                        locLoaded={this.state.locLoaded}
                        fbConnectHandler={this.fbConnectHandler}
                        fbLoginClicked={this.state.fbLoginClicked}
                        resetFbLoginClick={this.resetFbLoginClick}
                        resetInvalidCreds={this.resetInvalidCreds}
                        cookieEmail={this.state.cookieEmail}
                        isZDCMigrationModule2={this.state.isZDCMigrationModule2}
                        isZDCExpCouponActive={this.state.isZDCExpCouponActive}
                        invalidCredentials={this.state.invalidCredentials}
                        source={this.state.source}
                        loginType={this.state.loginType} />



                    <Content
                        featureData={this.state.featureData}
                        featureActive={this.state.featureActive}
                        fbLoaded={this.state.fbLoaded}
                        locCode={this.state.locCode}
                        locChangeHandler={this.onLocChange}
                        reloadAttrHandler={this.reloadZyngaUserAttributes}
                        loggedin={this.state.loggedin}
                        alreadyLoggedin={this.state.alreadyLoggedin}
                        loginAuthHandler={this.loginAuthHandler}
                        loginType={this.state.loginType}
                        data={this.state.data}
                        context={this.state.context}
                        logoutHandler={this.logoutHandler}
                        locLoaded={this.state.locLoaded}
                        needsTOS={this.state.needsTOS}
                        zFriends={this.state.zFriends}
                        friendProfiles={this.state.friendProfiles}
                        accountType={this.state.accountType}
                        needs_gdpr_verification={this.state.needs_gdpr_verification}
                        setTosSeen={this.setTosSeen} />

                    <Footer onOptOutClick={this.onOptOutClick} />
                    <EmailVerification />
                    <VerifyEmailOnSignUp />
                    <VerifyEmail
                        reloadAttrHandler={this.reloadZyngaUserAttributes}
                        loggedin={this.state.loggedin}
                        alreadyLoggedin={this.state.alreadyLoggedin}
                    />
                    <ScrollHelper />
                </BrowserRouter>
            </div>
        )
    }
}

ReactDOM.render(<Page />, document.getElementById('root'));