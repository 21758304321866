import ServiceLocator from "../ServiceLocator";
import People from "./SocialNetwork/People/People";
import Me from "./SocialNetwork/Me/Me";
import Comms from "./SocialNetwork/Comms/Comms";

var SocialNetworkManager = (function () {

    let csrf = undefined;
    let zid = 0;

    function Init(selfCsrf, selfZid) {
        csrf = selfCsrf;
        zid = selfZid;
        ServiceLocator.SocialNetworkManager.Comms.Init();
    }

    function SessionData() {
        return {
            csrf: csrf,
            zid: zid
        }
    }

    return {
        Init: Init,
        Comms: Comms,
        Me: Me,
        People: People,
        SessionData: SessionData
    }
})();

export default SocialNetworkManager;