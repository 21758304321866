/**
 * ZTrack.js
 * Wrapper for Zynga's ZTrack stats tracking service.
 * @author: jswarnakar
 */
import ZTrackConstants from "./ZTrackConstants";
import ServiceLocator from "../../ServiceLocator"
import ZLogger from "../../util/zlogger/zlogger";

/**
 * Uses Zynga's ZTrack service to log stats. Unlike the old zdc, a bunch of payload
 * is not pushed, and only one stat is fired at a time. We will let this api make
 * multi async calls.
 */
var ZTrack = (function () {

    /**
     * Furnishes the passed object with bare minimum/default values
     * if not passed. This gives the client caller the flexibility
     * to only specify the params they need to send.
     * @param {Object} param0 Input values in the object from client
     */
    function furnish({ counter, kingdom = null, phylum = null,
        zClass = null, family = null, genus = null, milestone = null,
        value = 1, rate = 1 }) {
        let playerZid =
            ServiceLocator.SocialNetworkManager.SessionData().zid === 0
                ? (window.zid !== undefined && !isNaN(parseInt(window.zid)))
                    ? parseInt(window.zid)
                    : 0
                : ServiceLocator.SocialNetworkManager.SessionData().zid;
        const trackPayload = {
            zid: playerZid,
            counter: String(counter),
            kingdom: (!!kingdom ? String(kingdom) : kingdom),
            phylum: (!!phylum ? String(phylum) : phylum),
            zClass: (!!zClass ? String(zClass) : zClass),
            family: (!!family ? String(family) : family),
            genus: (!!genus ? String(genus) : genus),
            milestone: (!!milestone ? String(milestone) : milestone),
            value: (value !== undefined && !isNaN(parseInt(value))) ? parseInt(value) : 1,
            rate: (rate !== undefined && !isNaN(parseInt(rate))) ? parseInt(rate) : 1
        }
        return trackPayload;
    }

    /**
     * Obviously, checks whether inputs passed in the candidate payload are
     * correct or not and meet type requirements etc.
     * @param {Object} param0 Input values in the object from client
     */
    function isInvalid({ counter, rate, value }) {
        return !!(counter === undefined || String(counter).length === 0 ||
            (rate !== undefined && isNaN(parseInt(rate))) ||
            (value !== undefined && isNaN(parseInt(value)))
        )
    }

    /**
     * Send a stats call to the endpoint service to be logged into the db table for
     * analytical purposes. Casts a vote candidate to the service for tracking.
     * @param {Object} data An Object containing the bare minimum KV pairs for firing a stat
     * @param {fn} onCompleteCallback To call when a successful call happens to the service
     * @param {fn} onFailureCallback To call when a service failure happens while calling the service
     */
    function Cast(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        ZLogger.log("Logging stat: ", data);
        if (isInvalid(data)) {
            return false;
        }
        const fullyFurnishedData = furnish(data);
        const params = {
            "data[0][zid]": fullyFurnishedData.zid,
            "data[0][value]": fullyFurnishedData.value,
            "data[0][counter]": process.env.NODE_ENV + "-" + fullyFurnishedData.counter,
            "data[0][kingdom]": fullyFurnishedData.kingdom,
            "data[0][phylum]": fullyFurnishedData.phylum,
            "data[0][class]": fullyFurnishedData.zClass,
            "data[0][family]": fullyFurnishedData.family,
            "data[0][genus]": fullyFurnishedData.genus,
            "data[0][milestone]": fullyFurnishedData.milestone,
            "data[0][rate]": fullyFurnishedData.rate
        }

        const payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/ztrack",
            payload: payload,
            token: "ZTRACK-" + (new Date()).getTime() + "-" + fullyFurnishedData.counter + "-" + Math.random()
        })
            .then(res => { return res.json() })
            .then(parsed => {
                onCompleteCallback(parsed)
            })
            .catch(err => { onFailureCallback(err) })
    }

    return {
        Cast: Cast,
        ZTrackConstants: ZTrackConstants
    }
})();

export default ZTrack;