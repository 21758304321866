/**
 * Login.js
 * [Managed by LoginComponent]
 * [Housed in login tab]
 * Takes care of login actions (delegating to the login handler)
 * and forget password options. This is a BS4 carousel.
 * (....BS here means bootstrap btw)
 * @author Jaiwardhan Swarnakar
 */
import React from 'react';
import BaseComponent from '../../BaseComponent';
import LocUtils from '../../../util/localization/LocUtils'
import '../../../../styles/generic.css'
import './Login.css'
import $ from 'jquery'
import Utils from '../../../managers/Network/Utils';
import Toast from '../../../util/toasts/Toast';
import ServiceLocator from '../../../ServiceLocator';
import Button from '../buttons/Buttons';
import FacebookLogin from './facebookLogin';
import DataStore from "../../../data/DataStore";
import WithoutFacebookLogin from "./withoutFacebookLogin";

class Login extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loginType: "0", // 0 => never logged in before, 1 => logged in via facebook, 2 => logged in via email and password
            username: this.props.cookieEmail,
            password: "",
            forgetEmail: this.props.cookieEmail,
            engaged: false,
            keepSignedIn: true,
            validation: {
                username: false,
                password: false,
                forgetEmail: false,
            },
            errorMsg: "",
            source: 2,
            fromSignup: false
        };
        /** Properties passed */
        this.loginAuthHandler = props.loginAuthHandler;
        this.loginFBHandler = props.loginFBHandler;
        this.forgotPasswordHandler = props.forgotPasswordHandler;
        this.resetFbLoginClick = props.resetFbLoginClick;

        /** Internal handlers */
        this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
        this.onForgotPasswordLinkClick = this.onForgotPasswordLinkClick.bind(this);
        this.onBackToLoginButtonClick = this.onBackToLoginButtonClick.bind(this);
        this.onForgotPasswordSubmitClick = this.onForgotPasswordSubmitClick.bind(this);
        this.onForgotPasswordUnderstoodButtonClick = this.onForgotPasswordUnderstoodButtonClick.bind(this);
        this.onEmailLoginClick = this.onEmailLoginClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onEmailNotVerified = this.onEmailNotVerified.bind(this)

        // Subscription to turn of the field editing 
        this.onEngageFinish = this.onEngageFinish.bind(this);
        if (!ServiceLocator.Events.isSubscribed("ZDC_LOGIN_DIALOG", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_LOGIN_DIALOG", this.onEngageFinish);
        if (!ServiceLocator.Events.isSubscribed("ZDC_FORGET_PASSWORD", this.onEngageFinish)) ServiceLocator.Events.subscribe("ZDC_FORGET_PASSWORD", this.onEngageFinish);
        if (!ServiceLocator.Events.isSubscribed("FACEBOOK_LOGIN", this.onEngageFinish)) ServiceLocator.Events.subscribe("FACEBOOK_LOGIN", this.onEngageFinish);

    }

    componentDidMount() {
        this.setState({
            //username: this.props.preloadedData.email,
            loginType: this.props.loginType
        })
    }

    componentWillUnmount() {
        ServiceLocator.Events.unsubscribe("ZDC_LOGIN_DIALOG", this.onEngageFinish);
        ServiceLocator.Events.unsubscribe("ZDC_FORGET_PASSWORD", this.onEngageFinish);
        ServiceLocator.Events.unsubscribe("FACEBOOK_LOGIN", this.onEngageFinish);
    }

    pageActions = {
        validityChecks: () => {
            const { forgetEmail, password, username } = this.state;
            let verdict = this.state;
            if (!password || password.length < 7) verdict.validation.password = true;
            if (!username || username.length === 0 || !Utils.ValidateEmail(username)) verdict.validation.username = true;
            if (!forgetEmail || forgetEmail.length === 0 || !Utils.ValidateEmail(forgetEmail)) verdict.validation.forgetEmail = true;
            this.setState(verdict);
        },
        resetValidityCheck: (d = {}) => {
            let verdict = this.state;
            Object.keys(d).forEach(k => {
                if (this.state.validation[k] !== undefined) verdict.validation[k] = false;
            })
            this.setState(verdict);
        },
        resetErrors: () => {
            let verdict = this.state;
            verdict.validation.username = false;
            verdict.validation.password = false;
            verdict.validation.forgetEmail = false;
            this.setState(verdict);
        }
    }


    /** Subscription handler */
    onEngageFinish(evt) {
        this.setState({
            engaged: false
        })
    }

    onEmailLoginClick() {
        this.resetFbLoginClick();
        this.setState({
            loginType: "2"
        });
    }

    /** PANE1: When login button is clicked */
    onLoginButtonClick(evt) {
        // In the event that the user attempts multiple button click
        // we would want to avoid it till the time the network has 
        // resolved itself
        if (this.state.engaged) return;

        this.pageActions.validityChecks();



        if (Utils.ValidateEmail(this.state.username) && Utils.ValidatePassword(this.state.password)) {
            this.setState({
                engaged: true
            })
            this.loginAuthHandler(this.state);
        }

        if (!Utils.ValidateEmail(this.state.username) && !Utils.ValidatePassword(this.state.password)) {
            this.setState({
                errorMsg: LocUtils.TPure("toasts.forget_password_invalid_email_password")
            });
        } else if (!Utils.ValidateEmail(this.state.username)) {
            this.setState({
                errorMsg: LocUtils.TPure("toasts.forget_password_invalid_email")
            });
        } else if (!Utils.ValidatePassword(this.state.password)) {
            this.setState({
                errorMsg: LocUtils.TPure("toasts.forget_password_invalid_password")
            });
        }

        // Clear password field if incorrect/invalid password is entered
        if (this.state.validation.password || this.state.validation.username) {
            this.setState({
                password: ''
            });
        }
    }

    /** PANE1: when user click on the forgot password indicator */
    onForgotPasswordLinkClick(evt) {
        this.pageActions.resetErrors();
        $("#login-carousel").carousel(1);
    }

    /** PANE2: When user wants to go back to login pane (back button) */
    onBackToLoginButtonClick(evt) {
        this.pageActions.resetErrors();
        $("#login-carousel").carousel(0);
    }

    onCheckboxChange(data) {
        var oldKeepSignedIn = this.state.keepSignedIn;
        this.setState({
            keepSignedIn: !oldKeepSignedIn,
        });
    }

    /** PANE2: When user submits a mail for forgotten password account */
    onForgotPasswordSubmitClick(evt) {
        // In the event that the user attempts multiple button click
        // we would want to avoid it till the time the network has 
        // resolved itself
        if (this.state.engaged) return;
        this.pageActions.validityChecks();
        if (Utils.ValidateEmail(this.state.forgetEmail)) {
            this.setState({
                engaged: true
            })
            this.props.forgotPasswordHandler(
                this.state.forgetEmail,
                (resp => { resp["e"] === 2 ?
                    this.onEmailNotVerified(this.state.forgetEmail)
                    : resp["e"] === 1  && resp["errors"] && resp["errors"].includes(1222)?
                        Toast.Show({
                            message: LocUtils.TPure("toasts.account_update_error_1024"),
                            severity: Toast.SEVERITY_CODES.ERROR
                        })
                        : $("#login-carousel").carousel(2) }),
                (err => {
                    super.error("Error when sending data", err);
                    Toast.Show({
                        message: LocUtils.TPure("toasts.something_went_wrong"),
                        severity: Toast.SEVERITY_CODES.ERROR
                    })
                })
            )
        } else {
            Toast.Show({
                message: LocUtils.TPure("toasts.forget_password_invalid_email"),
                severity: Toast.SEVERITY_CODES.ERROR
            })
        }
    }

    /** PANE3: User acknowledges that email was sent */
    onForgotPasswordUnderstoodButtonClick(evt) {
        this.pageActions.resetErrors();
        $("#login-carousel").carousel(0);
    }


    onChangeField(data) {
        this.pageActions.resetValidityCheck(data);
        this.props.resetInvalidCreds();
        this.setState(data);
    }

    onEmailNotVerified(data){
        DataStore.LocalStorage.Store("emailIdToSendVerification", data);
        $("#loginModalCenter").modal("hide");
        $("#emailVerificationModal").modal('show')
    }


    showErrorMsg() {
        return (!!this.state.validation.username || !!this.state.validation.password || (this.props.invalidCredentials && this.props.source === 2));
    }

    render() {
        return (
            <div aria-labelledby={this.props.tabRef} id={this.props.id}>
                <div className="row login-row no-gutters">
                    {!this.props.isZDCMigrationModule2 &&
                    <FacebookLogin
                        loginFBHandler={this.loginFBHandler}
                        source={"emailLogin"}
                        loginErrMsg={this.props.loginErrMsg}
                        parentSwitchTabHandle={this.props.parentSwitchTabHandle}
                    />}
                    {this.props.isZDCMigrationModule2 &&
                    <WithoutFacebookLogin
                        loginFBHandler={this.loginFBHandler}
                        source={"emailLogin"}
                        loginErrMsg={this.props.loginErrMsg}
                        parentSwitchTabHandle={this.props.parentSwitchTabHandle}
                    />}
                    <div className="col col-md-6 col-lg-6 signup-fb-container">
                        <div
                            id="login-carousel"
                            className="carousel slide full-ht"
                            data-keyboard="false"
                            data-ride="carousel"
                            data-interval="false"
                        >
                            <div className="carousel-inner carousel-min-pad full-ht">
                                {/* The login form */}
                                <div className="carousel-item active full-ht">
                                    {/* The form */}
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <p className="h4">
                                                {(this.state.loginType === "0") ? LocUtils.T("login.fb_signup_title") : LocUtils.T("login.login_title")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <p className="h5">
                                                {(this.state.loginType === "0") ? LocUtils.T("login.fb_signup_subtitle") : LocUtils.T("login.login_greet")}
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="input-group mb-3 text-left">
                                        <p className="h6">{LocUtils.T("login.login_desc")}</p>
                                    </div>
                                    <div className="input-group mb-3 text-left">
                                        <div className="invalid-tooltip error-message-container" style={{ "position": "static", "display": this.showErrorMsg() ? "block" : "none" }} >
                                            {(this.props.invalidCredentials && this.props.source === 2) ? LocUtils.TPure("toasts.invalid_credentials") : this.state.errorMsg}
                                        </div>
                                    </div>
                                    <div
                                        className="input-group mb-3"
                                    >
                                        <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                >
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                        </div>
                                        <input
                                            disabled={this.state.engaged}
                                            type="email"
                                            onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({
                                                    username: evt.target.value,
                                                });
                                            }}
                                            placeholder={LocUtils.TPure("login.email")}
                                            className={
                                                ((!!this.state.validation.username || (!!this.props.invalidCredentials && this.props.source === 2))
                                                    ? "is-invalid no-cross-img "
                                                    : "") + "form-control"
                                            }
                                            aria-label="Email"
                                            id={this.props.id + "-email"}
                                            value={this.state.username}
                                        />
                                    </div>
                                    <div
                                        className="input-group mb-3"
                                    >
                                        <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                >
                                                    <i className="fa fa-key" aria-hidden="true"></i>
                                                </span>
                                        </div>
                                        <input
                                            disabled={this.state.engaged}
                                            type="password"
                                            onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({
                                                    password: evt.target.value,
                                                });
                                            }}
                                            placeholder={LocUtils.TPure("login.password")}
                                            className={"form-control"}
                                            aria-label="Password"
                                            id={this.props.id + "-password"}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            onClick={this.onLoginButtonClick}
                                            extraClass="form-signin-button-max-width"
                                            message={
                                                this.state.engaged ? (
                                                    <span>
                                                                <i
                                                                    className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                                                    style={{ fontSize: "1rem" }}
                                                                ></i>
                                                        &nbsp;{LocUtils.T("login.login_btn")}
                                                            </span>
                                                ) : (
                                                    LocUtils.T("login.login_btn")
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="row no-gutters">
                                        {/* <--- modal helper for logging in and forgot password starts --> */}
                                        <div className="col-sm-6">
                                            <div className="mt-0">
                                                <div className="mt-8n">
                                                    <input type="checkbox"
                                                           disabled={this.state.engaged}
                                                           onChange={(evt) => {
                                                               this.onCheckboxChange({
                                                                   keepSignedIn: evt.target.value,
                                                               });
                                                           }}
                                                           defaultChecked={this.state.keepSignedIn}
                                                           id="keep-logged-in" name="keep-logged-in" />
                                                    <label htmlFor="keep-logged-in" className="keep-logged-in-text">{LocUtils.T("login.keep_logged_in")}</label>
                                                    <small id="modalHelp" className="form-text input-text-below mt-0 badge badge-secondary fp-badge-custom" onClick={this.onForgotPasswordLinkClick}>
                                                        {LocUtils.T("login.login_forgot_password")}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <--- modal helper for logging in and forgot password ends --> */}
                                    </div>
                                    <br />
                                    {this.props.isZDCMigrationModule2 && <div className="input-group mb-3 text-center content-center">
                                        <Button
                                            onClick={() => {
                                                ServiceLocator.ZTrack.Cast({
                                                    counter: "dialog",
                                                    kingdom: "click",
                                                    phylum: this.props.source,
                                                    zClass: "fbconnect",
                                                    family: "zdc_register",
                                                    value: ServiceLocator.SocialNetworkManager.Me.Identity.getDeviceID()
                                                });
                                                this.loginFBHandler();
                                            }}
                                            extraClass="h6"
                                            severity="link"
                                            message={LocUtils.T("login.signup_connect")}
                                        />
                                    </div>}
                                    <div style={{ "bottom": "-15px" }}>
                                        <hr />
                                        <div className="tos">
                                            {LocUtils.T("login.signup_form_terms", {
                                                button_text: LocUtils.T("login.signup_connect"),
                                            })}
                                            <ul>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="http://company.zynga.com/legal/terms-of-service"
                                                    >
                                                        {LocUtils.T("login.signup_terms_of_service")}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="https://www.take2games.com/privacy"
                                                    >
                                                        {LocUtils.T("login.signup_privacy_policy")}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* The forget password form */}
                                <div className="carousel-item forgot-margins">
                                    <div className="input-group mb-3 text-left">
                                        <h3>
                                            {LocUtils.T("login.login_forgot_password_title")}
                                        </h3>
                                    </div>
                                    <div className="input-group mb-3 text-left">
                                        <h5>
                                            {LocUtils.T("login.login_forgot_password_desc")}
                                            <br />
                                        </h5>
                                    </div>
                                    <div
                                        className={
                                            (!!this.state.validation.forgetEmail
                                                ? "inp-error"
                                                : "") + " input-group mb-3"
                                        }
                                    >
                                        <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1"
                                                >
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                        </div>
                                        <input
                                            disabled={this.state.engaged}
                                            type="email"
                                            onChange={(evt) => {
                                                if (evt) evt.preventDefault();
                                                this.onChangeField({
                                                    forgetEmail: evt.target.value,
                                                });
                                            }}
                                            placeholder={LocUtils.TPure("login.email")}
                                            className="form-control"
                                            aria-label="Email"
                                            id={this.props.id + "-email-forgot"}
                                            value={this.state.forgetEmail}
                                        />
                                    </div>
                                    <Button
                                        onClick={this.onForgotPasswordSubmitClick}
                                        extraClass="form-signin-button"
                                        message={LocUtils.T(
                                            "login.login_forgot_password_send_btn"
                                        )}
                                        severity="default"
                                    />
                                    <hr />
                                    <i
                                        className="div-button small-font badge badge-secondary"
                                        onClick={this.onBackToLoginButtonClick}
                                    >
                                        &lt; {LocUtils.T("login.back")}
                                    </i>
                                </div>

                                {/* The mail sent for forget password */}
                                <div className="carousel-item email-sent-margins">
                                    <div className="input-group mb-3 text-left">
                                        <h3>{LocUtils.T("login.login_email_sent_title")}</h3>
                                    </div>
                                    <div className="input-group mb-3 text-left">
                                        <h5>{LocUtils.T("login.login_email_sent_desc")}</h5>
                                    </div>
                                    <div className="input-group mb-3 text-left">
                                        <Button
                                            onClick={this.onForgotPasswordUnderstoodButtonClick}
                                            extraClass="form-signin-button"
                                            message={LocUtils.T(
                                                "login.login_email_sent_confirm_btn"
                                            )}
                                            severity="default"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>

            </div>
        );
    }
}

export default Login;