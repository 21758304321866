import React from "react";
import BaseComponent from "../../../BaseComponent";
import Joyride from "react-joyride";
import LocUtils from '../../../../util/localization/LocUtils';
import DataStore from "../../../../data/DataStore";
import StepComponent from './StepComponent'
import ServiceLocator from '../../../../ServiceLocator'

class RewardClaimFTUE extends BaseComponent {
    _stepStorageKey = "ftue2Step";
    state = {
        steps: [
            {
                target: ".claim-button",
                content: <StepComponent step={5} />,
                locale: {
                    last: LocUtils.T("feature.ftue.cta_5")
                }
            },
        ],
    };

    componentDidMount() {
        ServiceLocator.ZTrack.Cast({
            counter: "zdc_perm_benefit",
            kingdom: 'ftue_complete'
        });
    }

    handleJoyrideCallback = (data) => {
        const { action } = data;
        //let newIndex = action !== "reset" ? parseInt(index) : parseInt(index) + 1;
        if(action === "reset"){
            DataStore.LocalStorage.Store(this._stepStorageKey, 1);
        }
        
    };

    render() {
        const { steps } = this.state;
        return (
            <div className="app">
                <Joyride
                    callback={this.handleJoyrideCallback}
                    hideCloseButton={true}
                    disableOverlayClose={true}
                    continuous={true}
                    steps={steps}
                    locale={{
                        back: LocUtils.TPure("tour.controls.back").toString()
                    }}
                    styles={{
                        options: {
                            primaryColor: '#3ba634'
                        },
                        buttonClose: {
                            display: 'none'
                        }
                    }}
                />
            </div>
        );
    }
}

export default RewardClaimFTUE;