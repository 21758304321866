/**
 * LoadingWidget.js
 * This widget listens to a network pending request and network
 * load clear. If there is a pending request then shows loader.
 * 
 * With a new update this now supports two kinds of widget:
 * - A rotating notch:
 *      The notch can be used at strategic positions with the 
 *      role of an animated icon.
 * - An indeterminate progress bar:
 *      This returns a row of a sticky bar which should stick
 *      to the bottom of the referring container. The deterministic
 *      mode is not setup yet.
 * @author Jaiwardhan Swarnakar
 */
import React from 'react';
import BaseComponent from '../BaseComponent';
import ServiceLocator from '../../ServiceLocator';
import './LoadingWidget.css'

/**
 * The widget class.
 * @param True|*    notch       When true, a notch is shown, else bar
 * @param True|*    determinate When true, shows a deterministic bar
 *                              else indeterminate bar.
 * @param String    register    When "true", subscribes to network load
 *                              and "show"s up when there are ongoing events.
 */
class LoadingWidget extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: (props.show === "true") ? true : false
        }
        this.imgsrc = process.env.REACT_APP_ASSET_PATH + "/loading.gif";
        this.showIt = this.showIt.bind(this);
        this.hideIt = this.hideIt.bind(this);
        this.refresh = this.refresh.bind(this);

        if (props.register === "true") {
            ServiceLocator.Events.subscribe(
                ServiceLocator.Events.EVENT_LIST.NETWORK.LOAD_PENDING,
                this.showIt,
                (this.props.force === "true") ? true : false
            )
            ServiceLocator.Events.subscribe(
                ServiceLocator.Events.EVENT_LIST.NETWORK.LOAD_CLEARED,
                this.hideIt,
                (this.props.force === "true") ? true : false
            )
        }
    }

    refresh(ctx) {
        ctx = (ctx !== undefined) ? ctx : ServiceLocator.Contexts.get(this.state.selfCtx);
        if (ctx !== undefined) {
            ctx.forceUpdate();
        }
    }

    showIt() {
        this.setState({
            show: true
        })
    }

    hideIt() {
        this.setState({
            show: false
        })
    }

    render() {
        const showWidth = () => {
            return (this.state.show === true) ? "1.2rem" : "0rem";
        }

        const showType = () => {
            return (this.state.show === true) ? (!this.props.determinate ? "indeterminate" : "determinate") : ""
        }

        const getWidget = () => {
            if (!this.props.notch) {
                return <div className="row no-gutters progress-position">
                    <div className="col">
                        <div className="progress-network progress-position">
                            <div className={showType()}></div>
                        </div>
                    </div>
                </div>
            }
            return <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{ fontSize: showWidth(), color: "white" }}></i>
        }

        return (
            getWidget()
        )
    }
}

export default LoadingWidget;