import Splunk from './Splunk';

var ZLogger = (function () {
    /** Wrapper for console.log to allow logging only when allowed by env */
    function log(...logParams) {
        if (process.env.REACT_APP_ALLOW_CUSTOM_LOGGING === "allowed") {
            console.log(...logParams);
        }
    }

    /** Wrapper for console.error to allow error logging only when allowed by env */
    function error(...errParams) {
        if (process.env.REACT_APP_ALLOW_CUSTOM_LOGGING === "allowed") {
            console.trace(...errParams);
        }
        Splunk.Dump(Splunk.Cat.LOG_LEVEL.ERROR, Splunk.Cat.LOG_SECTION.DEFAULT, ...errParams);
    }

    /** Extreme errors are regarded as fatal and are always allowed in any env */
    function fatal(...fatalParams) {
        // Unrestricted by env parameters and are allowed to
        // be displayed on the console.
        console.trace(...fatalParams);
        Splunk.Dump(Splunk.Cat.LOG_LEVEL.FATAL, Splunk.Cat.LOG_SECTION.DEFAULT, ...fatalParams);
    }

    /** Announce non sensitive data, inits etc. Unrestricted by any env setting */
    function announce(...anParams) {
        console.log(...anParams);
        // Splunk.Dump(Splunk.Cat.LOG_LEVEL.NOTICE, Splunk.Cat.LOG_SECTION.DEFAULT, ...anParams);
    }

    /** Warning on an unexpected behaviour or similar. */
    function warn(...warnParams) {
        console.warn(...warnParams);
        Splunk.Dump(Splunk.Cat.LOG_LEVEL.WARN, Splunk.Cat.LOG_SECTION.DEFAULT, ...warnParams);
    }

    /** Used to dump data in splunk with level as warning. */
    function dump(...dumpParams) {
        Splunk.Dump(Splunk.Cat.LOG_LEVEL.WARN, Splunk.Cat.LOG_SECTION.DEFAULT, ...dumpParams);
    }

    return {
        announce: announce,
        error: error,
        fatal: fatal,
        log: log,
        warn: warn,
        dump: dump
    }
})();

window.Z = ZLogger;
export default ZLogger;