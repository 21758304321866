/**
 * Welcome to Xing!
 * Xing is the very basis of X-Frame communication protocol and
 * delegating support for ZDC2.0. It simplifies its old predecessor
 * - Porthole.js, by implementing a data driven approach.
 * Xing also enables other component level controllers to register
 * their plug-in their own protocols and leverage its functionality.
 * Some rules are enabled by default when Xing loads. Other games/components
 * can register their rules and activate/deactivate them on-demand.
 * @author: jswarnakar
 * @copyright Zynga Inc 2019
 */
import ZLogger from "../util/zlogger/zlogger";
import ServiceLocator from "../ServiceLocator";

var XingStorage = (function () {
    /**
     * A small KV storage for Xing's internal usage and caching.
     */
    var _cache = {};

    function GetForRule(ruleName, key) {
        if (_cache[ruleName] === undefined) _cache[ruleName] = {};
        return _cache[ruleName][key];
    }

    function SetForRule(ruleName, key, value) {
        if (_cache[ruleName] === undefined) _cache[ruleName] = {};
        _cache[ruleName][key] = value;
    }

    function GetWhiteListedOrigin() {
        return _cache["WhiteListedOrigin"];
    }

    function SetWhiteListedOrigin(value) {
        _cache["WhiteListedOrigin"] = value;
    }

    return {
        GetForRule: GetForRule,
        SetForRule: SetForRule,
        GetWhiteListedOrigin: GetWhiteListedOrigin,
        SetWhiteListedOrigin: SetWhiteListedOrigin
    }
})();

var XingChecker = (function () {
    /**
     * Checks whether a proposedRule is formatted appropriately.
     * Rule schema:
     * {
     *      name (string)  => The name of this rule. Should be unique in most cases.
     *      passes (array) => A list of functions that should pass for the rule to be processed further
     *      handler (func) => The handler function that will be executed and data will be handed over on all pass.
     * }
     */
    function CheckRuleFormatting(proposedRule) {
        return (
            proposedRule !== undefined &&
            proposedRule.name !== undefined &&
            proposedRule.handler !== undefined &&
            typeof (proposedRule.handler) === "function" &&
            proposedRule.passes !== undefined &&
            Array.isArray(proposedRule.passes) &&
            proposedRule.passes.length > 0
        )
    }

    /** Ping-pong */
    function IsPong(data) {
        return data.type === XingConstants.UWG_TYPE_INGRESS_PONG;
    }

    /** Detect Bookmark Opt Flow */
    function IsDetectBookmark(data) {
        return data.type === XingConstants.ZDC_TYPE_INGRESS_DETECT_BOOKMARK;
    }

    /** Get page title */
    function IsGetTitle(data) {
        return data.type === XingConstants.ZDC_TYPE_INGRESS_GET_TITLE;
    }

    function IsGetSpaceInfo(data) {
        return data.type === XingConstants.ZDC_TYPE_INGRESS_GET_SPACE_INFO;
    }

    function IsDetectGameLoad(data) {
        return data.type === XingConstants.ZDC_TYPE_INGRESS_GAME_LOAD;
    }

    function IsDetectZdcLoginTypeRequest(data) {
        return data.type === XingConstants.UWG_TYPE_INGRESS_LOGIN_TYPE_REQUEST;
    }

    /** Validates compulsory data fields */
    function Validate(data) {
        return (
            data !== undefined && data.sender !== undefined && data.data !== undefined
        );
    }

    return {
        CheckRuleFormatting: CheckRuleFormatting,
        IsGetSpaceInfo: IsGetSpaceInfo,
        IsGetTitle: IsGetTitle,
        IsDetectBookmark: IsDetectBookmark,
        IsDetectGameLoad: IsDetectGameLoad,
        IsDetectZdcLoginTypeRequest: IsDetectZdcLoginTypeRequest,
        IsPong: IsPong,
        Validate: Validate
    }
})();

var XingConstants = (function () {
    return {
        // Ingress constants
        UWG_TYPE_INGRESS_PONG: "UWG_PONG",
        ZDC_TYPE_INGRESS_GET_TITLE: "GET_TITLE",
        ZDC_TYPE_INGRESS_GET_SPACE_INFO: "GET_SPACEINFO",
        ZDC_TYPE_INGRESS_DETECT_BOOKMARK: "detectBrowserBookmark",
        ZDC_TYPE_INGRESS_GAME_LOAD: "UWG_REWARD_CLAIM",
        UWG_TYPE_INGRESS_LOGIN_TYPE_REQUEST: "UWG_LOGIN_TYPE_REQUEST",

        // Egress constants
        UWG_TYPE_EGRESS_PING: "UWG_PING",
        ZDC_TYPE_EGRESS_SEND_TITLE: "SEND_TITLE",
        ZDC_TYPE_EGRESS_SEND_SPACE_INFO: "SEND_SPACEINFO",
        UWG_TYPE_EGRESS_LOGIN_TYPE_SEND: "UWG_LOGIN_TYPE_SEND",

        // Self sig
        SELF_SIG: "UWG",
        UWG_LISTENER_XFRAME_MSG: "message",
    }
})();


/**
 * Xing.js
 * Cross frame messaging library. Enables this site
 * to send and listen to x-frame messages from other
 * frames. Gives any child frame to activate/deactivate
 * its own rules on the fly.
 */
var Xing = (function () {
    var _enabled = false;
    var _rules = {
        "pong": {
            description: "A rule which checks for a pong which would had been relayed in exchange for a ping. Ensure connectivity is maintained with a frame.",
            activated: true,
            handler: function (d) {
                ZLogger.log("Pong from a child <======> : ", d);
            },
            passes: [
                XingChecker.Validate,
                XingChecker.IsPong
            ]
        },
        "detect_bookmark": {
            "description": "A rule which detects bookmark opt.",
            activated: true,
            handler: (d, original) => {
                try {
                    // Since the flag is present on the original payload
                    // use it to detect for incoming data.
                    let message = original;
                    let str = window.location.href;
                    let newStr = message.newlink;
                    if (str.indexOf('src=') !== -1) {
                        if (str.indexOf('&') === -1) {
                            str = str.replace(str.split('src=')[1], newStr);
                        } else {
                            str = str.replace(str.split('src=')[1].split('&')[0], newStr);
                        }
                    }
                    else {
                        if (str.indexOf('?') === -1) {
                            str = str + "?src=" + newStr;
                        } else {
                            if (str.indexOf('&') === -1 && str.split('?')[1] !== '') {
                                str = str + "&src=" + newStr;
                            } else {
                                str = str + "src=" + newStr;
                            }
                        }
                    }
                    window.history.pushState({}, null, str);
                }
                catch (e) {
                    ZLogger.error("[Xing] Bookmark: Encountered error when attempting to parse handler payload: " + e);
                }
            },
            passes: [
                XingChecker.IsDetectBookmark
            ],
            name: "detect_bookmark"
        },
        "detect_game_load": {
            "description": "A rule which detects game load.",
            activated: true,
            handler: (d) => {
                console.log("Pong from a child - game load <======> : ", d);
            },
            passes: [
                XingChecker.Validate,
                XingChecker.IsDetectGameLoad
            ],
            name: "detect_game_load"
        },
        "zdc_login_type_request": {
            "description": "A rule which gets ZDC Login Type and Sends it back to farm2-game-frame.",
            activated: true,
            handler: (d) => {
                console.log("VENKAT_LOG In UWG Login type request recieved : ", d);
                var cookie = getCookie("zdc_login_type_new");
                var url = (window.location.href.split("sendkey"))[0];
                if(url.indexOf("account_verification") >= 0){
                    cookie = "2";
                }
                var zdcLoginType = cookie.length > 0 ? cookie : "0";
                console.log("VENKAT_LOG In UWG zdcLoginType = "+zdcLoginType+ " going to send");
                ServiceLocator.Xing.Send(
                    "farm2-game-frame",
                    XingConstants.UWG_TYPE_EGRESS_LOGIN_TYPE_SEND,
                    { "zdcLoginType": zdcLoginType }
                );
                console.log("VENKAT_LOG In UWG zdcLoginType = "+zdcLoginType+ " sent");

            },
            passes: [
                XingChecker.Validate,
                XingChecker.IsDetectZdcLoginTypeRequest
            ],
            name: "zdc_login_type_request"
        },
        "get_page_title": {
            "description": "A rule which gets the page title (document.title) from the top frame.",
            activated: true,
            handler: (d, original) => {
                var sender = original.sender;

                console.log("(>) Sending title: ", document.title);
                // send back the page's title to the asking frame
                ServiceLocator.Xing.Send(
                    sender,
                    XingConstants.ZDC_TYPE_EGRESS_SEND_TITLE, {
                    "title": document.title
                }
                );
            },
            passes: [
                XingChecker.Validate,
                XingChecker.IsGetTitle
            ],
            name: "get_page_title"
        },
        "get_page_space_info": {
            "description": "A rule which gets the page info, such as client height & width, scroll data & offsets",
            "activated": true,
            handler: (d, original) => {
                var sender = original.sender;
                var space_info = {
                    clientWidth: window.innerWidth,
                    clientHeight: window.innerHeight,
                    scrollLeft: 0,
                    scrollTop: 0,
                    offsetLeft: 0,
                    offsetTop: 0
                };

                // compute once
                let _headerOffsetHeight = XingStorage.GetForRule("get_page_space_info", "_headerOffsetHeight");
                if (_headerOffsetHeight === undefined) {
                    let _header = document.getElementById("nav-header");
                    if (_header === undefined) {
                        XingStorage.SetForRule("get_page_space_info", "_headerOffsetHeight", 0);
                        _headerOffsetHeight = 0;
                    } else {
                        _headerOffsetHeight = _header.offsetHeight;
                        XingStorage.SetForRule("get_page_space_info", "_headerOffsetHeight", _headerOffsetHeight);
                    }
                }
                space_info.offsetTop = _headerOffsetHeight;

                // compute once
                let _containerOffsetLeft = XingStorage.GetForRule("get_page_space_info", "_containerOffsetLeft");
                if (_containerOffsetLeft === undefined) {
                    let _wrapper = document.getElementById("contentSection");
                    if (_wrapper === undefined) {
                        XingStorage.SetForRule("get_page_space_info", "_containerOffsetLeft", 0);
                        _containerOffsetLeft = 0;
                    } else {
                        _containerOffsetLeft = _wrapper.offsetLeft;
                        XingStorage.SetForRule("get_page_space_info", "_containerOffsetLeft", _containerOffsetLeft);
                    }
                }
                space_info.offsetLeft = _containerOffsetLeft;

                space_info.scrollLeft = window.pageXOffset;
                space_info.scrollTop = window.pageYOffset;

                ServiceLocator.Xing.Send(
                    sender,
                    XingConstants.ZDC_TYPE_EGRESS_SEND_SPACE_INFO,
                    {
                        "space_info": space_info
                    }
                )
            },
            passes: [
                XingChecker.Validate,
                XingChecker.IsGetSpaceInfo
            ]
        }
    };

    function IsEnabled() {
        return _enabled === true;
    }

    function _enable() {
        if (!_enabled) {
            ZLogger.announce("Xing: Is being enabled");
            // Enable Xing; attach event handler
            _enabled = true;
            window.addEventListener(XingConstants.UWG_LISTENER_XFRAME_MSG, Xing.Handler, false);
        }
    }

    function _disable() {
        if (_enabled) {
            ZLogger.announce("Xing: Is not being disabled");
            // Disable Xing; remove event handler
            _enabled = false;
            window.removeEventListener(XingConstants.UWG_LISTENER_XFRAME_MSG, Xing.Handler, false);
        }
    }

    function IsRuleActivated(ruleName) {
        ruleName = !ruleName || String(ruleName).length === 0 ? "" : String(ruleName);
        return _rules[ruleName] !== undefined && _rules[ruleName].activated === true;
    }

    function IsRuleRegistered(ruleName) {
        ruleName = !ruleName || String(ruleName).length === 0 ? "" : String(ruleName);
        return _rules[ruleName] !== undefined;
    }

    /**
     * Enables users/page components to Activate rules
     * @param {} rules  => List of rules to be activated
     * Check XingChecker.CheckRuleFormatting for rule schema
     */
    function ActivateRules(rules) {
        rules = !rules ? [] : rules;
        rules.forEach(eachRule => {
            if (eachRule.name && _rules[eachRule.name]) {
                ZLogger.log("++== activating ", eachRule.name)
                _rules[eachRule.name].activated = true;
            }
        });
    }

    /**
     * Enables users/page components to Deactivate rules
     * @param {} rules  => List of rules to be deactivated
     * Check XingChecker.CheckRuleFormatting for rule schema
     */
    function DeactivateRules(rules) {
        rules = !rules ? [] : rules;
        rules.forEach(eachRule => {
            if (eachRule.name && _rules[eachRule.name]) {
                _rules[eachRule.name].activated = false;
            }
        });
    }

    /**
     * Enables users/page components to register rules on Xing
     * @param {} rules  => List of rules to be registered
     * Check XingChecker.CheckRuleFormatting for rule schema
     */
    function RegisterRules(rules) {
        rules = !rules ? {} : rules;
        rules.forEach(eachRule => {
            if (XingChecker.CheckRuleFormatting(eachRule)) {
                _rules[eachRule.name] = eachRule;
                _rules[eachRule.name].activated = false;
            } else {
                ZLogger.fatal("Attempted to register invalid rule: ", eachRule);
            }
        });
    }

    /**
     * Enables users/page components to unregister rules on Xing
     * @param {} rules  => List of rules to be unregistered
     * Check XingChecker.CheckRuleFormatting for rule schema
     */
    function UnRegisterRules(rules) {
        rules = !rules ? [] : rules;
        rules.forEach(eachRule => {
            if (eachRule.name) delete _rules[eachRule.name];
        })
    }

    function Handler(resp) {
        // We would really want a clear cut communication command from the
        // sender and only act if they pass conditions
        if (resp && resp.data) {
            try {
                var validOrigin;
                if ([ServiceLocator.Env.GameMaps.Get("Farm2", "farmville-two").base, process.env.REACT_APP_TWITTER_FEED_URL].includes(resp.origin)) {
                    validOrigin = true;
                } else {
                    //check if we've set the whitelisted URL
                    //attempt to set if the whitelisted origin is not present
                    if (XingStorage.GetWhiteListedOrigin() === undefined) {
                        const urlPath = ServiceLocator.NetworkManager.Utils.GetUrlPath();
                        if (urlPath !== "/") {
                            //we must be inside the app, get the app Name
                            const appName = urlPath.substring(6);
                            XingStorage.SetWhiteListedOrigin(ServiceLocator.Env.GameMaps.Get("Farm2", appName).base);

                        }
                    }
                    validOrigin = resp.origin === XingStorage.GetWhiteListedOrigin();
                }
                if (validOrigin) {
                    var parsedMessage = JSON.parse(resp.data);
                    // Now go through all the rules. Which ever rule is completely satisfied
                    // hand over the data to its handler
                    for (var eachRuleName in _rules) {
                        var eachRule = _rules[eachRuleName];
                        if (eachRule.activated) {
                            var passesAll = true;
                            for (var i = 0; passesAll && i < eachRule.passes.length; ++i) {
                                var ruleChecker = eachRule.passes[i];
                                passesAll = passesAll && ruleChecker(parsedMessage);
                            }
                            if (passesAll) {
                                var ruleHandler = eachRule.handler;
                                if (ruleHandler) ruleHandler(parsedMessage.data, parsedMessage);
                            }
                        }
                    }
                }
            } catch (e) {
                // ignore since this doesn't seem to be a well formatted content
            }
        }
    }

    /**
     * Send a message to an immediate child iframe element
     */
    function Send(iframeId, section, msg, extras) {
        extras = extras ? extras : {};
        if (Xing.IsEnabled() && iframeId && section && msg) {
            var iframeEl = document.getElementById(iframeId);
            if (iframeEl && iframeEl.contentWindow && iframeEl.contentWindow.postMessage) {
                var payload = {
                    type: section,
                    sender: XingConstants.SELF_SIG,
                    data: msg
                }
                // Additionally if extras are needed to be punched in
                // then add them as well
                for (var k in extras) {
                    payload[k] = extras[k];
                }
                iframeEl.contentWindow.postMessage(JSON.stringify(payload), "*");
            }
        }
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    return {
        ActivateRules: ActivateRules,
        DeactivateRules: DeactivateRules,
        Disable: _disable,
        Enable: _enable,
        Handler: Handler,
        IsEnabled: IsEnabled,
        IsRuleActivated: IsRuleActivated,
        IsRuleRegistered: IsRuleRegistered,
        RegisterRules: RegisterRules,
        Send: Send,
        UnRegisterRules: UnRegisterRules,
        XingConstants: XingConstants,
        XingChecker: XingChecker
    }
})();


Xing.Enable();
export default Xing;
