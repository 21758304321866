import ServiceLocator from "../../../../ServiceLocator";
import ZLogger from "../../../../util/zlogger/zlogger";

var Profile = (function () {

    function ChangeLocale(data, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            locale: data.to,
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf
        }

        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zlocale_setLocale",
            payload: payload,
            token: "LOCALE_CHANGE"
        })
            .then((d) => { onCompleteCallback(d) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Gets the user's attributes based on his csrf and zid
     * Should get you the following on success:
     * --> displayName
     * --> firstName
     * --> lastName
     * --> email
     * --> gender
     * --> language
     * --> fbid
     * etc..
     */
    function Get(onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        const zid = ServiceLocator.SocialNetworkManager.SessionData().zid;
        const csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        // This looks strange but this is how requests were sent in the Zui version
        // to the api. Can't do much, but deal with it.
        var verificationParams = {
            18: "data[" + zid + "][snid]",
            "displayName": "data[" + zid + "][attr]",
            "firstName": "data[" + zid + "][attr]",
            "lastName": "data[" + zid + "][attr]",
            "profileName": "data[" + zid + "][attr]",
            "userName": "data[" + zid + "][attr]",
            "birthday": "data[" + zid + "][attr]",
            "email": "data[" + zid + "][attr]",
            "gender": "data[" + zid + "][attr]",
            "language": "data[" + zid + "][attr]",
            "zid": "data[" + zid + "][attr]",
            "pic_key": "data[" + zid + "][attr]",
            "profile_url": "data[" + zid + "][attr]",
            "profilePicNormal": "data[" + zid + "][attr]",
            "profilePicThumb": "data[" + zid + "][attr]",
            "online": "data[" + zid + "][attr]",
            "fbid": "data[" + zid + "][attr]",
            "not_activiated": "data[" + zid + "][attr]",
            "gid": "data[" + zid + "][attr]",
            "canReceiveGive": "data[" + zid + "][attr]",
            "isGiveCapped": "data[" + zid + "][attr]",
            "zavatarVer": "data[" + zid + "][attr]"
        }
        var verificationPayload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(verificationParams, true) + "&csrf=" + csrf
        }

        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_user_getUserAttributes",
            payload: verificationPayload,
            token: "ZDC_GET_USER_ATTRB"
        })
            .then(res => { return res.json() })
            .then(parsed => { onCompleteCallback(parsed) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Update a user's account data such as password, name, username etc
     * Payload schema:
     * {
     *      sessionToken: => session token (not csrf)
     *      csrf: => the csrf token
     *      method: => the method to be executing for
     *      ... others => other payload data to be updated with values
     * }
     */
    function Update(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        data.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(data)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_user_setUserAttributes",
            payload: payload,
            token: "ACCOUNT_UPDATE"
        })
            .then(data => { return data.json() })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Update user's profile picture. Needs a base64 png image data.
     * @param {Object} data should contain imageData (base64), imageType
     * @param {f} onCompleteCallback Callback when this is completed
     * @param {f} onFailureCallback Callback when this fails
     */
    function UpdateProfilePicture(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        data.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(data)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/avatar_setProfilePic",
            payload: payload,
            token: "PIC_UPDATE"
        })
            .then(data => { return data.json() })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }

    /**
     * Update user's privacy settings based on his selection data payload.
     * Payload schema:
     * {
     *      isResetToDefault: => bool
     *      updates: {
     *          updateCode1: UpdateValue1,
     *          updateCode2: UpdateValue2,
     *          ...
     *          ...
     *      }
     * }
     */
    function UpdatePrivacy(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        var params = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
            isResetToDefault: (data.isResetToDefault === undefined ? false : true)
        };
        data.updates = (data.updates === undefined) ? {} : data.updates;
        for (var key in data.updates) {
            params["privacy[" + key + "]"] = parseInt(data.updates[key]);
        }
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(params)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:user_updatePrivacy",
            payload: payload,
            token: "PRIVACY_UPDATE"
        })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }

    function RemoveEmail(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        data.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(data)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_removeEmail",
            payload: payload,
            token: "ACCOUNT_UPDATE"
        })
            .then(data => { return data.json() })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }


    function SendVerificationEmail(data = {}, onCompleteCallback = ZLogger.log, onFailureCallback = ZLogger.error) {
        data.csrf = ServiceLocator.SocialNetworkManager.SessionData().csrf;
        var payload = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            referrer: 'client',
            body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(data)
        }
        ServiceLocator.NetworkManager.Fetch.Call({
            url: process.env.REACT_APP_AUTH_ISSUETOKEN + "/ajax/common_web:zauth_sendVerificationEmailFromLogin",
            payload: payload,
            token: "ZDC_VERIFY_EMAIL"
        })
            .then(data => { return data.json() })
            .then(resp => { onCompleteCallback(resp) })
            .catch(err => { onFailureCallback(err) })
    }

    return {
        ChangeLocale: ChangeLocale,
        Get: Get,
        Update: Update,
        UpdateProfilePicture: UpdateProfilePicture,
        UpdatePrivacy: UpdatePrivacy,
        RemoveEmail:RemoveEmail,
        SendVerificationEmail:SendVerificationEmail
    }
})();

export default Profile;