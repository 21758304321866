import EOS from './Optimize/EOS'
import GoogleOptimize from './Optimize/GoogleOptimize'

var OptimizationManager = (function () {

    return {
        EOS: EOS,
        GoogleOptimize: GoogleOptimize
    }
})();

export default OptimizationManager;