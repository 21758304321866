/**
 * EOS.js
 * Collection of EOS optimization methods
 * @author  Jaiwardhan Swarnakar
 */
import ServiceLocator from "../../ServiceLocator"
import GenericUtils from "../../util/GenericUtils";
import ZLogger from "../../util/zlogger/zlogger"

/**
 * An object class to hold assignment values for
 * convenience.
 */
var EOSAssignments = (function () {

    var eos_data = {
        "undefined": 0
    }

    function Get(experimentName) {
        if (eos_data[experimentName] === undefined) return 0;
        return eos_data[String(experimentName)];
    }

    function Set(experimentSet) {
        let workingSet = [];
        if (!Array.isArray(experimentSet)) {
            workingSet.push(experimentSet);
        } else {
            workingSet = experimentSet;
        }

        workingSet.forEach(eachItem => {
            let val = 0;
            if (eachItem.experiment !== undefined && eachItem.variant !== undefined) {
                switch (eachItem.variant) {
                    case "holdout":
                        val = 0;
                        break;
                    case "control":
                        val = 0;
                        break;
                    default:
                        val = parseInt(eachItem.variant);
                }
                eos_data[eachItem.experiment] = val;
            }
        });
    }

    return {
        Get: Get,
        Set: Set
    }
});

/**
 * EOS handler to fetch assignments from endpoint server.
 */
var EOS = (function () {

    var initialized = false;
    var assignments = EOSAssignments();

    function IsInitialized() {
        return (initialized === true);
    }

    /**
     * 
     * @param {Fn} onStatusChange Method to tell to when init happens successfully or already has happened
     * @param {Bool} force Whether to forcefully reload from server
     */
    function Init(onStatusChange = ZLogger.log, force = false) {
        let responseStr = '';
        if (force === true || initialized === false) {
          let retrievalPayload = {
            csrf: ServiceLocator.SocialNetworkManager.SessionData().csrf,
            environment: process.env.REACT_APP_EOS_ENV,
          };

          ServiceLocator.NetworkManager.Fetch.Call({
            url:
              process.env.REACT_APP_AUTH_ISSUETOKEN +
              "/ajax/common_web:user_getEOSData",
            payload: {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              referrer: "client",
              body: ServiceLocator.NetworkManager.Utils.GetURLEncoded(
                retrievalPayload
              ),
            },
            token: "EOS_INIT",
          })
            .then((response) => {
              responseStr = response;
              console.log("Redirection: Response STR"+responseStr);
              return response.text();
            })
            .then((text) => {
              if (responseStr.ok) {
                  console.log("Redirection: Response STR2"+responseStr);
                  return text;
              } else {
                return Promise.reject({
                  status: responseStr.status,
                  statusText: responseStr.statusText,
                  err: text,
                });
              }
            })
            .then((jsonStr) => {
              if (GenericUtils.IsJsonString(jsonStr)) {
                  console.log("Redirection: JsonStr"+jsonStr);
                  var json = JSON.parse(jsonStr);
                if (json.e === 0 && json.data) {
                    console.log("Redirection: Json"+json.data);
                    assignments.Set(json.data);
                  initialized = true;
                  onStatusChange(true);
                } else {
                  onStatusChange(false);
                  ZLogger.error(
                    "EOS Status :",
                    json.e,
                    json.error,
                    json.failed
                  );
                  console.log("Redirection: EOS ERROR "+ json.error);
                }
              } else {
                ZLogger.error("EOS response is not a json :", jsonStr);
              }
            })
            .catch((err) => {
              console.log(err);
              ZLogger.fatal("Caught exception when loading EOS : " + err);
              onStatusChange(false);
            });
        }    

        // Since its already initialized, lets unblock any waiting
        // components on this.
        if (force === false && initialized === true) {
            onStatusChange(true);
        }
    }

    /**
     * 
     * @param {String} experimentName An experiment name that you are looking for
     * @return {Int} The assignment value of this experiment. If not present, then 0
     */
    function GetExperimentVariant(experimentName = undefined) {
        return assignments.Get(experimentName);
    }

    return {
        GetExperimentVariant: GetExperimentVariant,
        IsInitialized: IsInitialized,
        Init: Init
    }
})();

export default EOS;